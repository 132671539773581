import React from "react";
import { memo } from "react";
import { ImSpinner10 } from "react-icons/im";

export const Spinner = memo(() => {
    return (
        <div className="flex h-screen w-full items-center justify-center bg-transparent text-danger">
            <ImSpinner10 size={36} className="animate-spin" />
        </div>
    );
});

Spinner.displayName = "Spinner";
