import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import "swiper/swiper-bundle.css";
import { Pagination, Autoplay } from "swiper/modules";
import { useNavigate, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useSocket } from "../../context/SocketContext";

interface Card {
    media: string;
    text: string;
    buttonText: string;
    buttonUrl?: string;
    logoMedia: string;
}

interface FullScreenCardSliderSectionProps {
    componentData: {
        cards: Card[];
        componentName: string;
    };
    _id: string;
    designId: string;
}

const FullScreenCardSliderSection: React.FC<FullScreenCardSliderSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const swiperRef = useRef<any>(null);
    const navigate = useNavigate();
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const socket = useSocket();
    const slideRefs = useRef<(HTMLElement | null)[]>([]);
    const observer = useRef<IntersectionObserver | null>(null);
    const [lastReportedIndex, setLastReportedIndex] = useState<number | null>(
        null
    );

    const handleMouseEnter = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.autoplay.stop();
        }
    };

    const handleMouseLeave = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.autoplay.start();
        }
    };

    // View tracking with IntersectionObserver
    useEffect(() => {
        observer.current = new IntersectionObserver(
            (entries) => {
                const visibleEntries = entries.filter((entry) => entry.isIntersecting);
                if (visibleEntries.length > 0) {
                    const firstVisibleIndex = slideRefs.current.findIndex(
                        (ref) => ref === visibleEntries[0].target
                    );

                    if (
                        firstVisibleIndex !== -1 &&
                        firstVisibleIndex !== lastReportedIndex
                    ) {
                        setLastReportedIndex(firstVisibleIndex);
                        const card = componentData.cards[firstVisibleIndex];
                        if (socket) {
                            socket.emit("activity", {
                                userId: localStorage.getItem("visitorId"),
                                componentId: _id,
                                launchId: designId,
                                activityType: "view",
                                texts: [{ text: card.text, count: 1 }],
                            });
                            console.log(`Viewing card: ${card.text}`);
                        }
                    }
                }
            },
            { threshold: 0.5 }
        );

        slideRefs.current.forEach((ref) => {
            if (ref) observer.current?.observe(ref);
        });

        return () => {
            slideRefs.current.forEach((ref) => {
                if (ref) observer.current?.unobserve(ref);
            });
        };
    }, [componentData.cards, socket, _id, designId, lastReportedIndex]);

    const handleClick = (buttonUrl: string, buttonText: string) => {
        if (socket && socket.connected) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId"),
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [`${buttonUrl} || ${buttonText}`],
            });
            console.log(`Click logged: ${buttonUrl} | ${buttonText}`);
        }
        navigate(buttonUrl);
    };

    return (
        <motion.div
            ref={ref}
            className="w-full h-[85vh] relative mx-auto mt-16 transition-transform duration-700 ease-in-out scale-100"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
        >
            <Swiper
                ref={swiperRef}
                spaceBetween={13}
                slidesPerView={1.2}
                centeredSlides={true}
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                speed={800}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                modules={[Pagination, Autoplay]}
                className="w-full h-full overflow-hidden relative"
            >
                {componentData.cards.map((card, index) => (
                    <SwiperSlide
                        key={index}
                        className="relative transition-transform duration-500 swiper-slide-custom"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <motion.div
                            ref={(el) => (slideRefs.current[index] = el)}
                            className="flex w-full h-full card-slide transform transition-transform duration-500"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, ease: "easeOut" }}
                        >
                            {card.media.endsWith(".mp4") ? (
                                <video
                                    className="w-full h-full object-cover"
                                    autoPlay
                                    loop
                                    muted
                                >
                                    <source src={card.media} type="video/mp4" />
                                    Tarayıcınız bu videoyu desteklemiyor.
                                </video>
                            ) : (
                                <img
                                    src={card.media}
                                    alt={`Card Media ${index + 1}`}
                                    className="w-full h-full object-cover"
                                />
                            )}

                            <div className="absolute bottom-10 left-0 flex items-center space-x-4 bg-gradient-to-r from-black/75 to-transparent p-4">
                                {card.buttonUrl && (
                                    <motion.button
                                        onClick={() =>
                                            card.buttonUrl && handleClick(card.buttonUrl, card.buttonText)
                                        }
                                        className="relative px-6 py-3 bg-transparent border-2 border-white text-white rounded-full hover:bg-white/30 hover:text-white font-bold text-sm transition-opacity duration-300"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        transition={{ duration: 0.3 }}
                                        style={{
                                            fontFamily: "Radhanji, sans-serif",
                                        }}
                                    >
                                        <span className="relative z-10">
                                            {card.buttonText}
                                        </span>
                                    </motion.button>
                                )}
                                <div className="flex items-center space-x-2">
                                    <span
                                        className="text-white font-bold text-lg"
                                        style={{
                                            fontFamily: "Radhanji, sans-serif",
                                        }}
                                    >
                                        {card.text}
                                    </span>
                                </div>
                            </div>

                            {card.logoMedia && (
                                <motion.div
                                    className="absolute bottom-10 right-10 z-10"
                                    initial={{ opacity: 0, x: 50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeOut",
                                    }}
                                >
                                    <img
                                        src={card.logoMedia}
                                        alt="Card Logo"
                                        className="w-[55px] h-auto object-contain"
                                    />
                                </motion.div>
                            )}
                        </motion.div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div
                className="swiper-pagination w-full absolute bottom-[-40px] left-0 flex justify-center"
                style={{
                    fontSize: "40px",
                    zIndex: 10,
                }}
            ></div>
        </motion.div>
    );
};

export default FullScreenCardSliderSection;
