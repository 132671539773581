import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useDeployDesignStore from "../store/useLaunchForComponents";
import LaunchPage from "./LaunchPage";
import LaunchAnnouncement from "../components/LaunchAnnouncement";
import VisitForm from "../components/componentmodals/VisitForm"; // Formu ekleyelim
import useFairStore from "../store/fairStore";
import { getCompany, getFair } from "../services/fairServices";
import {
    useCompany,
    usePublicCompanyProducts,
    usePublicCompanyServices,
} from "../services/damiseServices";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const CompanyLayout: React.FC = () => {
    const { launchUrl, companyUrl } = useParams<{
        launchUrl: string;
        companyUrl: string;
    }>();
    const { fetchLaunch, launch, components } = useDeployDesignStore(
        (state: any) => state
    );
    const navigate = useNavigate();
    const [showVisitForm, setShowVisitForm] = useState(false); // Formun gösterim durumu
    const [visitorId, setVisitorId] = useState<string | null>(null); // visitorId'yi state'de tutuyoruz
    const { setLaunchData, launchData, setFairData } = useFairStore();
    const {
        data: selectedCompany,
        isLoading: loadingCompany,
        isError: errorCompany,
        error, // Hata detaylarını almak için
    } = useCompany(launchData.language.toLowerCase(), companyUrl);

    const {
        data: selectedCompanyProducts,
        isLoading: loadingCompanyProducts,
        isError: errorCompanyProducts,
    } = usePublicCompanyProducts(selectedCompany?._id);

    const {
        data: services,
        isLoading: loadingServices,
        isError: errorServices,
    } = usePublicCompanyServices(selectedCompany?._id);

    useEffect(() => {
        if (loadingCompany) {
            console.log("Şirket verisi yükleniyor...");
        } else if (errorCompany) {
            console.error(
                "Şirket verisi yüklenirken hata oluştu:",
                errorCompany
            );
        } else if (!loadingCompany && selectedCompany?._id) {
            console.log("Veri yüklendi ve istek yapılacak.");
            axios
                .post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                    launchUrl,
                    visitorId: localStorage.getItem("visitorId"),
                    componentName: "Company Layout",
                    componentCount: 1,
                    companyId: selectedCompany?._id,
                })
                .then((response) => {
                    console.log(
                        "Component verisi başarıyla kaydedildi:",
                        response.data
                    );
                })
                .catch((error) => {
                    console.error(
                        "Component verisi kaydedilirken hata oluştu:",
                        error
                    );
                });
        }
    }, [launchUrl, selectedCompany, loadingCompany, errorCompany]);

    const fetchDatas = async () => {
        try {
            // Lansman verilerini çekiyoruz
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/launch/by-launch-url/${launchUrl}`
            );
            // const launchDataForLang = await fetchLaunch(launchUrl);
            setLaunchData(response.data.launch);
            await fetchLaunch(
                response.data.launch.language === "TR" ? "firma" : "company"
            );
        } catch (error) {
            console.error("Firma bilgileri alınırken hata oluştu:", error);
            navigate("/access-denied");
        }
    };

    useEffect(() => {
        const storedVisitorId = localStorage.getItem("visitorId");

        // Eğer visitorId yoksa login sayfasına yönlendir
        if (!storedVisitorId) {
            navigate("/login");
        } else {
            // visitorId varsa durumu güncelle
            setVisitorId(storedVisitorId);

            // launchUrl varsa verileri çek
            if (launchUrl) {
                fetchDatas();
            }
        }
    }, [launchUrl]);

    useEffect(() => {
        if (launch && components) {
            // Lansmanın aktif olup olmadığını kontrol ediyoruz
            if (!launch.isActive) {
                console.log("Firma sayfası aktif değil, erişim engellendi.");
                navigate("/access-denied"); // Eğer lansman aktif değilse erişim engelleniyor
                return;
            }
        }
    }, [launch, components]);

    return (
        <>
            {launch ? (
                <>
                    {components.length > 0 ? (
                        <LaunchPage
                            components={components.sort(
                                (a: any, b: any) => a.row - b.row
                            )}
                            launchUrl={launchUrl || ""}
                        />
                    ) : (
                        <div>Loading...</div>
                    )}
                </>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default CompanyLayout;
