import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useSocket } from "../../context/SocketContext"; // Socket kullanımı

interface BannerSectionProps {
    componentData: {
        media: string; // Banner görsel URL'si
        url: string; // Keşfet butonu URL'si
        buttonText: string; // Buton metni
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const BannerSection: React.FC<BannerSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const { media, url, buttonText } = componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";
    const { ref, inView } = useInView({
        triggerOnce: false, // Tekrar görünürlüğü kontrol etmek için
        threshold: 0.3,
    });

    const [viewDuration, setViewDuration] = useState(0); // Görünüm süresi
    const socket = useSocket(); // Socket kullanımı

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000); // Görünümdeyken her saniye artır
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} bannerını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, _id, designId, socket]);

    const handleClick = () => {
        if (socket && socket.connected) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [`${url}`],
            });

            console.log(`Banner tıklama kaydedildi: ${url}`);
        } else {
            console.error("Socket bağlantısı yok, tıklama loglanamadı.");
        }
    };

    useEffect(() => {
        if (inView) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                    launchUrl,
                    visitorId: localStorage.getItem("visitorId"),
                    componentName: componentData.componentName,
                    componentCount: 1,
                })
                .then((response) => {
                    console.log(
                        "Component verisi başarıyla kaydedildi:",
                        response.data
                    );
                })
                .catch((error) => {
                    console.error(
                        "Component verisi kaydedilirken hata oluştu:",
                        error
                    );
                });
        }
    }, [inView, launchUrl, componentData.componentName]);

    return (
        <motion.div
            ref={ref}
            className="relative w-full lg:w-[85vw] h-[200px] bg-cover bg-center mb-8 flex items-center justify-center mx-auto rounded-3xl"
            style={{ backgroundImage: `url(${media})` }}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
        >
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute bottom-6 right-6 lg:inline-flex lg:items-center lg:justify-center min-w-[200px] lg:px-16 lg:py-4 px-8 py-2 border-2 border-white text-white text-lg font-bold rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg bg-black bg-opacity-30 shadow-lg text-center"
                onClick={handleClick} // Tıklama loglaması
            >
                {buttonText || "Keşfet"}
            </a>
        </motion.div>
    );
};

export default BannerSection;
