import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import lansmanLoginImage from "../assets/images/LansmanLogin.png";
import { useSocket } from "../context/SocketContext"; // SocketContext kullanımı

interface DamiseUserResponse {
    token: string;
    _id: string;
}

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<"tr" | "en">("tr");
    const [placeholders, setPlaceholders] = useState({
        email: "E-posta adresinizi giriniz",
        password: "Şifrenizi giriniz",
        remember: "Beni Hatırla",
        loginButton: "Giriş Yap",
        forgotPassword: "Şifremi Unuttum",
        signUp: "Kayıt ol",
    });

    const navigate = useNavigate();
    const location = useLocation();
    const socket = useSocket(); // SocketContext'ten socket'i alıyoruz

    // URL'ye göre dil ayarı ve MutationObserver ile lang attribute güncelleme
    useEffect(() => {
        const path = location.pathname;

        if (path.startsWith("/en")) {
            setLanguage("en");
            setPlaceholders({
                email: "Enter your email",
                password: "Enter your password",
                remember: "Remember Me",
                loginButton: "Login",
                forgotPassword: "Forgot Password",
                signUp: "Sign Up",
            });
            document.documentElement.lang = "en"; // HTML lang attribute'ünü güncelle
        } else if (path.startsWith("/tr")) {
            setLanguage("tr");
            setPlaceholders({
                email: "E-posta adresinizi giriniz",
                password: "Şifrenizi giriniz",
                remember: "Beni Hatırla",
                loginButton: "Giriş Yap",
                forgotPassword: "Şifremi Unuttum",
                signUp: "Kayıt ol",
            });
            document.documentElement.lang = "tr"; // HTML lang attribute'ünü güncelle
        }

        // MutationObserver ile dil değişikliklerini izleme
        const observer = new MutationObserver(() => {
            const lang = document.documentElement.lang;
            if (lang === "tr") {
                setPlaceholders({
                    email: "E-posta adresinizi giriniz",
                    password: "Şifrenizi giriniz",
                    remember: "Beni Hatırla",
                    loginButton: "Giriş Yap",
                    forgotPassword: "Şifremi Unuttum",
                    signUp: "Kayıt ol",
                });
            } else {
                setPlaceholders({
                    email: "Enter your email",
                    password: "Enter your password",
                    remember: "Remember Me",
                    loginButton: "Login",
                    forgotPassword: "Forgot Password",
                    signUp: "Sign Up",
                });
            }
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ["lang"],
        });

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, [location]);

    // Login işlemi
    const handleLogin = async () => {
        if (!email || !password) {
            setErrorMessage(
                language === "tr"
                    ? "Email ve şifre girilmelidir."
                    : "Email and password are required."
            );
            return;
        }

        setIsLoading(true);
        setErrorMessage("");

        try {
            const response = await axios.post<DamiseUserResponse>(
                `${process.env.REACT_APP_DAMISE_API_URL}/users/login`,
                { email, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const { token, _id } = response.data;

            // Login başarılı olursa token ve userId'yi kaydet
            localStorage.setItem("token", token);
            localStorage.setItem("visitorId", _id);

            // Kullanıcı giriş yaptı bilgisi sunucuya gönder
            if (socket) {
                socket.emit("login", { userId: _id, launchId: "default_launch_id" });
                console.log("Login event emitted via socket:", _id);
            }

            // Kullanıcıyı yönlendir
            navigate(location.state?.from || "/");
        } catch (error: any) {
            if (error.response) {
                setErrorMessage(
                    language === "tr"
                        ? `Sunucu hatası: ${error.response.data.message || "Bilinmeyen hata"
                        }`
                        : `Server error: ${error.response.data.message || "Unknown error"
                        }`
                );
            } else if (error.request) {
                setErrorMessage(
                    language === "tr"
                        ? "Sunucuya erişim sağlanamadı."
                        : "Unable to reach the server."
                );
            } else {
                setErrorMessage(
                    language === "tr"
                        ? "Bir hata oluştu: " + error.message
                        : "An error occurred: " + error.message
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <Navbar />
            <div className="flex bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
                <div className="hidden md:block w-1/2 bg-gray-100">
                    <img
                        src={lansmanLoginImage}
                        alt="Lansman Login"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="w-full md:w-1/2 p-8">
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">
                        {placeholders.loginButton}
                    </h2>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            * {placeholders.email}
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="email"
                                className="form-input block w-full px-4 py-3 border border-gray-300 rounded-md"
                                placeholder={placeholders.email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <FaEnvelope className="text-gray-400" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            * {placeholders.password}
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="password"
                                className="form-input block w-full px-4 py-3 border border-gray-300 rounded-md"
                                placeholder={placeholders.password}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <FaLock className="text-gray-400" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            id="remember"
                            className="h-4 w-4 text-red-900 focus:ring-red-800 border-gray-300 rounded"
                        />
                        <label
                            htmlFor="remember"
                            className="ml-2 block text-sm text-gray-900"
                        >
                            {placeholders.remember}
                        </label>
                    </div>
                    {errorMessage && (
                        <p className="text-red-500 text-sm mb-4">
                            {errorMessage}
                        </p>
                    )}
                    <button
                        onClick={handleLogin}
                        disabled={isLoading}
                        className={`w-full py-3 text-white rounded-lg bg-red-900 hover:bg-red-700 transition duration-300 ${isLoading && "opacity-50 cursor-not-allowed"
                            }`}
                    >
                        {isLoading
                            ? language === "tr"
                                ? "Giriş Yapılıyor..."
                                : "Logging in..."
                            : placeholders.loginButton}
                    </button>
                    <div className="mt-4 flex justify-between">
                        <a
                            href="https://damise.com/tr/sifremi-unuttum"
                            className="text-sm text-red-900 hover:underline"
                        >
                            {placeholders.forgotPassword}
                        </a>
                        <a
                            href="https://damise.com/tr/uyelik"
                            className="text-sm text-blue-500 hover:underline"
                        >
                            {placeholders.signUp}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;