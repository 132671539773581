import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useSocket } from "../../context/SocketContext"; // Socket kullanımı

interface CompanyNameSectionProps {
    componentData: {
        companyNameCards: Array<{
            media: string;
            title: string;
            subtitle: string;
            exploreUrl: string;
            buttonText: string;
        }>;
    };
}

const CompanyNameSection: React.FC<CompanyNameSectionProps> = ({
    componentData,
}) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const [selectedCard, setSelectedCard] = useState<{
        media: string;
        title: string;
        subtitle: string;
        exploreUrl: string;
        buttonText: string;
    } | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isPaused, setIsPaused] = useState(false); // Kaydırmayı durdurma durumu
    const [viewDuration, setViewDuration] = useState(0); // Görünüm süresi
    const socket = useSocket(); // Socket kullanımı

    const duplicatedCompanyNameCards = [
        ...componentData?.companyNameCards,
        ...componentData?.companyNameCards,
    ];

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000);
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: "CompanyNameSection",
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca CompanyNameSection kartını izledi.`
                );

                setViewDuration(0);
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, socket]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isTransitioning && !isPaused) {
                setActiveIndex((prevIndex) => prevIndex + 1);
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [isTransitioning, isPaused]);

    const isVideo = (media: string) => {
        const videoExtensions = ["mp4", "webm", "ogg"];
        const extension = media.split(".").pop();
        return extension && videoExtensions.includes(extension.toLowerCase());
    };

    const closeModal = () => {
        setSelectedCard(null);
    };

    const handleCardClick = (card: {
        media: string;
        title: string;
        subtitle: string;
        exploreUrl: string;
        buttonText: string;
    }) => {
        setSelectedCard(card);

        if (socket) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: "CompanyNameSection",
                activityType: "click",
                textlinks: [card.title, card.exploreUrl],
            });

            console.log(`Kart tıklama kaydedildi: ${card.title}`);
        }
    };

    useEffect(() => {
        if (sliderRef.current) {
            const cardWidth = 320;
            const totalScrollLeft = cardWidth * activeIndex;

            setIsTransitioning(true);
            sliderRef.current.scrollTo({
                left: totalScrollLeft,
                behavior: "smooth",
            });

            const timer = setTimeout(() => {
                if (activeIndex >= componentData?.companyNameCards?.length) {
                    sliderRef.current?.scrollTo({
                        left:
                            cardWidth *
                            (activeIndex -
                                componentData?.companyNameCards?.length),
                        behavior: "auto",
                    });
                    setActiveIndex(
                        (prevIndex) =>
                            prevIndex - componentData?.companyNameCards?.length
                    );
                }
                setIsTransitioning(false);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [activeIndex, componentData?.companyNameCards?.length]);

    if (!componentData?.companyNameCards) return <></>;
    return (
        <div className="relative w-full flex justify-center items-center">
            <div
                ref={sliderRef}
                className="max-w-[95%] overflow-x-scroll flex space-x-5 py-4 px-2 scrollbar-hide"
                style={{
                    scrollSnapType: "x mandatory",
                    WebkitOverflowScrolling: "touch",
                }}
            >
                {duplicatedCompanyNameCards?.map((card, index) => (
                    <div
                        key={index}
                        className={`relative flex-shrink-0 w-[320px] h-[600px] rounded-xl shadow-lg overflow-hidden transition-transform transform-gpu hover:scale-105 duration-500 ${
                            index === activeIndex ? "active" : ""
                        }`}
                        style={{ scrollSnapAlign: "start" }}
                        onMouseEnter={() => setIsPaused(true)}
                        onMouseLeave={() => setIsPaused(false)}
                        onClick={() => handleCardClick(card)}
                    >
                        {isVideo(card?.media) ? (
                            <video
                                src={card.media}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <img
                                src={card.media}
                                alt={`CompanyName Card ${index + 1}`}
                                className="w-full h-full object-cover"
                            />
                        )}
                        <div className="absolute bottom-0 left-0 w-full p-6 bg-gradient-to-t from-black to-transparent text-white">
                            <h3
                                className="text-2xl text-center font-semibold mb-1"
                                style={{ fontFamily: "Radhanji, sans-serif" }}
                            >
                                {card?.title}
                            </h3>
                            <p
                                className="text-gray-300 text-center mb-5"
                                style={{ fontFamily: "Radhanji, sans-serif" }}
                            >
                                {card.subtitle}
                            </p>
                            {card?.exploreUrl && (
                                <div className="text-center">
                                    <a
                                        href={card.exploreUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-block px-8 py-3 text-lg font-bold text-white mb-3 border-2 border-white rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg"
                                        style={{
                                            fontFamily: "Radhanji, sans-serif",
                                        }}
                                    >
                                        {card.buttonText || "Keşfet"}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CompanyNameSection;
