import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext"; // SocketContext'ten socket'i aldık

interface AccordionSection {
    title: string;
    subtitle: string | string[];
}

interface AccordionRightCardSectionProps {
    componentData: {
        media: string;
        accordionSections: AccordionSection[];
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const AccordionRightCardSection: React.FC<AccordionRightCardSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [viewDuration, setViewDuration] = useState(0); // İzleme süresi
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";
    const socket = useSocket(); // SocketContext'ten socket'i aldık

    const toggleAccordion = (index: number) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const { ref, inView } = useInView({
        threshold: 0.3, // Görünürlük eşiği
    });

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000); // Her saniye artır
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, _id, designId, socket]);

    const renderMedia = (media: string) => {
        if (!media) {
            return (
                <div className="flex justify-center items-center w-full h-full bg-gray-200">
                    <p className="text-gray-500 text-lg">Anonim Görsel</p>
                </div>
            );
        }

        const fileExtension = media.split(".").pop()?.toLowerCase();

        if (fileExtension === "mp4") {
            return (
                <video
                    className="object-cover w-full h-full"
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={media} type="video/mp4" />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else if (fileExtension === "pdf") {
            return (
                <iframe
                    src={media}
                    className="object-cover w-full h-full"
                    title="PDF Viewer"
                ></iframe>
            );
        } else if (
            ["png", "jpg", "jpeg", "gif", "webp"].includes(fileExtension || "")
        ) {
            return (
                <img
                    src={media}
                    alt="Media"
                    className="object-cover w-full h-full"
                />
            );
        } else if (["mp3", "wav", "ogg"].includes(fileExtension || "")) {
            return (
                <audio controls className="object-cover w-full h-full">
                    <source src={media} type={`audio/${fileExtension}`} />
                    Tarayıcınız bu ses dosyasını desteklemiyor.
                </audio>
            );
        } else if (["webm", "ogg"].includes(fileExtension || "")) {
            return (
                <video className="object-cover w-full h-full" controls>
                    <source src={media} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else {
            return <p>Desteklenmeyen medya türü</p>;
        }
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 100 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 80,
                damping: 20,
                duration: 2,
            },
        },
    };

    const titleVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                type: "spring",
                stiffness: 80,
                damping: 20,
                duration: 2,
            },
        },
    };

    return (
        <motion.div
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
            ref={ref}
            className="flex flex-col lg:flex-row justify-center items-center bg-white-100 p-8"
            style={{ marginTop: 0, marginBottom: 0 }}
        >
            <motion.div
                className="flex flex-col lg:flex-row h-auto lg:h-[70vh] w-full lg:w-[85vw] bg-white shadow-lg overflow-hidden rounded-3xl"
                variants={containerVariants}
            >
                <div
                    className="w-full lg:w-1/2 bg-white-50 p-6 space-y-8"
                    style={{ marginTop: 90, marginBottom: 20 }}
                >
                    {componentData.accordionSections.map(
                        (section: AccordionSection, index: number) => (
                            <motion.div
                                key={index}
                                className="pb-4"
                                variants={titleVariants}
                            >
                                <motion.div
                                    className="flex justify-between items-center pl-4 lg:pl-16 max-w-full lg:max-w-[65%] mx-auto"
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={titleVariants}
                                >
                                    <button
                                        className="text-lg lg:text-2xl text-gray-700 font-bold text-left"
                                        style={{
                                            fontFamily: "Radhanji, sans-serif",
                                        }}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        {section.title}
                                    </button>
                                    <button
                                        className="ml-auto text-xl p-1 transition-transform"
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        {activeIndex === index ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 15l7-7 7 7"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                </motion.div>
                                <div className="w-full lg:w-[55%] border-b border-gray-300 mt-2 mb-4 mx-auto"></div>
                                <motion.div
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{
                                        height:
                                            activeIndex === index ? "auto" : 0,
                                        opacity: activeIndex === index ? 1 : 0,
                                    }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 80,
                                        damping: 20,
                                        duration: 2,
                                    }}
                                    className="overflow-hidden"
                                >
                                    <div
                                        className="text-sm lg:text-base text-gray-700 mt-2 pl-4 lg:pl-12 pt-2 max-w-full lg:max-w-[50%] mx-auto text-left break-words"
                                        style={{
                                            fontFamily: "Radhanji, sans-serif",
                                        }}
                                    >
                                        {Array.isArray(section.subtitle) ? (
                                            section.subtitle.map(
                                                (
                                                    item: string,
                                                    subIndex: number
                                                ) => (
                                                    <p
                                                        key={subIndex}
                                                        className="mb-2"
                                                    >
                                                        {item}
                                                    </p>
                                                )
                                            )
                                        ) : (
                                            <p>
                                                {section.subtitle ||
                                                    "İçerik yok"}
                                            </p>
                                        )}
                                    </div>
                                </motion.div>
                            </motion.div>
                        )
                    )}
                </div>
                <div className="w-full lg:w-1/2 flex justify-center items-center bg-white">
                    {componentData.media ? (
                        renderMedia(componentData.media)
                    ) : (
                        <div className="flex justify-center items-center w-full h-full bg-gray-200">
                            <p className="text-gray-500 text-lg">
                                Anonim Görsel
                            </p>
                        </div>
                    )}
                </div>
            </motion.div>
        </motion.div>
    );
};

export default AccordionRightCardSection;
