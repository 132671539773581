import React, { useEffect } from "react";

import { Dispatch, KeyboardEvent, ReactNode, SetStateAction } from "react";
import { Link, useParams } from "react-router-dom";

import { shallow } from "zustand/shallow";
import useSearchStore from "../../store/damiseStores/searchStore";
//import { useDisclosure } from "hooks/useDisclosure";

//import { useLanguage } from "hooks";

import { Input } from "antd";
import classNames from "classnames";

import {
    BsGrid3X3Gap,
    BsSortAlphaDown,
    BsSortAlphaDownAlt,
} from "react-icons/bs";
import { useFair } from "../../services/damiseServices";
import useFairStore from "../../store/fairStore";
import { useDisclosure } from "../../components/damiseComponents/useDisclosure";

type Props = {
    filters: ReactNode;
    sorting: "asc" | "desc" | "";
    setSearchValue: Dispatch<SetStateAction<string>>;
    setSorting: Dispatch<SetStateAction<"asc" | "desc" | "">>;
    debouncedSearch?: (value: string) => void;
};

const TopSearchArea = (props: Props) => {
    const { filters, setSearchValue, setSorting, sorting, debouncedSearch } =
        props;
    const { slug } = useParams();
    const { launchData } = useFairStore();

    //const { t, lang } = useLanguage();
    const { isOpen, toggle } = useDisclosure();

    const [activeTab, setActiveTab] = useSearchStore(
        (state) => [state.activeTab, state.setActiveTab],
        shallow
    );

    const { data } = useFair(launchData.language, slug);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tab = params.get("tab"); // URL'den 'tab' parametresini al

        if (tab && tab !== activeTab) {
            setActiveTab(tab); // Eğer parametre değişmişse activeTab'ı güncelle
        }
    }, [activeTab, setActiveTab]);

    function handleChangeSearchSection(field: string) {
        setActiveTab(field);

        // URL'deki arama parametresini güncelle
        const params = new URLSearchParams(window.location.search);
        params.set("tab", field);
        window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${params}`
        );
    }
    function handleFilter(e: KeyboardEvent<HTMLInputElement>): void {
        const searchValue = e.currentTarget?.value;
        setSearchValue(searchValue);
        if (
            (activeTab === "company" || activeTab === "firma") &&
            debouncedSearch
        ) {
            debouncedSearch(searchValue);
        }
    }

    return (
        <div className="flex flex-col items-center justify-evenly gap-y-3 pb-6 pt-3 ">
            {/* <Link
                to={`/${lang}/${t("routes:ldigitalShowcase")}/${
                    data?.slug?.[lang as keyof typeof data.slug]
                }`}
            >
                {data?.name?.[lang as keyof typeof data.name]}
            </Link> */}
            <div className="hidden items-center gap-x-2 md:flex md:pr-2 xl:pr-12">
                <button
                    onClick={() =>
                        handleChangeSearchSection(
                            launchData.language === "tr" ? "firma" : "company"
                        )
                    }
                    className={classNames(
                        "filter-button  h-7 border-slate-400 !py-0 text-sm font-light text-slate-400 hover:border-danger hover:text-danger",
                        {
                            "!border-danger !text-danger":
                                activeTab === "company" ||
                                activeTab === "firma",
                        }
                    )}
                >
                    {launchData.language === "tr" ? "Firma" : "Company"}
                </button>

                <button
                    onClick={() =>
                        handleChangeSearchSection(
                            launchData.language === "tr" ? "ürün" : "product"
                        )
                    }
                    className={classNames(
                        "filter-button  h-7 border-slate-400 !py-0 text-sm  font-light text-slate-400 hover:border-danger hover:text-danger",
                        {
                            "!border-danger !text-danger":
                                activeTab === "product" || activeTab === "ürün",
                        }
                    )}
                >
                    {launchData.language === "tr" ? "Ürün" : "Product"}
                </button>

                <button
                    onClick={() =>
                        handleChangeSearchSection(
                            launchData.language === "tr" ? "hizmet" : "service"
                        )
                    }
                    className={classNames(
                        "filter-button  h-7 border-slate-400 !py-0   text-sm  font-light text-slate-400 hover:border-danger hover:text-danger",
                        {
                            "!border-danger !text-danger":
                                activeTab === "service" ||
                                activeTab === "hizmet",
                        }
                    )}
                >
                    {launchData.language === "tr" ? "Hizmet" : "Service"}
                </button>
            </div>
            <div className="flex w-full items-center space-x-2">
                <Input
                    placeholder={
                        launchData.language === "tr" ? "Ara" : "Search"
                    }
                    className="ml-4"
                    onKeyUp={handleFilter}
                />
                {sorting === "asc" || !sorting ? (
                    <BsSortAlphaDown
                        role="button"
                        onClick={() => setSorting("desc")}
                        size={24}
                    />
                ) : (
                    <BsSortAlphaDownAlt
                        role="button"
                        onClick={() => setSorting("asc")}
                        size={24}
                    />
                )}
            </div>

            {/* Mobile */}
            <div className="relative flex md:hidden">
                <BsGrid3X3Gap
                    onClick={toggle}
                    className={classNames("cursor-pointer hover:!opacity-75", {
                        "!text-main": isOpen,
                    })}
                    color="#cc374b"
                    size={24}
                />
                <div
                    className={classNames(
                        "invisible absolute top-12 -left-36 z-40 h-0 w-72 rounded-xl border border-slate-200 bg-white p-2 opacity-0 transition-all duration-300 ease-linear",
                        {
                            "!visible !h-fit !opacity-100": isOpen,
                        }
                    )}
                >
                    <div className="flex justify-end">
                        <button
                            onClick={() =>
                                handleChangeSearchSection(
                                    launchData.language === "tr"
                                        ? "firma"
                                        : "company"
                                )
                            }
                            className={classNames(
                                "filter-button  h-6 border-slate-400 !py-0 px-3 text-sm font-light text-slate-400 hover:border-danger hover:text-danger",
                                {
                                    "!border-danger !text-danger":
                                        activeTab === "company" ||
                                        activeTab === "firma",
                                }
                            )}
                        >
                            {launchData.language === "tr" ? "Firma" : "Company"}
                        </button>

                        <button
                            onClick={() =>
                                handleChangeSearchSection(
                                    launchData.language === "tr"
                                        ? "ürün"
                                        : "product"
                                )
                            }
                            className={classNames(
                                "filter-button  h-6 border-slate-400 !py-0 px-3 text-sm  font-light text-slate-400 hover:border-danger hover:text-danger",
                                {
                                    "!border-danger !text-danger":
                                        activeTab === "product" ||
                                        activeTab === "ürün",
                                }
                            )}
                        >
                            {launchData.language === "tr" ? "Ürün" : "Product"}
                        </button>

                        <button
                            onClick={() =>
                                handleChangeSearchSection(
                                    launchData.language === "tr"
                                        ? "hizmet"
                                        : "service"
                                )
                            }
                            className={classNames(
                                "filter-button  h-6 border-slate-400 !py-0  px-3  text-sm  font-light text-slate-400 hover:border-danger hover:text-danger",
                                {
                                    "!border-danger !text-danger":
                                        activeTab === "service" ||
                                        activeTab === "hizmet",
                                }
                            )}
                        >
                            {launchData.language === "tr"
                                ? "Hizmet"
                                : "Service"}
                        </button>
                    </div>
                    <div>{filters}</div>
                </div>
            </div>
        </div>
    );
};

export default TopSearchArea;
