import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import LaunchLayout from "./pages/LaunchLayout";
import HomepagePreviewPage from "./pages/HomepagePreviewPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFoundPage from "./pages/NotFoundPage";
import LaunchSearchPage from "./UI/sections/LaunchSearchPage";
import LoginPage from "./pages/LoginPage";
import CompanyLayout from "./pages/CompanyLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ParticipantDetails } from "./pages/ParticipantDetails";
import { ConfigProvider } from "antd";
import SearchLayout from "./pages/SearchLayout";
import { SocketProvider } from "./context/SocketContext";
const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <SocketProvider>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#861222",
                },
            }}
        >
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <Router>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                        />
                        <Routes>
                            {/* Genel kullanıcı rotaları */}
                            <Route path="/" element={<HomepagePreviewPage />} />
                            <Route
                                path="/:launchUrl"
                                element={<LaunchLayout />}
                            />
                            <Route
                                path="/:launchUrl/:companyUrl"
                                element={<CompanyLayout />}
                            />
                            <Route
                                path="/:launchUrl/katilimcilar"
                                element={<SearchLayout />}
                            />
                            <Route
                                path="/:launchUrl/participants"
                                element={<SearchLayout />}
                            />
                            <Route path="tr/giris-yap" element={<LoginPage />} />
                            <Route path="en/login" element={<LoginPage />} />
                            <Route
                                path="/access-denied"
                                element={<NotFoundPage />}
                            />
                            {/* Doğru rota ve componentData parametresiyle yönlendirme yapıyoruz */}
                            <Route
                                path="/tr/yayindaki-dijital-vitrinler"
                                element={
                                    <LaunchSearchPage componentData="ongoing" />
                                }
                            />
                            <Route
                                path="/tr/gelecek-dijital-vitrinler"
                                element={
                                    <LaunchSearchPage componentData="upcoming" />
                                }
                            />
                            <Route
                                path="/tr/gecmis-dijital-vitrinler"
                                element={
                                    <LaunchSearchPage componentData="past" />
                                }
                            />
                            <Route
                                path="/en/ongoing-digital-events"
                                element={
                                    <LaunchSearchPage componentData="ongoing" />
                                }
                            />
                            <Route
                                path="/en/upcoming-digital-events"
                                element={
                                    <LaunchSearchPage componentData="upcoming" />
                                }
                            />
                            <Route
                                path="/en/past-digital-events"
                                element={
                                    <LaunchSearchPage componentData="past" />
                                }
                            />
                            {/* 404 Sayfası */}
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Router>
                </HelmetProvider>
            </QueryClientProvider>
        </ConfigProvider>
        </SocketProvider>
    );
};

export default App;
