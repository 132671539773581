import React from "react";

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

//import { useLanguage } from 'hooks'

//import { ItemCardWithModal } from "../../../components/damiseComponents/Card";
import { AnyData } from "../../../components/damiseComponents/AnyData";

import classNames from "classnames";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import type {
    ICompany,
    ICompanySearchBody,
} from "../../../services/damiseTypes";

import { Pagination } from "antd";
import { usePaginatedFairParticipations } from "../../../services/damiseServices";
import SearchFilters from "../SearchFilters";
//import TableView from "../TableView";
import TopSearchArea from "../TopSearchArea";
import CompanyCard from "../../../components/damiseComponents/CompanyCard";
import useFairStore from "../../../store/fairStore";

const defaultValues: ICompanySearchBody = {
    sectors: [],
};

const FairCompanyList = (props: {
    fairId: string;
    selectedSectors: string[];
    setSelectedSectors: Dispatch<SetStateAction<string[]>>;
}) => {
    const { fairId, selectedSectors, setSelectedSectors } = props;
    const { launchData } = useFairStore();
    //const { t } = useLanguage();

    const { register, reset, handleSubmit } = useForm({
        defaultValues,
    });
    console.log("comp:" + fairId)
    const [mode, setMode] = useState<"grid" | "table">("grid");
    const [page, setPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [sorting, setSorting] = useState<"asc" | "desc" | "">("asc");

    useEffect(() => {
        refetchCompanies();
    }, [selectedSectors]);

    const {
        data: fairParticipations,
        isPreviousData,
        refetch: refetchCompanies,
        isLoading: loadingFairParticipations,
        isError: errorFairParticipations,
    } = usePaginatedFairParticipations(
        fairId,
        selectedSectors,
        page,
        companyName
    );

    const allCompanies =
        fairParticipations?.data?.map((part) => part?.company) ?? [];

    const onSubmit: SubmitHandler<FieldValues> = (values) => {
        setPage(1);
        setSelectedSectors(values.sectors);
    };

    const filteredData = useMemo(() => {
        //liste sıralama

        if (!sorting?.length) return allCompanies;
        return allCompanies?.sort(function (a, b) {
            if (
                a?.name.localeCompare(b?.name) < b?.name.localeCompare(a?.name)
            ) {
                return sorting === "asc" ? 1 : -1;
            }
            if (
                a?.name.localeCompare(b?.name) > b?.name.localeCompare(a?.name)
            ) {
                return sorting === "asc" ? -1 : 1;
            }
            return 0;
        });
    }, [allCompanies, searchValue, sorting]);

    const debouncedSearch = useDebouncedCallback((value: string) => {
        setCompanyName(value);
    }, 300);

    return (
        <section className="mb-12 grid grid-cols-9">
            <div className="col-span-9 flex flex-col gap-y-3 md:col-span-9">
                <TopSearchArea
                    debouncedSearch={debouncedSearch}
                    sorting={sorting}
                    setSearchValue={setSearchValue}
                    setSorting={setSorting}
                    filters={
                        <SearchFilters
                            mode={mode}
                            setMode={setMode}
                            reset={reset}
                            register={register}
                        />
                    }
                />
                <div className="hidden h-min  flex-col gap-4 rounded-lg bg-white p-3 mb-20 shadow-sm md:col-span-9 md:flex md:justify-start">
                    <SearchFilters
                        mode={mode}
                        setMode={setMode}
                        reset={reset}
                        register={register}
                    />
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="w-full rounded-lg bg-main px-3 py-1 text-red-700"
                    >
                        {launchData.language === "tr" ? "Ara" : "Search"}
                    </button>
                </div>

                <div
                    className={classNames(
                        "grid h-full w-full grid-cols-1 flex-wrap items-start gap-6 py-1 sm:grid-cols-2  md:flex-1 md:grid-cols-2 md:pr-8 md:pl-6 lg:grid-cols-3 xl:grid-cols-4 xl:pr-20",
                        { "!flex !flex-col": mode === "table" }
                    )}
                >
                    {filteredData?.length > 0 ? (
                        <>
                            {filteredData?.map((s) => (
                                <CompanyCard
                                    company={{
                                        id: s._id,
                                        title: s?.name,
                                        media:
                                            launchData?.language === "tr"
                                                ? s?.promotionImages?.[0]
                                                : s?.promotionImagesEn?.[0],

                                        exploreUrl:
                                            launchData?.language === "tr"
                                                ? s?.slug?.tr
                                                : s?.slug?.en,
                                        subtitle: "",
                                        logo: s?.logo?.url || "",
                                        
                                    }}
                               
                                />
                            ))}
                        </>
                    ) : (
                        <div className="flex !h-96 w-full items-center justify-center md:!w-[60vw]">
                            <AnyData title="notMatchingCompanies" />
                        </div>
                    )}
                </div>
                <div>
                    <Pagination
                        current={page}
                        onChange={setPage}
                        total={fairParticipations?.total}
                        pageSize={20}
                        showSizeChanger={false}
                        //pageSizeOptions={["10", "25", "50"]}
                    />
                </div>
            </div>
        </section>
    );
};

export default FairCompanyList;
