import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";

interface TwinTopTitleHeroCardSectionProps {
    componentData: {
        rightCard: {
            media: string;
            title: string;
            subtitle: string;
            buttonText: string;
            buttonUrl: string;
        };
        leftCard: {
            media: string;
            title: string;
            subtitle: string;
            buttonText: string;
            buttonUrl: string;
        };
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const TwinTopTitleHeroCardSection: React.FC<TwinTopTitleHeroCardSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const { rightCard, leftCard, componentName } = componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";

    const [leftRef, leftInView] = useInView({ threshold: 0.3 });
    const [rightRef, rightInView] = useInView({ threshold: 0.3 });

    const socket = useSocket();

    const [leftViewDuration, setLeftViewDuration] = useState(0);
    const [rightViewDuration, setRightViewDuration] = useState(0);

    useEffect(() => {
        let leftInterval: NodeJS.Timeout | null = null;
        let rightInterval: NodeJS.Timeout | null = null;

        if (leftInView) {
            leftInterval = setInterval(() => {
                setLeftViewDuration((prev) => prev + 1);
            }, 1000);
        } else if (leftViewDuration > 0 && socket) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "view-duration",

                duration: leftViewDuration,
            });
            setLeftViewDuration(0);
        }

        if (rightInView) {
            rightInterval = setInterval(() => {
                setRightViewDuration((prev) => prev + 1);
            }, 1000);
        } else if (rightViewDuration > 0 && socket) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "view-duration",
                duration: rightViewDuration,
            });
            setRightViewDuration(0);
        }

        return () => {
            if (leftInterval) clearInterval(leftInterval);
            if (rightInterval) clearInterval(rightInterval);
        };
    }, [leftInView, rightInView, leftViewDuration, rightViewDuration, _id, designId, socket]);

    const handleClick = (mediaUrl: string, text: string) => {
        if (socket && socket.connected) {
            const textlinks = [mediaUrl, text].join(" | ");
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId"),
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [textlinks], // Ensure textlinks is an array
            });
            console.log("Tıklama kaydedildi:", textlinks);
        }
    };

    const renderMedia = (mediaUrl: string) => {
        if (
            mediaUrl.endsWith(".mp4") ||
            mediaUrl.endsWith(".webm") ||
            mediaUrl.endsWith(".ogg")
        ) {
            return (
                <video
                    className="w-full h-full object-cover rounded-3xl"
                    autoPlay
                    muted
                    loop
                >
                    <source src={mediaUrl} type="video/mp4" />
                </video>
            );
        } else {
            return (
                <img
                    src={mediaUrl}
                    alt="Card Media"
                    className="w-full h-full object-cover rounded-3xl"
                />
            );
        }
    };

    return (
        <div className="flex flex-col md:flex-row justify-between p-5 bg-white mb-5 w-[85vw] h-[70vh] mx-auto mt-16 space-y-5 md:space-y-0 md:space-x-7 max-w-none">
            {/* Left Card */}
            <div
                ref={leftRef}
                className={`w-full md:w-[calc(50%-14px)] h-full bg-[#DFE2E6] overflow-hidden relative transition-all duration-1000 ease-in-out transform rounded-3xl ${leftInView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-5"
                    } hover:shadow-lg group`}
            >
                <div className="absolute bottom-0 left-0 w-full p-5 h-[30%] bg-gradient-to-t from-black to-transparent opacity-90 z-10 rounded-b-none">
                    <div className="absolute bottom-10 left-5 text-white font-sans z-20 space-y-2">
                        <h3 className="text-[4vw] font-bold mb-2 tracking-wider md:text-[2vw]">
                            {leftCard.title}
                        </h3>
                        <p
                            className="text-[2.5vw] font-semibold mb-5 line-clamp-2 md:text-[1.2vw]"
                            style={{ marginBottom: "20px", maxWidth: "80%" }}
                        >
                            {leftCard.subtitle}
                        </p>
                    </div>
                </div>
                <motion.div
                    className="absolute bottom-12 right-10 block z-30"
                    style={{ width: "25%", height: "10%" }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                >
                    <a
                        href={leftCard.buttonUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-6 py-3 border-2 border-white rounded-full text-white text-center font-bold text-[1.5vw] md:text-[1vw] transition-colors duration-300 block"
                        onClick={() => handleClick(leftCard.buttonUrl, leftCard.title)}
                    >
                        {leftCard.buttonText}
                    </a>
                </motion.div>
                {renderMedia(leftCard.media)}
            </div>

            {/* Right Card */}
            <div
                ref={rightRef}
                className={`w-full md:w-[calc(50%-14px)] h-full bg-[#DFE2E6] overflow-hidden relative transition-all duration-1000 ease-in-out transform rounded-3xl ${rightInView
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-5"
                    } hover:shadow-lg group`}
            >
                <div className="absolute bottom-0 left-0 w-full p-5 h-[30%] bg-gradient-to-t from-black to-transparent opacity-90 z-10 rounded-b-none">
                    <div className="absolute bottom-10 left-5 text-white font-sans z-20 space-y-2">
                        <h3 className="text-[4vw] font-bold mb-2 tracking-wider md:text-[2vw]">
                            {rightCard.title}
                        </h3>
                        <p
                            className="text-[2.5vw] font-semibold mb-5 line-clamp-2 md:text-[1.2vw]"
                            style={{ marginBottom: "20px", maxWidth: "80%" }}
                        >
                            {rightCard.subtitle}
                        </p>
                    </div>
                </div>
                <motion.div
                    className="absolute bottom-12 right-10 block z-30"
                    style={{ width: "25%", height: "10%" }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                >
                    <a
                        href={rightCard.buttonUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-6 py-3 border-2 border-white rounded-full text-white text-center font-bold text-[1.5vw] md:text-[1vw] transition-colors duration-300 block"
                        onClick={() => handleClick(rightCard.buttonUrl, rightCard.title)}
                    >
                        {rightCard.buttonText}
                    </a>
                </motion.div>
                {renderMedia(rightCard.media)}
            </div>
        </div>
    );
};

export default TwinTopTitleHeroCardSection;
