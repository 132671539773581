import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import axios from "../../libs/axios";
//import { langOptions } from "constants/langOptions";

import type {
    IFair,
    IFairLogReqBody,
    IFairValidationReqBody,
    IFairVisitReqBody,
    IFeaturedParticipation,
    IParticipantCompany,
    IParticipantProduct,
    IParticipantService,
    IParticipation,
} from "../damiseTypes";

const getFairs = async (): Promise<IFair[]> => {
    const response = await axios(`/fair/fair-all`);
    return response.data?.data;
};

export function useFairs(/* logged: boolean */) {
    const func = getFairs; /* logged ? getRoleBasedFairs : */
    return useQuery(["fairs"], func);
}

// __ PaginatedStart
const getPaginatedFairs = async ({ pageParam = 1 }): Promise<IFair[]> => {
    const response = await axios(
        `/fair/fair-all?page=${pageParam}&limit=5&sort=startDate,-1`
    );
    return response.data?.data;
};

export function usePaginatedFairs(/* logged: boolean */) {
    const func = getPaginatedFairs; /* logged ?getRoleBasedPaginatedFairs : */
    return useInfiniteQuery(["fairs-paginated"], func, {
        getNextPageParam: (lastPage, pages) => {
            const morePagesExist = lastPage && lastPage.length === 5;
            if (!morePagesExist) {
                return;
            }
            return pages.length + 1;
        },
    });
}
// __ PaginatedEnd

/* const getFair = async (fairId: string | undefined): Promise<IFair> => {
    return typeof fairId === 'undefined'
      ? Promise.reject(new Error('Invalid id'))
      : axios(`/fair/fair/${fairId}`).then((response) => response.data)
  } */
const getFair = async (lang?: string, fairId?: string): Promise<IFair> => {
    return typeof fairId === "undefined" || typeof lang === "undefined"
        ? Promise.reject(new Error("Invalid request"))
        : axios(`/fair/fair/${fairId}`)
              .then((response) => response.data)
              .catch((e) => {
                  if (e?.response?.data?.message === "NotFound") {
                      return axios(`/fair/fair/${fairId}`)
                          .then((res) => res.data)
                          .catch((e) => Promise.reject(e));
                  }
              });
};

export function useFair(lang?: string, fairId?: string) {
    return useQuery(["fairs", fairId], () => getFair(lang, fairId), {
        enabled: Boolean(fairId) && Boolean(lang),
    });
}

const getPaginatedParticipations = async ({
    fairId,
    sectors,
    pageParam = 1,
    companyName,
}: {
    fairId?: string;
    sectors?: string[];
    pageParam?: number;
    companyName?: string;
}): Promise<{ data: IParticipation[]; total: number }> => {
    const sectorQuery = sectors?.length
        ? sectors?.map((s) => `&sectors=${s}`).join("")
        : "";
    const searchQuery = companyName?.length
        ? `&companyName=${companyName}`
        : "";
    if (!fairId) return Promise.reject({ message: "Invalid fair id" });
    const response = await axios(
        `/fair/fair-participation-all?fairid=${fairId}&page=${pageParam}&limit=20${sectorQuery}${searchQuery}`
    );
    return { data: response.data?.data, total: response.data?.total };
};

export function usePaginatedFairParticipations(
    fairId?: string,
    sectors?: string[],
    pageParam?: number,
    companyName?: string
) {
    const func = async () => {
        return await getPaginatedParticipations({
            fairId,
            sectors,
            pageParam,
            companyName,
        });
    };

    return useQuery({
        queryKey: [
            "fair-participants-paginated",
            fairId,
            pageParam,
            companyName,
            sectors,
        ],
        queryFn: func,
        keepPreviousData: true,
    });

    /*   return useInfiniteQuery(['fair-participants-paginated', fairId], func, {
      getNextPageParam: (lastPageData, allPages) => {
        const morePagesExist = lastPageData?.data && lastPageData.data?.length === 20
        if (!morePagesExist) {
          return
        }
        return allPages?.length + 1
      },
      enabled: Boolean(fairId)
    }) */
}

const getInfiniteParticipations = async ({
    fairId,
    sectors,
    pageParam = 1,
}: {
    fairId?: string;
    sectors?: string[];
    pageParam: number;
}): Promise<IParticipation[]> => {
    const sectorQuery = sectors?.length
        ? sectors?.map((s) => `&sectors=${s}`).join("")
        : "";
    if (!fairId) return Promise.reject({ message: "Invalid fair id" });
    const response = await axios(
        `/fair/fair-participation-all?fairid=${fairId}&page=${pageParam}&limit=20${sectorQuery}`
    );
    return response.data?.data;
};

export function useInfiniteFairParticipations(
    fairId?: string,
    sectors?: string[]
) {
    const func = async ({ pageParam = 1 }) => {
        return await getInfiniteParticipations({ fairId, sectors, pageParam });
    };

    return useInfiniteQuery(["fair-participants-infinite", fairId], func, {
        getNextPageParam: (lastPage, pages) => {
            const morePagesExist = lastPage && lastPage.length === 20;
            if (!morePagesExist) {
                return;
            }
            return pages.length + 1;
        },
        enabled: Boolean(fairId),
    });
}

const getFairProducts = async (
    fairId?: string,
    sectors?: string[]
): Promise<IFeaturedParticipation["products"]> => {
    const sectorQuery = sectors?.length
        ? sectors
              ?.map((s) => `${sectors?.[0] === s ? "?" : "&"}sectors=${s}`)
              .join("")
        : "";
    return typeof fairId === "undefined"
        ? Promise.reject(new Error("Invalid fair id"))
        : axios(
              `/fair/fair-participation-products-fairid/${fairId}${sectorQuery}`
          ).then((response) => response.data);
};

export function useFairProducts(fairId?: string, sectors?: string[]) {
    return useQuery(
        ["fair-products", fairId],
        () => getFairProducts(fairId, sectors),
        {
            enabled: Boolean(fairId),
        }
    );
}
const getFairServices = async (
    fairId?: string,
    sectors?: string[]
): Promise<IFeaturedParticipation["services"]> => {
    const sectorQuery = sectors?.length
        ? sectors
              ?.map((s) => `${sectors?.[0] === s ? "?" : "&"}sectors=${s}`)
              .join("")
        : "";
    return typeof fairId === "undefined"
        ? Promise.reject(new Error("Invalid fair id"))
        : axios(
              `/fair/fair-participation-services-fairid/${fairId}${sectorQuery}`
          ).then((response) => response.data);
};

export function useFairServices(fairId?: string, sectors?: string[]) {
    return useQuery(
        ["fair-services", fairId],
        () => getFairServices(fairId, sectors),
        {
            enabled: Boolean(fairId),
        }
    );
}
const getParticipantCompany = async (
    fairId?: string,
    companyId?: string
): Promise<IParticipantCompany> => {
    return typeof fairId === "undefined" || typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid request"))
        : axios(
              `/fair/fair-participation-company/${fairId}?companyId=${companyId}`
          ).then((response) => response.data);
};

export function useParticipantCompany(fairId?: string, companyId?: string) {
    return useQuery(
        ["participant-company", companyId],
        () => getParticipantCompany(fairId, companyId),
        {
            enabled: Boolean(fairId) && Boolean(companyId),
        }
    );
}
const getParticipantProduct = async (
    fairId?: string,
    productId?: string
): Promise<IParticipantProduct> => {
    return typeof fairId === "undefined" || typeof productId === "undefined"
        ? Promise.reject(new Error("Invalid request"))
        : axios(
              `/fair/fair-participation-product/${fairId}?productId=${productId}`
          ).then((response) => response.data);
};

export function useParticipantProduct(fairId?: string, productId?: string) {
    return useQuery(
        ["participant-product", productId],
        () => getParticipantProduct(fairId, productId),
        {
            enabled: Boolean(fairId) && Boolean(productId),
        }
    );
}
const getParticipantService = async (
    fairId?: string,
    serviceId?: string
): Promise<IParticipantService> => {
    return typeof fairId === "undefined" || typeof serviceId === "undefined"
        ? Promise.reject(new Error("Invalid request"))
        : axios(
              `/fair/fair-participation-service/${fairId}?serviceId=${serviceId}`
          ).then((response) => response.data);
};

export function useParticipantService(fairId?: string, serviceId?: string) {
    return useQuery(
        ["participant-service", serviceId],
        () => getParticipantService(fairId, serviceId),
        {
            enabled: Boolean(fairId) && Boolean(serviceId),
        }
    );
}

const getFeaturedFairParticipations = async (
    fairId?: string
): Promise<IFeaturedParticipation> => {
    return typeof fairId === "undefined"
        ? Promise.reject(new Error("Invalid fair id"))
        : axios(`/fair/fair-highlight/${fairId}`).then(
              (response) => response.data
          );
};

export function useFeaturedFairParticipants(fairId?: string) {
    return useQuery(
        ["fairs-featured-participants", fairId],
        () => getFeaturedFairParticipations(fairId),
        {
            enabled: Boolean(fairId),
        }
    );
}

export const sendFairFormApproval = async (
    reqBody: IFairValidationReqBody
): Promise<number> => {
    return typeof reqBody === "undefined"
        ? Promise.reject(new Error("Invalid form body"))
        : await axios
              .post(`/fair/send-mail-code`, reqBody)
              .then((response) => response.data)
              .catch((e) =>
                  Promise.reject({ message: e?.response?.data?.message })
              );
};

export const sendFairForm = async (
    reqBody: IFairVisitReqBody,
    code?: string
): Promise<unknown> => {
    const codeQuery = code ? `?mailcode=${code}` : "";
    return typeof reqBody === "undefined"
        ? Promise.reject(new Error("Invalid form body"))
        : await axios
              .post(`/fair/send-form${codeQuery}`, reqBody)
              .then((response) => response.data)
              .catch((e) =>
                  Promise.reject({ message: e?.response?.data?.message })
              );
};
export const sendFairLog = async (
    reqBody: IFairLogReqBody,
    fairId?: string
): Promise<unknown> => {
    return typeof reqBody === "undefined" || typeof fairId === "undefined"
        ? Promise.reject(new Error("Invalid request"))
        : await axios
              .post(`/fair/fair-log/${fairId}`, reqBody)
              .then((response) => response.data)
              .catch((e) =>
                  Promise.reject({ message: e?.response?.data?.message })
              );
};
