import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useSocket } from "../../context/SocketContext";

interface SponsorCardSectionProps {
    componentData: {
        sponsorCards: Array<{
            logo: string;
            subtitle: string;
            url: string;
        }>;
        componentName: string;
    };
    designId: string; // Launch ID
    _id: string; // Component ID
}

const SponsorCardSection: React.FC<SponsorCardSectionProps> = ({
    componentData,
    designId,
    _id,
}) => {
    const { sponsorCards } = componentData;
    const socket = useSocket();
    const slideRefs = useRef<(HTMLElement | null)[]>([]);
    const observer = useRef<IntersectionObserver | null>(null);
    const [lastReportedIndex, setLastReportedIndex] = useState<number | null>(null);

    useEffect(() => {
        observer.current = new IntersectionObserver(
            (entries) => {
                const visibleEntries = entries.filter((entry) => entry.isIntersecting);
                if (visibleEntries.length > 0) {
                    const firstVisibleIndex = slideRefs.current.findIndex(
                        (ref) => ref === visibleEntries[0].target
                    );

                    if (
                        firstVisibleIndex !== -1 &&
                        firstVisibleIndex !== lastReportedIndex
                    ) {
                        setLastReportedIndex(firstVisibleIndex);
                        const card = sponsorCards[firstVisibleIndex];
                        if (socket) {
                            socket.emit("activity", {
                                userId: localStorage.getItem("visitorId"),
                                componentId: _id,
                                launchId: designId,
                                activityType: "view",

                            });
                        }
                        console.log(`Kullanıcı şu kartı izliyor: ${card.subtitle}`);
                    }
                }
            },
            { threshold: 0.5 }
        );

        slideRefs.current.forEach((ref) => {
            if (ref) observer.current?.observe(ref);
        });

        return () => {
            slideRefs.current.forEach((ref) => {
                if (ref) observer.current?.unobserve(ref);
            });
        };
    }, [sponsorCards, socket, _id, designId, lastReportedIndex]);

    const handleClick = (url: string, subtitle: string) => {
        if (socket && socket.connected) {
            const textlinks = `${url} | ${subtitle}`;
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [textlinks],
            });

            console.log("Tıklama kaydedildi:", textlinks);
        } else {
            console.error("Socket bağlantısı yok, tıklama kaydedilemedi.");
        }
    };

    const isScrollable = sponsorCards.length > 6;

    return (
        <div
            className="relative w-full h-auto flex items-center justify-center bg-white"
            style={{ margin: "0 auto", overflow: "hidden", maxWidth: "90%" }}
        >
            {isScrollable && (
                <div className="absolute flex justify-between w-full top-1/2 transform -translate-y-1/2 z-20 pointer-events-auto">
                    <div
                        className="text-red-800 hover:text-red transition-all cursor-pointer ml-4"
                        onClick={() =>
                            slideRefs.current.forEach((ref) =>
                                ref?.scrollBy({ left: -300, behavior: "smooth" })
                            )
                        }
                        style={{
                            zIndex: 50,
                        }}
                    >
                        <span className="text-5xl">&#10094;</span>
                    </div>
                    <div
                        className="text-red-800 hover:text-red transition-all cursor-pointer mr-4"
                        onClick={() =>
                            slideRefs.current.forEach((ref) =>
                                ref?.scrollBy({ left: 300, behavior: "smooth" })
                            )
                        }
                        style={{
                            zIndex: 50,
                        }}
                    >
                        <span className="text-5xl">&#10095;</span>
                    </div>
                </div>
            )}
            <motion.div
                className={`flex ${isScrollable ? "overflow-x-auto" : "justify-center"
                    } scrollbar-hide p-4 w-full scroll-smooth`}
                style={{
                    gap: "20px",
                    scrollSnapType: "x mandatory",
                    scrollPadding: "10px",
                }}
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                {sponsorCards.map((card, index) => (
                    <motion.a
                        key={index}
                        href={card.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        ref={(el) => (slideRefs.current[index] = el)}
                        className="min-w-[200px] max-w-[250px] h-[220px] bg-white rounded-2xl border shadow-md flex flex-col items-center justify-start p-4 snap-center"
                        whileHover={{
                            scale: 1.05,
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                        }}
                        transition={{ duration: 0.3 }}
                        onClick={() => handleClick(card.url, card.subtitle)}
                    >
                        <div
                            className="w-24 h-24 mb-4 flex items-center justify-center rounded-md overflow-hidden"
                            style={{
                                width: "100px",
                                height: "100px",
                            }}
                        >
                            <img
                                src={card.logo}
                                alt="Sponsor Logo"
                                className="object-contain w-full h-full"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                }}
                            />
                        </div>
                        <p
                            className="text-center text-gray-800 text-m font-semibold line-clamp-2 overflow-hidden"
                            style={{
                                fontFamily: "Rajdhani, sans-serif",
                                maxWidth: "90%",
                            }}
                        >
                            {card.subtitle}
                        </p>
                    </motion.a>
                ))}
            </motion.div>
        </div>
    );
};

export default SponsorCardSection;
