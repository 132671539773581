import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import useFairStore from "../../store/fairStore";
import { useParams } from "react-router-dom";
import { useCompany } from "../../services/damiseServices";
import { useSocket } from "../../context/SocketContext"; // Socket kullanımı

interface CompanyHeaderData {
    title: string;
    logoMedia: string;
}

const CompanyHeaderSection = () => {
    const { companyUrl, launchUrl } = useParams<{ companyUrl: string; launchUrl: string }>();
    const [componentData, setComponentData] = useState<CompanyHeaderData>({
        title: "",
        logoMedia: "",
    });
    const { launchData } = useFairStore();
    const {
        data: selectedCompany,
        isLoading: loadingCompany,
        isError: errorCompany,
    } = useCompany(launchData.language, companyUrl);

    const { ref, inView } = useInView({
        triggerOnce: false, // Tekrar tekrar görünürlük kontrolü için
        threshold: 0.3,
    });

    const [viewDuration, setViewDuration] = useState(0); // Görünüm süresi
    const socket = useSocket(); // Socket kullanımı

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000); // Her saniye artır
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: `${launchUrl}-${companyUrl}`,
                    launchId: launchUrl || "default_launch",
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${launchUrl}-${companyUrl} başlık kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, companyUrl, launchUrl, socket]);

    useEffect(() => {
        const createCard = async () => {
            setComponentData((prevData) => ({
                ...prevData,
                title:
                    selectedCompany?.name
                        .replace(/i/g, "İ")
                        .replace(/ı/g, "I")
                        .replace(/ğ/g, "Ğ")
                        .replace(/ü/g, "Ü")
                        .replace(/ş/g, "Ş")
                        .replace(/ö/g, "Ö")
                        .replace(/ç/g, "Ç")
                        .toUpperCase() || "",
                logoMedia: selectedCompany?.logo?.url || "",
            }));
        };

        createCard();
    }, [launchData, useParams, companyUrl, selectedCompany]);

    if (!componentData.title) return <></>;

    return (
        <div
            ref={ref}
            className="flex justify-between items-center w-full h-[120px] mx-auto bg-white relative p-12 lg:px-16"
        >
            {/* Sol tarafta başlık */}
            <motion.h1
                className="text-gray-800 font-bold max-w-[60vw] text-2xl md:text-3xl lg:text-4xl xl:text-5xl"
                style={{
                    fontFamily: "Radhanji, sans-serif",
                    textShadow: "3px 3px 6px rgba(0, 0, 0, 0.3)",
                }}
                initial={{ x: -100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: "easeOut" }}
            >
                {componentData.title}
            </motion.h1>

            {/* Sağ tarafta logo */}
            <motion.div
                className="flex items-center h-full"
                initial={{ x: 100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: "easeOut" }}
            >
                <img
                    src={componentData?.logoMedia}
                    alt="Logo"
                    className="object-contain"
                    style={{ width: "190px", height: "190px" }}
                />
            </motion.div>
        </div>
    );
};

export default CompanyHeaderSection;
