import React, { useEffect, useState } from "react";
import axios from "axios"; // Axios kütüphanesini import ediyoruz
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CTACardSection from "../UI/sections/CTACardSection";
import FullScreenCardSliderSection from "../UI/sections/FullScreenCardSliderSection";
import FullTextCardSection from "../UI/sections/FullTextCardSection";
import HeaderSection from "../UI/sections/HeaderSection";
import AccordionRightCardSection from "../UI/sections/AccordionRightCardSection";
import InfoCardSliderSection from "../UI/sections/InfoCardSliderSection";
import MiniCardSliderSection from "../UI/sections/MiniCardSliderSection";
import ReelsCardSliderSection from "../UI/sections/ReelsCardSliderSection";
import TitleCardSection from "../UI/sections/TitleCardSection";
import TwinTopTitleHeroCardSection from "../UI/sections/TwinTopTitleHeroCardSection";
import LargeFlipCardSection from "../UI/sections/LargeFlipCardSection";
import LargePopupCardSection from "../UI/sections/LargePopupCardSection";
import LargeScalableCardSection from "../UI/sections/LargeScalableCardSection";
import TwinCardSection from "../UI/sections/TwinCardSection";
import LargeTopTitleHeroCardSection from "../UI/sections/LargeTopTitleHeroCardSection";
import RightTextCardSection from "../UI/sections/RightTextCardSection";
import LeftTextCardSection from "../UI/sections/LeftTextCardSection";
import TopTextCardSection from "../UI/sections/TopTextCardSection";
import LargeCardSection from "../UI/sections/LargeCardSection";
import TwinFlipCardSection from "../UI/sections/TwinFlipCardSection";
import BottomTextCardSection from "../UI/sections/BottomTextCardSection";
import ReelsCardSliderWithExploreSection from "../UI/sections/ReelsCardSliderWithExploreSection";
import SearchComponentSection from "../UI/sections/SearchComponentSection";
import SpacingSection from "../UI/sections/SpacingSection";
import BannerSection from "../UI/sections/BannerSection";
import ShareLinks from "../components/ShareLinks";
import ServiceCardSection from "../UI/sections/ServiceCardSection";
import CompanyNameSection from "../UI/sections/CompanyNameSection";
import ProductCardSection from "../UI/sections/ProductCardSection";
import CompanyReelsCardSliderSection from "../UI/sections/CompanyReelsCardSliderSection";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import CompanyHeaderSection from "../UI/sections/CompanyHeaderSection";
import CompanyLargeScalableCardSection from "../UI/sections/CompanyLargeScalableCardSection";
import CompanyProductCardSection from "../UI/sections/CompanyProductCardSection";
import CompanyServiceCardSection from "../UI/sections/CompanyServiceCardSection";
import CompanyLargePopupCardSection from "../UI/sections/CompanyLargePopupCardSection";
import CompanyFullTextCardSection from "../UI/sections/CompanyFullTextCardSection";
import TextMiniSliderCardSection from "../UI/sections/TextMiniSliderCardSection";
import SponsorCardSection from "../UI/sections/SponsorCardSection";

interface SeoData {
    title: string;
    description: string;
    keywords: string;
    socialImage: string;
    url: string;
}

const LaunchPage: React.FC<any> = (components: any) => {
    const { launchUrl } = useParams<{ launchUrl: string }>(); // URL parametresini alıyoruz
    const [seoData, setSeoData] = useState<SeoData>({
        title: "",
        description: "",
        keywords: "",
        socialImage: "",
        url: "",
    });

    // SEO bilgilerini API'den çekme
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";

        const fetchSeoSettings = async () => {
            try {
                const response = await axios.get<SeoData>(
                    `${apiUrl}/seosettings/url/${launchUrl}`
                );
                setSeoData(response.data); // SEO verilerini state'e kaydediyoruz
            } catch (error) {
                console.error("SEO ayarları alınırken bir hata oluştu:", error);
            }
        };

        fetchSeoSettings();
    }, [launchUrl]);

    return (
        <div>
            {/* SEO meta verilerini dinamik olarak ayarlıyoruz */}
            <Helmet>
                <title>{seoData.title}</title>
                <meta name="description" content={seoData.description} />
                <meta name="keywords" content={seoData.keywords} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={seoData.title} />
                <meta property="og:description" content={seoData.description} />
                <meta property="og:image" content={seoData.socialImage} />
                <meta property="og:url" content={seoData.url} />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={seoData.title} />
                <meta
                    name="twitter:description"
                    content={seoData.description}
                />
                <meta name="twitter:image" content={seoData.socialImage} />

                {/* Canonical URL */}
                <link rel="canonical" href={seoData.url} />
            </Helmet>

            <Navbar />
            <ShareLinks
                title={seoData.title}
                description={seoData.description}
                socialImage={seoData.socialImage}
            />
            {components.components.map((component: any, index: number) => {
                const sectionData = component.componentData;

                switch (true) {
                    case component.componentName.startsWith("CTA Card"):
                        return (
                            <CTACardSection
                                key={`cta-card-${index}`}
                                title={sectionData.title}
                                subtitle={sectionData.subtitle}
                                buttonText={sectionData.buttonText}
                                buttonUrl={sectionData.buttonUrl}
                                componentName={component.componentName}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith(
                        "SponsorCard"
                    ):
                        return (
                            <SponsorCardSection
                                key={`sponsor-card-${index}`}
                                componentData={sectionData}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith(
                        "TextMiniSliderCard"
                    ):
                        return (
                            <TextMiniSliderCardSection
                                key={`text-mini-slider-card-${index}`}
                                componentData={sectionData}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith("Service Card"):
                        return (
                            <ServiceCardSection
                                key={`service-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith(
                        "CompanyNameComponent"
                    ):
                        return (
                            <CompanyNameSection
                                key={`company-name-component-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith("Product Card"):
                        return (
                            <ProductCardSection
                                key={`product-card-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith("Banner"):
                        return (
                            <BannerSection
                                key={`banner-${index}`}
                                componentData={{
                                    media: sectionData.media,
                                    url: sectionData.url,
                                    buttonText: sectionData.buttonText,
                                    componentName: component.componentName,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith("Spacing"):
                        return (
                            <SpacingSection
                                key={`spacing-${index}`}
                                componentData={sectionData}
                            />
                        );

                    case component.componentName.startsWith("Search Component"):
                        return (
                            <SearchComponentSection
                                key={`search-component-${index}`}
                                componentData={sectionData}
                            />
                        );

                    case component.componentName.startsWith("Bottom Text Card"):
                        return (
                            <BottomTextCardSection
                                key={`bottom-text-card-${index}`}
                                componentData={{
                                    text: sectionData.text,
                                    media: sectionData.media,
                                    componentName: component.componentName,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith("Explorer"):
                        return (
                            <ReelsCardSliderWithExploreSection
                                key={`reels-card-slider-with-explore-${index}`}
                                componentData={{
                                    reelsCards: sectionData.reelsCards || [],
                                    componentName: component.componentName,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith(
                        "Full Screen Card Slider"
                    ):
                        return (
                            <FullScreenCardSliderSection
                                key={`fullscreen-slider-${index}`}
                                componentData={{
                                    componentName: component.componentName, // componentName dinamik olarak ekleniyor
                                    cards: sectionData.cards || [], // Eğer sectionData'da 'cards' varsa, onu ekleyin, yoksa boş array olarak ayarlayın
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith("Full Text"):
                        return (
                            <FullTextCardSection
                                key={`full-text-card-${index}`}
                                componentData={{
                                    text: sectionData.text,
                                    componentName: component.componentName,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith("Header"):
                        return (
                            <HeaderSection
                                key={`header-card-${index}`}
                                componentData={{
                                    componentName: component.componentName, // Dinamik olarak componentName ekleniyor
                                    title: sectionData.title,
                                    logoMedia: sectionData.logoMedia,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith(
                        "Accordion Right Card"
                    ):
                        return (
                            <AccordionRightCardSection
                                key={`accordion-right-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    media: sectionData.media,
                                    accordionSections:
                                        sectionData.accordionSections,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith("Info Card Slider"):
                        return (
                            <InfoCardSliderSection
                                key={`info-card-slider-${index}`}
                                componentData={{
                                    componentName: component.componentName, // Dinamik olarak componentName ekleniyor
                                    infoCards: sectionData.infoCards || [], // infoCards içeriği sectionData'dan çekiliyor, yoksa boş array
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith('Mini Card Slider'):
                        return (
                            <MiniCardSliderSection
                                key={`mini-card-slider-${index}`}
                                componentData={sectionData}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );

                    case component.componentName.startsWith(
                        "Reels Card Slider"
                    ):
                        return (
                            <ReelsCardSliderSection
                                key={`reels-card-slider-${index}`}
                                componentData={{
                                    componentName: component.componentName, // Dinamik olarak componentName ekleniyor
                                    reelsCards: sectionData.reelsCards || [],
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId}
                            />
                        );
                    case component.componentName.startsWith("Title"):
                        return (
                            <TitleCardSection
                                key={`title-card-${index}`}
                                componentData={{
                                    title: sectionData.title,
                                    componentName: component.componentName,
                                }}
                            />
                        );
                    case component.componentName.startsWith(
                        "Twin Top Title Hero Card"
                    ):
                        return (
                            <TwinTopTitleHeroCardSection
                                key={`twin-top-title-hero-card-${index}`}
                                componentData={{
                                    rightCard: sectionData.rightCard, // Doğrudan rightCard'ı geçiriyoruz
                                    leftCard: sectionData.leftCard, // Doğrudan leftCard'ı geçiriyoruz
                                    componentName: component.componentName, // Bileşen adı
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );

                    case component.componentName.startsWith("Large Flip Card"):
                        return (
                            <LargeFlipCardSection
                                key={`large-flip-card-${index}`}
                                componentData={{
                                    frontMedia: sectionData.frontMedia,
                                    componentName: component.componentName,
                                    backMedia: sectionData.backMedia,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith("Large Popup Card"):
                        return (
                            <LargePopupCardSection
                                key={`large-popup-card-${index}`}
                                componentData={{
                                    media: sectionData.media,
                                    poster: sectionData.poster,
                                    componentName: component.componentName,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith(
                        "Large Scalable Card"
                    ):
                        return (
                            <LargeScalableCardSection
                                key={`large-scalable-card-${index}`}
                                componentData={{
                                    media: sectionData.media,
                                    componentName: component.componentName,
                                    text: sectionData.text,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith(
                        "Large Top Title Hero Card"
                    ):
                        return (
                            <LargeTopTitleHeroCardSection
                                key={`large-top-title-hero-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    media: sectionData.media,
                                    title: sectionData.title,
                                    subtitle: sectionData.subtitle,
                                    buttonText: sectionData.buttonText,
                                    buttonUrl: sectionData.buttonUrl,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith("Right Text Card"):
                        return (
                            <RightTextCardSection
                                key={`right-text-card-${index}`}
                                componentData={{
                                    text: sectionData.text,
                                    componentName: component.componentName,
                                    media: sectionData.media,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith("Left Text Card"):
                        return (
                            <LeftTextCardSection
                                key={`left-text-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    text: sectionData.text,
                                    media: sectionData.media,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith("Twin Card"):
                        return (
                            <TwinCardSection
                                key={`twin-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    leftMedia: sectionData.leftMedia,
                                    rightMedia: sectionData.rightMedia,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith("Top Text Card"):
                        return (
                            <TopTextCardSection
                                key={`top-text-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    text: sectionData.text,
                                    media: sectionData.media,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith("Large Card"):
                        return (
                            <LargeCardSection
                                key={`large-card-${index}`}
                                componentData={sectionData} // componentData prop olarak gönderiliyor
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith("Twin Flip Card"):
                        return (
                            <TwinFlipCardSection
                                key={`twin-flip-card-${index}`}
                                componentData={{
                                    rightCard: sectionData.rightCard, // Doğrudan rightCard'ı geçiriyoruz
                                    leftCard: sectionData.leftCard, // Doğrudan leftCard'ı geçiriyoruz
                                    componentName: component.componentName, // Bileşen adı
                                }}
                                 _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith(
                        "Company Header Card"
                    ):
                        return (
                            <CompanyHeaderSection
                                key={`"company-header-card"-${index}`}
                            />
                        );
                    case component.componentName.startsWith(
                        "Company Reels Card Slider"
                    ):
                        return (
                            <CompanyReelsCardSliderSection
                                key={`"company-reels-card-slider"-${index}`}
                            />
                        );

                    case component.componentName.startsWith(
                        "Company Large Scalable Card"
                    ):
                        return (
                            <CompanyLargeScalableCardSection
                                key={`"company-large-scalable-card-section"-${index}`}
                            />
                        );
                    case component.componentName.startsWith(
                        "Company Product Card"
                    ):
                        return (
                            <CompanyProductCardSection
                                key={`"company-product-card-section"-${index}`}
                            />
                        );
                    case component.componentName.startsWith(
                        "Company Service Card"
                    ):
                        return (
                            <CompanyServiceCardSection
                                key={`"company-service-card-section"-${index}`}
                            />
                        );
                    case component.componentName.startsWith(
                        "Company Large Popup Card"
                    ):
                        return (
                            <CompanyLargePopupCardSection
                                key={`"company-large-popup-card-section"-${index}`}
                            />
                        );
                    case component.componentName.startsWith(
                        "Company Full Text Card"
                    ):
                        return (
                            <CompanyFullTextCardSection
                                key={`"company-full-text-card-section"-${index}`}
                            />
                        );
                    default:
                        return null;
                }
            })}

            <Footer />
        </div>
    );
};

export default LaunchPage;
