import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// @ts-ignore
import Flag from "react-world-flags"; // Bayrak ikonları için
import {
    FaChevronDown,
    FaChevronUp,
    FaSearch,
    FaBars,
    FaTimes,
} from "react-icons/fa"; // Chevron, Search, Hamburger ve Close ikonları
import damiseLogo from "../assets/images/Damise.png"; // DAMISE logosunu burada import ediyoruz
import { useSocket } from "../context/SocketContext";
interface SeoSettings {
    userId: string;
    title: string;
    url: string;
    keywords: string;
    description: string;
    socialImage: string;
    indexStatus: boolean;
    followStatus: boolean;
}

interface Launch {
    _id: string;
    groupNumber: string;
    language: "TR" | "EN"; // Dil sadece 'TR' veya 'EN' olacak
    launchName: string;
    // Diğer alanlar...
}

const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const socket = useSocket();
    const { launchUrl, companyUrl } = useParams<{
        launchUrl: string;
        companyUrl?: string;
    }>(); // URL parametresini alıyoruz
    const [language, setLanguage] = useState<"TR" | "EN">("TR"); // Başlangıç dili TR
    const [isOpen, setIsOpen] = useState(false); // Dropdown menüsünün açık/kapalı durumu
    const [isLaunchMenuOpen, setIsLaunchMenuOpen] = useState(false); // Lansman menüsü açık/kapalı durumu
    const [groupNumber, setGroupNumber] = useState<string>(""); // Mevcut lansmanın grup numarası
    const [userId, setUserId] = useState<string>(""); // Mevcut SEO'dan gelen userId
    const [menuOpen, setMenuOpen] = useState(false); // Mobil menü açık/kapalı durumu
    const [visitorId, setVisitorId] = useState<string | null>(null); // VisitorId kontrolü için state

    useEffect(() => {
        // localeStorage'dan visitorId'yi al ve kontrol et
        const storedVisitorId = localStorage.getItem("visitorId");
        setVisitorId(storedVisitorId); // visitorId varsa state'e aktar
    }, []);

    useEffect(() => {
        // URL'ye göre SEO verisini çekiyoruz
        const fetchSeoData = async () => {
            try {
                const response = await axios.get<SeoSettings>(
                    `${process.env.REACT_APP_API_URL}/seosettings/url/${launchUrl}`
                );
                if (response.status === 200) {
                    const seoData = response.data;
                    // SEO'dan gelen userId'yi al
                    setUserId(seoData.userId);

                    // Ardından, lansman veritabanından bilgileri al
                    const launchResponse = await axios.get<Launch>(
                        `${process.env.REACT_APP_API_URL}/launch/${seoData.userId}`
                    );
                    if (launchResponse.status === 200) {
                        const launchData = launchResponse.data;
                        setGroupNumber(launchData.groupNumber); // Grup numarasını al
                        setLanguage(launchData.language); // Dil bilgisini al
                    }
                }
            } catch (error) {
                console.error("SEO ve lansman verisi alınamadı:", error);
            }
        };
        fetchSeoData();
    }, [launchUrl]);

    // Dil değişikliği olduğunda lang attribute'ünü güncelle
    useEffect(() => {
        document.documentElement.lang = language === "TR" ? "tr" : "en";
    }, [language]);

    // Dil değişikliği yapıldığında SEO verisine göre yönlendirme yap ve dropdown'ı kapat
    const handleLanguageChange = async (newLanguage: "TR" | "EN") => {
        try {
            const isCompanyLayout = location.pathname.startsWith(
                `/${launchUrl}/${companyUrl}`
            );
            const isParticipantsLayoutTr = location.pathname.startsWith(
                `/${launchUrl}/katilimcilar`
            );
            console.log("TR", isParticipantsLayoutTr);
            const isParticipantsLayoutEn = location.pathname.startsWith(
                `/${launchUrl}/participants`
            );
            console.log("EN", isParticipantsLayoutEn);

            // Aynı groupNumber'a ve farklı dildeki lansmanı getiriyoruz
            const response = await axios.get<Launch[]>(
                `${process.env.REACT_APP_API_URL}/launch/launches`,
                {
                    params: {
                        groupNumber: groupNumber, // Aynı grup numarasına sahip lansmanı arıyoruz
                        language: newLanguage, // Yeni dil ile lansmanı arıyoruz
                    },
                }
            );
            const currentPath = window.location.pathname;

            if (newLanguage === "TR" && currentPath.startsWith("/en")) {
                // Eğer TR seçilmişse ve URL /en ile başlıyorsa kök (ana domain) rotasına yönlendir
                setLanguage(newLanguage);
                navigate(
                    currentPath === "/en" ? "/" : currentPath.replace("/en", "")
                ); // /en varsa kaldır ve yönlendir
            } else if (newLanguage === "EN" && !currentPath.startsWith("/en")) {
                // Eğer EN seçilmişse ve URL ana sayfa ise, sadece /en'e yönlendir
                if (currentPath === "/") {
                    navigate("/en"); // Ana sayfa için sadece /en
                }
            } else {
                // Zaten uygun dilde ise sadece dili güncelle
                setLanguage(newLanguage);
            }

            const launches = response.data; // Aynı grup numarasındaki lansmanlar

            if (launches.length > 0) {
                if (launchUrl) {
                    const newLaunchId = launches[0]._id; // Yeni dildeki lansmanın ID'sini alıyoruz
                    // Yeni dildeki lansmanın SEO URL'sini bul
                    const seoResponse = await axios.get<SeoSettings>(
                        `${process.env.REACT_APP_API_URL}/seosettings/${newLaunchId}`
                    );
                    if (seoResponse.status === 200) {
                        const newSeoData = seoResponse.data;
                        setLanguage(newLanguage); // Dili güncelle
                        setIsOpen(false); // Menü kapanıyor
                        if (isCompanyLayout) {
                            navigate(`/${newSeoData.url}/${companyUrl}`);
                        } else if (isParticipantsLayoutEn) {
                            navigate(`/${newSeoData.url}/katilimcilar`);
                        } else if (isParticipantsLayoutTr) {
                            navigate(`/${newSeoData.url}/participants`);
                        } else {
                            navigate(`/${newSeoData.url}`); // Yeni sayfaya yönlendiriyoruz
                        }
                    } else if (companyUrl) {
                    }
                }
            } else {
                console.error("İlgili dilde lansman bulunamadı.");
            }
        } catch (error) {
            console.error("Dil değiştirilemedi:", error);
        }
        try {
            const currentPath = window.location.pathname;

            // Türkçe ve İngilizce segment eşleşmeleri
            const urlMappings: Record<string, Record<"TR" | "EN", string>> = {
                "yayindaki-dijital-vitrinler": {
                    TR: "yayindaki-dijital-vitrinler",
                    EN: "ongoing-digital-events",
                },
                "gelecek-dijital-vitrinler": {
                    TR: "gelecek-dijital-vitrinler",
                    EN: "upcoming-digital-events",
                },
                "gecmis-dijital-vitrinler": {
                    TR: "gecmis-dijital-vitrinler",
                    EN: "past-digital-events",
                },
                "giris-yap": { TR: "giris-yap", EN: "login" },
                "ongoing-digital-events": {
                    TR: "yayindaki-dijital-vitrinler",
                    EN: "ongoing-digital-events",
                },
                "upcoming-digital-events": {
                    TR: "gelecek-dijital-vitrinler",
                    EN: "upcoming-digital-events",
                },
                "past-digital-events": {
                    TR: "gecmis-dijital-vitrinler",
                    EN: "past-digital-events",
                },
                login: { TR: "giris-yap", EN: "login" },
            };

            // URL parçalarını ayır
            const segments = currentPath.split("/").filter(Boolean); // Boş segmentleri temizle

            // İlk segment dilse kaldır
            if (segments[0] === "en" || segments[0] === "tr") {
                segments.shift();
            }

            // Belirtilen URL segmentleri dışındaki tüm yolları olduğu gibi bırak
            const hasMapping = segments.some((segment) =>
                Object.keys(urlMappings).includes(segment)
            );

            if (!hasMapping) {
                // Belirtilen eşleşme yoksa mevcut URL'yi olduğu gibi bırak
                setLanguage(newLanguage);
                setIsOpen(false);
                console.warn(
                    "Belirtilen URL eşleşmeleri dışında bir yol, olduğu gibi bırakıldı:",
                    currentPath
                );
                return;
            }

            // Segmentleri çevirmek
            const newSegments = segments.map((segment) => {
                // Mevcut segmentin eşleşen karşılığı varsa, yeni dildeki değerini al
                const mapping = Object.entries(urlMappings).find(
                    ([_, langs]) =>
                        langs[newLanguage] === segment ||
                        langs[language] === segment
                );
                return mapping ? mapping[1][newLanguage] : segment; // Eğer eşleşme yoksa orijinal segmenti kullan
            });

            // Yeni dil segmentini başa ekle
            const newPath = `/${
                newLanguage === "TR" ? "tr" : "en"
            }/${newSegments.join("/")}`;

            // Yeni URL'ye yönlendir
            navigate(newPath);

            // State'i güncelle
            setLanguage(newLanguage);
            setIsOpen(false); // Menü kapanıyor
        } catch (error) {
            console.error("Dil değiştirilemedi:", error);
        }
    };
    // Dil değişikliği olduğunda Navbar sekmelerini güncellemek için MutationObserver kullanıyoruz
    useEffect(() => {
        const observer = new MutationObserver(() => {
            const newLang = document.documentElement.lang;
            setLanguage(newLang === "tr" ? "TR" : "EN");
        });

        const config = { attributes: true, attributeFilter: ["lang"] };
        observer.observe(document.documentElement, config);

        return () => {
            observer.disconnect();
        };
    }, []);
    // Dropdown menüsünü aç/kapa
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Lansman menüsünü aç/kapa
    const toggleLaunchMenu = () => {
        setIsLaunchMenuOpen(!isLaunchMenuOpen);
    };

    // Mobil menüyü aç/kapa
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Çıkış yap işlemi
    const handleLogout = () => {
        const storedVisitorId = localStorage.getItem("visitorId");

        if (storedVisitorId && socket) {
            // Çıkış yapıldığını Socket.IO üzerinden bildir
            socket.emit("logout", { userId: storedVisitorId });
        }

        // localeStorage'dan visitorId'yi sil
        localStorage.removeItem("visitorId");

        // visitorId'yi sıfırla
        setVisitorId(null);

        // Ana sayfaya yönlendir
        navigate("/");
    };

    // Socket.IO bağlantısını yönetme
    useEffect(() => {
        if (socket && visitorId) {
            // Yeni bağlantı kurulduğunda bir etkinlik gönder
            socket.emit("user-connected", { userId: visitorId });
            console.log("Socket bağlandı, kullanıcı ID'si:", visitorId);
        }

        return () => {
            if (socket && visitorId) {
                // Çıkış yapılırken veya bileşenden çıkılırken bir etkinlik gönder
                socket.emit("user-disconnected", { userId: visitorId });
            }
        };
    }, [socket, visitorId]);
    // Sekmeleri dile göre güncellemek için varsayılan içerik
    const menuItems = {
        TR: {
            home: "Ana Sayfa",
            launches: "Dijital Vitrinler",
            currentLaunches: "Yayındaki Etkinlikler",
            upcomingLaunches: "Gelecek Etkinlikler",
            pastLaunches: "Geçmiş Etkinlikler",
            login: "Giriş Yap",
            logout: "Çıkış Yap",
        },
        EN: {
            home: "Homepage", // 'Ana Sayfa' = 'Homepage'
            launches: "Digital Showcase", // 'Dijital Vitrinler' = 'Digital Displays'
            currentLaunches: "Ongoing Events", // 'Yayındaki Etkinlikler' = 'Ongoing Events'
            upcomingLaunches: "Upcoming Events", // 'Gelecek Etkinlikler' = 'Upcoming Events'
            pastLaunches: "Past Events", // 'Geçmiş Etkinlikler' = 'Past Events'
            login: "Sign In", // 'Giriş Yap' = 'Sign In'
            logout: "Sign Out", // 'Çıkış Yap' = 'Sign Out'
        },
    };

    const currentMenu = menuItems[language];

    return (
        <div className="fixed top-0 left-0 w-full px-4 sm:px-8 py-2 bg-white z-50 shadow-md">
            <div className="flex justify-between items-center">
                <div className="flex-shrink-0">
                    <img
                        src={damiseLogo}
                        alt="Logo"
                        className="w-32 sm:w-36 cursor-pointer"
                        onClick={() =>
                            (window.location.href = "https://damise.com")
                        }
                    />
                </div>

                {/* Mobil menü butonu */}
                <div className="sm:hidden">
                    <button onClick={toggleMenu} className="text-gray-700">
                        {menuOpen ? (
                            <FaTimes size={24} />
                        ) : (
                            <FaBars size={24} />
                        )}
                    </button>
                </div>

                {/* Navigasyon ve dil seçenekleri (sadece büyük ekranlarda) */}
                <div className="hidden sm:flex items-center space-x-6">
                    <button
                        onClick={() =>
                            navigate(language === "TR" ? "/tr" : "/en")
                        }
                        className="text-gray-700 hover:text-red-800 text-lg font-medium relative group"
                    >
                        {currentMenu.home}
                        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-800 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
                    </button>

                    <div className="relative">
                        <button
                            onClick={toggleLaunchMenu}
                            className="text-gray-700 hover:text-red-800 text-lg font-medium flex items-center relative group"
                        >
                            {currentMenu.launches}{" "}
                            {isLaunchMenuOpen ? (
                                <FaChevronUp />
                            ) : (
                                <FaChevronDown />
                            )}
                            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-800 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
                        </button>

                        {isLaunchMenuOpen && (
                            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
                                <button
                                    onClick={() =>
                                        navigate(
                                            language === "TR"
                                                ? "/tr/yayindaki-dijital-vitrinler"
                                                : "/en/ongoing-digital-events"
                                        )
                                    }
                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    {currentMenu.currentLaunches}
                                </button>
                                <button
                                    onClick={() =>
                                        navigate(
                                            language === "TR"
                                                ? "/tr/gelecek-dijital-vitrinler"
                                                : "/en/upcoming-digital-events"
                                        )
                                    }
                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    {currentMenu.upcomingLaunches}
                                </button>
                                <button
                                    onClick={() =>
                                        navigate(
                                            language === "TR"
                                                ? "/tr/gecmis-dijital-vitrinler"
                                                : "/en/past-digital-events"
                                        )
                                    }
                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    {currentMenu.pastLaunches}
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Giriş Yap veya Çıkış Yap butonu */}
                    {visitorId && visitorId !== "none_user" ? (
                        <button
                            onClick={handleLogout}
                            className="text-gray-700 border border-gray-400 rounded-full px-4 py-1 text-lg font-medium hover:text-red-800 hover:border-red-800 transition duration-300"
                        >
                            {currentMenu.logout}
                        </button>
                    ) : (
                        <button
                            onClick={() =>
                                navigate(
                                    `/${language === "TR"
                                        ? "tr/giris-yap"
                                        : "en/login"
                                    }`
                                )
                            }
                            className="text-gray-700 border border-gray-400 rounded-full px-4 py-1 text-lg font-medium hover:text-red-800 hover:border-red-800 transition duration-300"
                        >
                            {currentMenu.login}
                        </button>
                    )}

                    {/* Dil Menüsü (Büyük ekranlar için) */}
                    <div className="relative hidden sm:block">
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center space-x-2 text-gray-800 hover:text-red-800 text-lg font-medium border rounded-full px-4 py-1"
                        >
                            <Flag
                                code={language === "TR" ? "TUR" : "GBR"}
                                className="w-5 h-4"
                            />
                            <span className="notranslate">{language}</span>{" "}
                            {/* notranslate ekledim */}
                        </button>

                        {isOpen && (
                            <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                                <button
                                    onClick={() => handleLanguageChange("TR")}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="TUR" className="mr-2 w-6" />
                                    <span>Türkçe</span>
                                </button>
                                <button
                                    onClick={() => handleLanguageChange("EN")}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="GBR" className="mr-2 w-6" />
                                    <span>English</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Mobil menü (küçük ekranlar için) */}
            {menuOpen && (
                <div className="sm:hidden mt-4 space-y-2">
                    <button
                        onClick={() =>
                            navigate(language === "TR" ? "/tr" : "/en")
                        }
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        {currentMenu.home}
                    </button>
                    <button
                        onClick={() =>
                            navigate(
                                language === "TR"
                                    ? "/tr/yayindaki-dijital-vitrinler"
                                    : "/en/ongoing-digital-events"
                            )
                        }
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        {currentMenu.currentLaunches}
                    </button>
                    <button
                        onClick={() =>
                            navigate(
                                language === "TR"
                                    ? "/tr/gelecek-dijital-vitrinler"
                                    : "/en/upcoming-digital-events"
                            )
                        }
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        {currentMenu.upcomingLaunches}
                    </button>
                    <button
                        onClick={() =>
                            navigate(
                                language === "TR"
                                    ? "/tr/gecmis-dijital-vitrinler"
                                    : "/en/past-digital-events"
                            )
                        }
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        {currentMenu.pastLaunches}
                    </button>
                    <button
                        onClick={() =>
                            navigate(
                                `/${
                                    language === "TR"
                                        ? "tr/giris-yap"
                                        : "en/login"
                                }`
                            )
                        }
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        {currentMenu.login}
                    </button>

                    {/* Mobil Dil Menüsü */}
                    <div className="relative">
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                        >
                            <Flag
                                code={language === "TR" ? "TUR" : "GBR"}
                                className="mr-2 w-6"
                            />
                            <span className="notranslate">{language}</span>{" "}
                            {/* notranslate ekledim */}
                        </button>
                        {isOpen && (
                            <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                                <button
                                    onClick={() => handleLanguageChange("TR")}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="TUR" className="mr-2 w-6" />
                                    <span>Türkçe</span>
                                </button>
                                <button
                                    onClick={() => handleLanguageChange("EN")}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="GBR" className="mr-2 w-6" />
                                    <span>English</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
