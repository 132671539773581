import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { useSocket } from "../../context/SocketContext";

interface TextMiniSliderCardSectionProps {
    componentData: {
        cards: {
            buttonText: string;
            buttonUrl: string;
            media: string;
            text: string;
        }[];
    };
    designId: string;
    _id: string;
}

const TextMiniSliderCardSection: React.FC<TextMiniSliderCardSectionProps> = ({
    componentData,
    designId,
    _id,
}) => {
    const { cards } = componentData;
    const socket = useSocket();
    const slideRefs = useRef<(HTMLElement | null)[]>([]);
    const observer = useRef<IntersectionObserver | null>(null);
    const swiperRef = useRef<any>(null); // Swiper referansı burada tanımlandı
    const [lastReportedIndex, setLastReportedIndex] = useState<number | null>(null);

    // IntersectionObserver ile görünürlük kontrolü
    useEffect(() => {
        observer.current = new IntersectionObserver(
            (entries) => {
                const visibleEntries = entries.filter((entry) => entry.isIntersecting);
                if (visibleEntries.length > 0) {
                    const firstVisibleIndex = slideRefs.current.findIndex(
                        (ref) => ref === visibleEntries[0].target
                    );

                    if (
                        firstVisibleIndex !== -1 &&
                        firstVisibleIndex !== lastReportedIndex
                    ) {
                        setLastReportedIndex(firstVisibleIndex);
                        const card = cards[firstVisibleIndex];
                        if (socket) {
                            socket.emit("activity", {
                                userId: localStorage.getItem("visitorId"),
                                componentId: _id,
                                launchId: designId,
                                activityType: "view",
                                texts: [{ text: card.text, count: 1 }],
                            });
                        }
                        console.log(`Kullanıcı şu kartı izliyor: ${card.text}`);
                    }
                }
            },
            { threshold: 0.5 }
        );

        slideRefs.current.forEach((ref) => {
            if (ref) observer.current?.observe(ref);
        });

        return () => {
            slideRefs.current.forEach((ref) => {
                if (ref) observer.current?.unobserve(ref);
            });
        };
    }, [cards, socket, _id, designId, lastReportedIndex]);

    const handleClick = (mediaUrl: string, text: string) => {
        if (socket && socket.connected) {
            const textlinks = [mediaUrl, text].join(" | ");
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId"),
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [textlinks],
            });
            console.log("Tıklama kaydedildi:", textlinks);
        }
    };

    const renderMedia = (mediaUrl: string) => {
        const fileExtension = mediaUrl.split(".").pop()?.toLowerCase();
        const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
        const videoTypes = ["mp4", "webm", "ogg"];

        if (imageTypes.includes(fileExtension || "")) {
            return (
                <img
                    src={mediaUrl}
                    alt="Card Media"
                    className="w-full h-full object-cover rounded-t-3xl"
                />
            );
        } else if (videoTypes.includes(fileExtension || "")) {
            return (
                <video
                    className="w-full h-full object-cover rounded-t-3xl"
                    autoPlay
                    loop
                    muted
                >
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else {
            return (
                <p className="text-gray-500 text-center">
                    Desteklenmeyen medya formatı
                </p>
            );
        }
    };

    return (
        <div className="relative w-full flex items-center justify-center bg-white">
            <Swiper
                ref={swiperRef} // Swiper ref ile bağlandı
                modules={[Navigation, Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                loop={cards.length > 4}
                speed={800}
                breakpoints={{
                    640: { slidesPerView: 1.5, spaceBetween: 10 },
                    768: { slidesPerView: 2.5, spaceBetween: 15 },
                    1024: { slidesPerView: 4, spaceBetween: 20 },
                }}
                className="!p-0"
            >
                {cards.map((card, index) => (
                    <SwiperSlide key={index}>
                        <div
                            ref={(el) => (slideRefs.current[index] = el)}
                            className="w-[96%] md:w-[85%] lg:w-[80%] h-[400px] bg-white rounded-3xl mx-auto overflow-hidden group flex flex-col items-stretch"
                        >
                            <div className="relative h-[60%] flex-shrink-0">
                                {renderMedia(card.media)}
                                <a
                                    href={card.buttonUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => handleClick(card.buttonUrl, card.text)}
                                    className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black/50"
                                >
                                    <span
                                        className="px-8 py-3 text-lg font-medium text-white bg-transparent border-2 border-white rounded-full hover:bg-white hover:text-black transition-all duration-300"
                                        style={{ fontFamily: "Rajdhani, sans-serif" }}
                                    >
                                        {card.buttonText}
                                    </span>
                                </a>
                            </div>
                            <div
                                className="relative h-[40%] px-0 py-4 bg-white rounded-b-3xl flex flex-col justify-between text-left"
                                style={{
                                    width: "100%",
                                }}
                            >
                                <p
                                    className="text-gray-800 text-l md:text-base font-medium leading-snug tracking-wide px-4"
                                    style={{
                                        fontFamily: "Rajdhani, sans-serif",
                                        wordBreak: "break-word",
                                        overflowWrap: "break-word",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {card.text}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div
                className="absolute left-4 text-red-800 hover:text-red transition-all cursor-pointer top-1/2 transform -translate-y-1/2 z-10"
                onClick={() => swiperRef.current?.swiper.slidePrev()}
            >
                <span className="text-5xl">&#10094;</span>
            </div>
            <div
                className="absolute right-4 text-red-800 hover:text-red transition-all cursor-pointer top-1/2 transform -translate-y-1/2 z-10"
                onClick={() => swiperRef.current?.swiper.slideNext()}
            >
                <span className="text-5xl">&#10095;</span>
            </div>
        </div>
    );
};

export default TextMiniSliderCardSection;
