//import { useLanguage } from 'hooks'
//import { Head, Footer, Navbar } from "components";
import React from "react";
import type { ReactNode, FC } from "react";

interface Props {
    children: ReactNode;
    //title: string;
}

export const SearchLayout: FC<Props> = (props) => {
    const { children } = props;
    //const { t } = useLanguage();

    return (
        <div className="flex w-full flex-col overflow-hidden lg:pb-0">
            <div className={"App px-8 2xl:px-32"}>{children}</div>
        </div>
    );
};
