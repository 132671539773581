import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation, A11y, Autoplay } from "swiper/modules";
import { useSocket } from "../../context/SocketContext";

interface MiniCardSliderSectionProps {
    componentData: {
        cards: {
            buttonText: string;
            buttonUrl: string;
            text: string;
            logoMedia: string;
            backgroundMedia?: string;
            videoMedia?: string;
        }[];
        componentName: string;
    };
    designId: string;
    _id: string;
}

const MiniCardSliderSection: React.FC<MiniCardSliderSectionProps> = ({
    componentData,
    designId,
    _id,
}) => {
    const { cards } = componentData;
    const socket = useSocket();
    const slideRefs = useRef<(HTMLElement | null)[]>([]);
    const observer = useRef<IntersectionObserver | null>(null);
    const [lastReportedIndex, setLastReportedIndex] = useState<number | null>(
        null
    );

    useEffect(() => {
        observer.current = new IntersectionObserver(
            (entries) => {
                // Sadece en baştaki kartı kontrol etmek için
                const visibleEntries = entries.filter((entry) => entry.isIntersecting);
                if (visibleEntries.length > 0) {
                    const firstVisibleIndex = slideRefs.current.findIndex(
                        (ref) => ref === visibleEntries[0].target
                    );

                    if (
                        firstVisibleIndex !== -1 &&
                        firstVisibleIndex !== lastReportedIndex
                    ) {
                        // Sadece yeni görünen en baştaki kartı gönder
                        setLastReportedIndex(firstVisibleIndex);
                        const card = cards[firstVisibleIndex];
                        if (socket) {
                            socket.emit("activity", {
                                userId: localStorage.getItem("visitorId"),
                                componentId: _id,
                                launchId: designId,
                                activityType: "view",
                                texts: [{ text: card.text, count: 1 }],
                            });
                        }

                        console.log(`Kullanıcı şu kartı izliyor: ${card.text}`);
                    }
                }
            },
            { threshold: 0.5 }
        );

        slideRefs.current.forEach((ref) => {
            if (ref) observer.current?.observe(ref);
        });

        return () => {
            slideRefs.current.forEach((ref) => {
                if (ref) observer.current?.unobserve(ref);
            });
        };
    }, [cards, socket, _id, designId, lastReportedIndex]);

    const handleClick = (mediaUrl: string, text: string) => {
        if (socket && socket.connected) {
            const textlinks = [mediaUrl, text].join(" | ");
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId"),
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [textlinks],
            });
            console.log("Tıklama kaydedildi:", textlinks);
        }
    };

    const renderMedia = (mediaUrl: string, text: string) => {
        const fileExtension = mediaUrl.split(".").pop()?.toLowerCase();
        const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
        const videoTypes = ["mp4", "webm", "ogg"];
        const audioTypes = ["mp3", "wav", "ogg"];

        if (imageTypes.includes(fileExtension || "")) {
            return (
                <img
                    src={mediaUrl}
                    alt="Görsel medya"
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                    onClick={() => handleClick(mediaUrl, text)}
                />
            );
        } else if (videoTypes.includes(fileExtension || "")) {
            return (
                <video
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                    autoPlay
                    loop
                    muted
                    onClick={() => handleClick(mediaUrl, text)}
                >
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else if (audioTypes.includes(fileExtension || "")) {
            return (
                <audio
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                    controls
                    onClick={() => handleClick(mediaUrl, text)}
                >
                    <source src={mediaUrl} type={`audio/${fileExtension}`} />
                    Tarayıcınız bu sesi desteklemiyor.
                </audio>
            );
        } else {
            return (
                <p className="text-white">Desteklenmeyen medya formatı: {fileExtension}</p>
            );
        }
    };

    return (
        <div className="relative w-full flex items-center justify-center bg-white">
            <Swiper
                modules={[Pagination, Navigation, A11y, Autoplay]}
                navigation
                autoplay={{ delay: 1500, disableOnInteraction: false }}
                speed={1500}
                loop={cards.length > 4}
                breakpoints={{
                    640: { slidesPerView: 1, spaceBetween: 10 },
                    768: { slidesPerView: 2, spaceBetween: 15 },
                    1024: { slidesPerView: 3, spaceBetween: 30 },
                    1280: { slidesPerView: 4, spaceBetween: 40 },
                }}
            >
                {cards.map((card, index) => (
                    <SwiperSlide key={index}>
                        <div
                            ref={(el) => (slideRefs.current[index] = el)}
                            className="w-[85%] h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl mx-auto"
                        >
                            {card.backgroundMedia
                                ? renderMedia(card.backgroundMedia, card.text)
                                : card.logoMedia
                                    ? renderMedia(card.logoMedia, card.text)
                                    : "Medya bulunamadı"}
                            <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent">
                                <p className="text-base font-semibold">{card.text}</p>
                                <a
                                    href={card.buttonUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => handleClick(card.buttonUrl, card.text)}
                                    className="absolute flex items-center justify-center px-6 py-2 text-white bg-white bg-opacity-20 border border-white rounded-full opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300"
                                    style={{
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        textShadow:
                                            "0 0 10px rgba(0, 0, 0, 0.8), 0 0 15px rgba(0, 0, 0, 0.6)",
                                    }}
                                >
                                    {card.buttonText}
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default MiniCardSliderSection;
