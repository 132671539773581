import React, { useState, useRef, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";

interface LargeFlipCardSectionProps {
    componentData: {
        frontMedia: string;
        backMedia: string;
        componentName: string;
    };
    marginTop?: number; // Dinamik margin-top..
    marginBottom?: number; // Dinamik margin-bottom..
    _id: string; // Component ID
    designId: string; // Launch ID
}

const LargeFlipCardSection: React.FC<LargeFlipCardSectionProps> = ({
    componentData,
    marginTop = 0,
    marginBottom = 0,
    _id,
    designId,
}) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false); // Video oynatma durumu
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || "homepage"; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const videoRef = useRef<HTMLVideoElement>(null);

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.5, // Kartın %50'si görünür olduğunda tetikler
    });

    const socket = useSocket();
    const [viewDuration, setViewDuration] = useState<number>(0);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1); // Görünüm süresini artır
            }, 1000);
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, socket, _id, designId]);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    const handleBackgroundClick = () => {
        if (isFlipped) {
            setIsFlipped(false);
        }
    };

    const renderMedia = (mediaUrl: string) => {
        const mediaExtension = mediaUrl.split(".").pop()?.toLowerCase();
        if (
            mediaExtension === "mp4" ||
            mediaExtension === "webm" ||
            mediaExtension === "ogg"
        ) {
            return (
                <video
                    ref={videoRef}
                    className="object-cover w-full h-full rounded-3xl"
                    onClick={togglePlayPause}
                    loop
                    muted
                >
                    <source src={mediaUrl} type={`video/${mediaExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }
        return (
            <img
                src={mediaUrl}
                alt="Media"
                className="object-cover w-full h-full rounded-3xl"
            />
        );
    };

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const sectionStyle: React.CSSProperties = {
        opacity: 0,
        transform: "translateY(50px)",
        transition: "transform 1s ease, opacity 1s ease",
    };

    const visibleStyle: React.CSSProperties = {
        opacity: 1,
        transform: "translateY(0)",
        transition: "transform 1s ease, opacity 1s ease",
    };

    return (
        <div
            className="relative"
            style={{
                marginTop: `${marginTop}px`,
                marginBottom: `${marginBottom}px`,
            }}
        >
            {isFlipped && (
                <div
                    className="fixed inset-0 z-10"
                    onClick={handleBackgroundClick}
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        animation: "fade 2s infinite alternate",
                    }}
                ></div>
            )}

            <div
                ref={ref}
                className={`flex justify-center items-center space-x-8 p-8 bg-white-100 z-20 relative ${isFlipped ? "fixed inset-0" : ""
                    }`}
                style={{
                    ...(inView ? visibleStyle : sectionStyle),
                }}
            >
                <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                    {/* Ön kart */}
                    <div
                        className="relative w-full sm:w-[85vw] h-[50vh] sm:h-[70vh] bg-transparent shadow-2xl flex items-center justify-center z-30 rounded-3xl transition-all duration-700 ease-in-out"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {renderMedia(componentData.frontMedia)}
                        <button
                            className="absolute bottom-4 right-4 bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 transition-colors bg-opacity-20 hover:bg-opacity-50"
                            onClick={handleFlip}
                            style={{
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 4v16m8-8H4"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* Arka kart */}
                    <div
                        className="relative w-full sm:w-[85vw] h-[50vh] sm:h-[70vh] bg-transparent shadow-2xl flex items-center justify-center z-30 rounded-3xl transition-all duration-700 ease-in-out"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {renderMedia(componentData.backMedia)}
                        <button
                            className="absolute bottom-4 right-4 bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 transition-colors bg-opacity-20 hover:bg-opacity-50"
                            onClick={handleFlip}
                            style={{
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </ReactCardFlip>
            </div>

            <style>
                {`
                    @keyframes fade {
                        0% { opacity: 0.3; }
                        100% { opacity: 0.6; }
                    }
                `}
            </style>
        </div>
    );
};

export default LargeFlipCardSection;
