import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar'; // Yeni eklenen navbar importu
import Footer from '../components/Footer';
import CTACardSection from '../UI/sections/CTACardSection';
import FullScreenCardSliderSection from '../UI/sections/FullScreenCardSliderSection';
import FullTextCardSection from '../UI/sections/FullTextCardSection';
import HeaderSection from '../UI/sections/HeaderSection';
import AccordionRightCardSection from '../UI/sections/AccordionRightCardSection';
import InfoCardSliderSection from '../UI/sections/InfoCardSliderSection';
import MiniCardSliderSection from '../UI/sections/MiniCardSliderSection';
import ReelsCardSliderSection from '../UI/sections/ReelsCardSliderSection';
import TitleCardSection from '../UI/sections/TitleCardSection';
import TwinTopTitleHeroCardSection from '../UI/sections/TwinTopTitleHeroCardSection';
import LargeFlipCardSection from '../UI/sections/LargeFlipCardSection';
import LargePopupCardSection from '../UI/sections/LargePopupCardSection';
import LargeScalableCardSection from '../UI/sections/LargeScalableCardSection';
import TwinCardSection from '../UI/sections/TwinCardSection';
import LargeTopTitleHeroCardSection from '../UI/sections/LargeTopTitleHeroCardSection';
import RightTextCardSection from '../UI/sections/RightTextCardSection';
import LeftTextCardSection from '../UI/sections/LeftTextCardSection';
import TopTextCardSection from '../UI/sections/TopTextCardSection';
import LargeCardSection from '../UI/sections/LargeCardSection';
import TwinFlipCardSection from '../UI/sections/TwinFlipCardSection';
import BottomTextCardSection from '../UI/sections/BottomTextCardSection';
import SearchComponentSection from '../UI/sections/LaunchSearchPage';
import SpacingSection from '../UI/sections/SpacingSection';
import ReelsCardSliderSectionWithExplore from '../UI/sections/ReelsCardSliderWithExploreSection';
import BannerSection from '../UI/sections/BannerSection';
import { FaCopy } from 'react-icons/fa';
import ShareLinks from '../components/ShareLinks';
import SponsorCardSection from '../UI/sections/SponsorCardSection';
import TextMiniSliderCardSection from '../UI/sections/TextMiniSliderCardSection';


interface ComponentData {
    _id: string;
    designId: string;
    componentName: string;
    componentData: any;
    row: number;
    showInFragman: boolean;
}

interface PreviewPageProps {
    launchId?: string; // launchId opsiyonel hale getirildi
    showShareLinks?: boolean;
}

const PreviewPage: React.FC<PreviewPageProps> = ({ launchId }) => {
    const { id } = useParams<{ id: string }>();
    const [loadedSections, setLoadedSections] = useState<ComponentData[]>([]);


    const currentId = launchId || id;

    useEffect(() => {
        const fetchComponents = async () => {
            try {
                const response = await axios.get<ComponentData[]>(`${process.env.REACT_APP_API_URL}/components/${currentId}`);
                if (response.status === 200) {
                    const components = response.data;
                    const sortedComponents = components.sort((a, b) => a.row - b.row);
                    setLoadedSections(sortedComponents);
                } else {
                    console.error('Failed to fetch components');
                }
            } catch (error) {
                console.error('Error fetching components:', error);
            }
        };

        fetchComponents();
    }, [currentId]);



    return (
        <div>
            <Navbar />

            {/* İçerikler burada yer alacak */}
            {loadedSections.map((component, index) => {
                const sectionData = component.componentData;

                switch (true) {
                    case component.componentName.startsWith('CTA Card'):
                        return (
                            <CTACardSection
                                key={`cta-card-${index}`}
                                title={sectionData.title}
                                subtitle={sectionData.subtitle}
                                buttonText={sectionData.buttonText}
                                buttonUrl={sectionData.buttonUrl}
                                componentName={component.componentName}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith('Banner'):
                        return (
                            <BannerSection
                                key={`banner-${index}`}
                                componentData={{
                                    media: sectionData.media,
                                    url: sectionData.url,
                                    buttonText: sectionData.buttonText,
                                    componentName: component.componentName

                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith('Spacing'):
                        return (
                            <SpacingSection
                                key={`spacing-${index}`}
                                componentData={sectionData}
                            />
                        );
                    case component.componentName.startsWith(
                        "SponsorCard"
                    ):
                        return (
                            <SponsorCardSection
                                key={`sponsor-card-${index}`}
                                componentData={sectionData}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith(
                        "TextMiniSliderCard"
                    ):
                        return (
                            <TextMiniSliderCardSection
                                key={`text-mini-slider-card-${index}`}
                                componentData={sectionData}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith('Search Component'):
                        return (
                            <SearchComponentSection
                                key={`search-component-${index}`}
                                componentData={sectionData}
                            />
                        );

                    case component.componentName.startsWith('Bottom Text Card'):
                        return (
                            <BottomTextCardSection
                                key={`bottom-text-card-${index}`}
                                componentData={
                                    {
                                        text: sectionData.text,
                                        media: sectionData.media,
                                        componentName: component.componentName
                                    }
                                }
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith('Explorer'):
                        return (
                            <ReelsCardSliderSectionWithExplore
                                key={`reels-card-slider-with-explore-${index}`}
                                componentData={{
                                    reelsCards: sectionData.reelsCards || [],
                                    componentName: component.componentName,

                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        )
                    case component.componentName.startsWith('Full Screen Card Slider'):
                        return (
                            <FullScreenCardSliderSection
                                key={`fullscreen-slider-${index}`}
                                componentData={{
                                    componentName: component.componentName,  // componentName dinamik olarak ekleniyor
                                    cards: sectionData.cards || []  // Eğer sectionData'da 'cards' varsa, onu ekleyin, yoksa boş array olarak ayarlayın
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith('Full Text'):
                        return (
                            <FullTextCardSection
                                key={`full-text-card-${index}`}
                                componentData={
                                    {
                                        text: sectionData.text,
                                        componentName: component.componentName
                                    }

                                }
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith('Header'):
                        return (
                            <HeaderSection
                                key={`header-card-${index}`}
                                componentData={{
                                    componentName: component.componentName, // Dinamik olarak componentName ekleniyor
                                    title: sectionData.title,
                                    logoMedia: sectionData.logoMedia
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID

                            />
                        );
                    case component.componentName.startsWith('Accordion Right Card'):
                        return (
                            <AccordionRightCardSection
                                key={`accordion-right-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    media: sectionData.media,
                                    accordionSections: sectionData.accordionSections
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith('Info Card Slider'):
                        return (
                            <InfoCardSliderSection
                                key={`info-card-slider-${index}`}
                                componentData={{
                                    componentName: component.componentName,  // Dinamik olarak componentName ekleniyor
                                    infoCards: sectionData.infoCards || []   // infoCards içeriği sectionData'dan çekiliyor, yoksa boş array
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );

                    case component.componentName.startsWith('Mini Card Slider'):
                        return (
                            <MiniCardSliderSection
                                key={`mini-card-slider-${index}`}
                                componentData={sectionData}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );

                    case component.componentName.startsWith('Reels Card Slider'):
                        return (
                            <ReelsCardSliderSection
                                key={`reels-card-slider-${index}`}
                                componentData={{
                                    componentName: component.componentName, // Dinamik olarak componentName ekleniyor
                                    reelsCards: sectionData.reelsCards || []
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId}
                            />
                        );
                    case component.componentName.startsWith('Title'):
                        return (
                            <TitleCardSection
                                key={`title-card-${index}`}
                                componentData={{
                                    title: sectionData.title,
                                    componentName: component.componentName
                                }}
                            />
                        );
                    case component.componentName.startsWith('Twin Top Title Hero Card'):
                        return (
                            <TwinTopTitleHeroCardSection
                                key={`twin-top-title-hero-card-${index}`}
                                componentData={{
                                    rightCard: sectionData.rightCard,  // Doğrudan rightCard'ı geçiriyoruz
                                    leftCard: sectionData.leftCard,    // Doğrudan leftCard'ı geçiriyoruz
                                    componentName: component.componentName // Bileşen adı
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );

                    case component.componentName.startsWith('Large Flip Card'):
                        return (
                            <LargeFlipCardSection
                                key={`large-flip-card-${index}`}
                                componentData={{
                                    frontMedia: sectionData.frontMedia,
                                    componentName: component.componentName,
                                    backMedia: sectionData.backMedia

                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Large Popup Card'):
                        return (
                            <LargePopupCardSection
                                key={`large-popup-card-${index}`}
                                componentData={{
                                    media: sectionData.media,
                                    componentName: component.componentName,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Large Scalable Card'):
                        return (
                            <LargeScalableCardSection
                                key={`large-scalable-card-${index}`}
                                componentData={{
                                    media: sectionData.media,
                                    componentName: component.componentName,
                                    text: sectionData.text,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Large Top Title Hero Card'):
                        return (
                            <LargeTopTitleHeroCardSection
                                key={`large-top-title-hero-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    media: sectionData.media,
                                    title: sectionData.title,
                                    subtitle: sectionData.subtitle,
                                    buttonText: sectionData.buttonText,
                                    buttonUrl: sectionData.buttonUrl,
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Right Text Card'):
                        return (
                            <RightTextCardSection
                                key={`right-text-card-${index}`}
                                componentData={{
                                    text: sectionData.text,
                                    componentName: component.componentName,
                                    media: sectionData.media
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Left Text Card'):
                        return (
                            <LeftTextCardSection
                                key={`left-text-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    text: sectionData.text,
                                    media: sectionData.media,

                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Twin Card'):
                        return (
                            <TwinCardSection
                                key={`twin-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    leftMedia: sectionData.leftMedia,
                                    rightMedia: sectionData.rightMedia
                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Top Text Card'):
                        return (
                            <TopTextCardSection
                                key={`top-text-card-${index}`}
                                componentData={{
                                    componentName: component.componentName,
                                    text: sectionData.text,
                                    media: sectionData.media,

                                }}
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID 
                            />
                        );
                    case component.componentName.startsWith('Large Card'):
                        return (
                            <LargeCardSection
                                key={`large-card-${index}`}
                                componentData={sectionData} // componentData prop olarak gönderiliyor
                                _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    case component.componentName.startsWith('Twin Flip Card'):
                        return (
                            <TwinFlipCardSection
                                key={`twin-flip-card-${index}`}
                                componentData={{
                                    rightCard: sectionData.rightCard,  // Doğrudan rightCard'ı geçiriyoruz
                                    leftCard: sectionData.leftCard,    // Doğrudan leftCard'ı geçiriyoruz
                                    componentName: component.componentName // Bileşen adı
                                }}
                                 _id={component._id} // Component ID
                                designId={component.designId} // Launch ID
                            />
                        );
                    default:
                        return null;
                }
            })}

            <Footer />



        </div>
    );
};

export default PreviewPage;