import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import useFairStore from "../../store/fairStore";
import VisitForm from "../../components/componentmodals/VisitForm";
import { useNavigate, useParams } from "react-router-dom";

interface ReelsCard {
    media: string;
    title: string;
    subtitle?: string;
    exploreUrl?: string;
    buttonText?: string;
    logo: boolean;
}

const CompanyReelsCardSliderSection = () => {
    const { launchUrl } = useParams<{ launchUrl: string }>();
    const sliderRef = useRef<HTMLDivElement>(null);
    const [visitorId, setVisitorId] = useState<string | null>(null);
    const [showVisitForm, setShowVisitForm] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
    const [reelsCards, setReelsCards] = useState<ReelsCard[]>([
        {
            media: "",
            title: "",
            subtitle: "",
            exploreUrl: "test",
            buttonText: "Görüntüle",
            logo: false,
        },
    ]);
    const [selectedCard, setSelectedCard] = useState<ReelsCard | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isPaused, setIsPaused] = useState(false); // Kaydırmayı durdurma durumu
    const { fairData, launchData } = useFairStore();
    const navigate = useNavigate();

    // Kart sayısını iki katına çıkarıyoruz (sonsuz döngü etkisi için)
    const duplicatedReelsCards = [...reelsCards, ...reelsCards];

    const anonymousImage =
        "https://via.placeholder.com/400x700?text=No+Media+Selected";

    useEffect(() => {
        const createCard = async () => {
            let reelCards;
            if (launchData.language === "tr") {
                reelCards = fairData?.participation?.map((p) => ({
                    media: p?.companyId?.promotionImages?.[0]
                        ? p?.companyId?.promotionImages?.[0]
                        : p?.companyId?.logo?.url || anonymousImage,
                    title: p?.companyId?.name
                        ? p?.companyId?.name
                              .replace(/i/g, "İ")
                              .replace(/ı/g, "I")
                              .replace(/ğ/g, "Ğ")
                              .replace(/ü/g, "Ü")
                              .replace(/ş/g, "Ş")
                              .replace(/ö/g, "Ö")
                              .replace(/ç/g, "Ç")
                              .toUpperCase()
                        : "",
                    buttonText: "Görüntüle",
                    exploreUrl: p?.companyId?.slug?.tr,
                    logo: p?.companyId?.promotionImages?.[0] ? false : true,
                }));
            } else {
                reelCards = fairData?.participation?.map((p) => ({
                    media: p?.companyId?.promotionImagesEn?.[0]
                        ? p?.companyId?.promotionImagesEn?.[0]
                        : p?.companyId?.logo?.url || anonymousImage,
                    title: p?.companyId?.name
                        ? p?.companyId?.name
                              .replace(/i/g, "İ")
                              .replace(/ı/g, "I")
                              .replace(/ğ/g, "Ğ")
                              .replace(/ü/g, "Ü")
                              .replace(/ş/g, "Ş")
                              .replace(/ö/g, "Ö")
                              .replace(/ç/g, "Ç")
                              .toUpperCase()
                        : "",
                    buttonText: "View",
                    exploreUrl: p?.companyId?.slug?.en,
                    logo: p?.companyId?.promotionImagesEn?.[0] ? false : true,
                }));
            }
            if (reelCards) setReelsCards(reelCards);
        };

        createCard();
    }, [fairData, launchData]);

    // Otomatik kaydırma işlemi
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isTransitioning && !isPaused) {
                // Eğer duraklatılmadıysa kaydır
                setActiveIndex((prevIndex) => prevIndex + 1);
            }
        }, 3000); // 3 saniyede bir kaydırma

        return () => clearInterval(interval); // Component unmount olduğunda interval temizlenecek
    }, [isTransitioning, isPaused]);

    // Kartın türünü belirler: video mu, görsel mi
    const isVideo = (media: string) => {
        const videoExtensions = ["mp4", "webm", "ogg"];
        const extension = media.split(".").pop();
        return extension && videoExtensions.includes(extension.toLowerCase());
    };

    const closeModal = () => {
        setSelectedCard(null);
    };

    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    // Otomatik kaydırmayı sağlamak için referansla scroll ayarlanması
    useEffect(() => {
        if (sliderRef.current) {
            const cardWidth = 320; // Kart genişliği
            const totalScrollLeft = cardWidth * activeIndex; // Scroll yapılacak pozisyon

            setIsTransitioning(true); // Geçişi başlatıyoruz
            sliderRef.current.scrollTo({
                left: totalScrollLeft,
                behavior: "smooth",
            });

            // Geçiş tamamlandıktan sonra, döngünün sonsuz gibi görünmesini sağlamak için
            const timer = setTimeout(() => {
                if (activeIndex >= reelsCards.length) {
                    // Başlangıç konumuna anında geri götür
                    sliderRef.current?.scrollTo({
                        left: cardWidth * (activeIndex - reelsCards.length),
                        behavior: "auto",
                    });
                    setActiveIndex(
                        (prevIndex) => prevIndex - reelsCards.length
                    );
                }
                setIsTransitioning(false); // Geçiş tamamlandı
            }, 500); // Smooth scroll'un tamamlanması için kısa bir süre tanıyoruz

            return () => clearTimeout(timer);
        }
    }, [activeIndex, reelsCards.length]);

    useEffect(() => {
        const storedVisitorId = localStorage.getItem("visitorId");
        setVisitorId(storedVisitorId);
    }, [localStorage.getItem("visitorId")]);

    const handleProtectedLinkClick = (url: string) => {
        if (localStorage.getItem("visitorId")) {
            window.open(url, "_blank");
        } else {
            setRedirectUrl(url);
            setShowVisitForm(true);
        }
    };

    if (reelsCards.length === 0 || !reelsCards) return <></>;
    return (
        <div className="relative w-full flex justify-center items-center">
            <div
                ref={sliderRef} // sliderRef ile otomatik kaydırma sağlanacak
                className="max-w-[95%] overflow-x-scroll flex space-x-5 py-4 px-2 scrollbar-hide"
                style={{
                    scrollSnapType: "x mandatory",
                    WebkitOverflowScrolling: "touch",
                }}
            >
                {duplicatedReelsCards.map((card, index) => (
                    <div
                        key={index}
                        className={`relative flex-shrink-0 w-[320px] h-[600px] rounded-xl shadow-lg overflow-hidden transition-transform transform-gpu hover:scale-105 duration-500 ${
                            index === activeIndex ? "active" : ""
                        }`}
                        style={{ scrollSnapAlign: "start" }}
                        onMouseEnter={() => setIsPaused(true)} // Kartın üzerine geldiğinde kaydırmayı durdur
                        onMouseLeave={() => setIsPaused(false)} // Karttan ayrıldığında kaydırmayı devam ettir
                        onClick={() => setSelectedCard(card)}
                    >
                        {isVideo(card.media) ? (
                            <video
                                src={card.media}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                            />
                        ) : card.logo ? (
                            <div className="flex justify-center items-center h-full w-full">
                                <img
                                    src={card.media}
                                    alt={`Reels Card ${index + 1}`}
                                    className="w-2/3"
                                />
                            </div>
                        ) : (
                            <img
                                src={card.media}
                                alt={`Reels Card ${index + 1}`}
                                className="h-full w-full object-cover"
                            />
                        )}
                        <div className="absolute bottom-0 left-0 w-full p-6 bg-gradient-to-t from-black to-transparent text-white">
                            <h3
                                className="text-xl text-center font-semibold mb-1 line-clamp-2"
                                style={{ fontFamily: "Radhanji, sans-serif" }}
                            >
                                {card.title}
                            </h3>
                            <p
                                className="text-gray-300 text-center mb-5"
                                style={{ fontFamily: "Radhanji, sans-serif" }}
                            >
                                {card.subtitle}
                            </p>
                            {card.exploreUrl && (
                                <div className="text-center">
                                    <button
                                        onClick={() =>
                                            handleProtectedLinkClick(
                                                `${launchUrl}/${card.exploreUrl}`
                                            )
                                        }
                                        className="inline-block px-8 py-3 text-lg font-bold text-white mb-3 border-2 border-white rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg"
                                        style={{
                                            fontFamily: "Radhanji, sans-serif",
                                        }}
                                    >
                                        {card.buttonText || "Keşfet"}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {selectedCard && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 "
                    onClick={handleBackgroundClick}
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                    <div className="relative w-[85vw] h-[70vh] rounded-lg overflow-hidden ">
                        <button
                            className="absolute top-4 right-4 bg-gray-200 bg-opacity-30 p-2 rounded-full hover:bg-opacity-50 transition z-50"
                            onClick={closeModal}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        {isVideo(selectedCard.media) ? (
                            <video
                                src={selectedCard.media}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                            />
                        ) : selectedCard.logo ? (
                            <div className="flex justify-center items-center h-full w-full bg-white">
                                <img
                                    src={selectedCard.media}
                                    alt={`Selected Card`}
                                    className="h-3/4"
                                />
                            </div>
                        ) : (
                            <img
                                src={selectedCard.media}
                                alt={`Selected Card`}
                                className="h-full w-full object-cover"
                            />
                        )}

                        {selectedCard.exploreUrl && (
                            <div className="absolute bottom-0 w-full text-center bg-gradient-to-t  from-black to-transparent p-4">
                                <h3
                                    className="text-2xl text-center font-semibold mb-8 text-white"
                                    style={{
                                        fontFamily: "Radhanji, sans-serif",
                                    }}
                                >
                                    {selectedCard.title}
                                </h3>
                                <button
                                    onClick={() =>
                                        handleProtectedLinkClick(
                                            `${launchUrl}/${selectedCard.exploreUrl}`
                                        )
                                    }
                                    className="inline-block px-8 py-3 text-lg font-bold text-white mb-3 border-2 border-white rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg"
                                    style={{
                                        fontFamily: "Radhanji, sans-serif",
                                    }}
                                >
                                    {selectedCard.buttonText || "Keşfet"}
                                </button>
                            </div>
                        )}
                    </div>
                </motion.div>
            )}
            {showVisitForm && (
                <VisitForm
                    onClose={() => setShowVisitForm(false)}
                    onVerifySuccess={() => {
                        if (redirectUrl) {
                            navigate(redirectUrl);
                        }
                    }}
                />
            )}
        </div>
    );
};

export default CompanyReelsCardSliderSection;
