import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext"; // SocketContext'ten socket'i aldık

interface LargeCardSectionProps {
    componentData: {
        componentName: string;
        media: string;
        url: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const LargeCardSection: React.FC<LargeCardSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const { media, url } = componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";

    const { ref, inView } = useInView({
        threshold: 0.3,
    });

    const [viewDuration, setViewDuration] = useState(0); // İzleme süresi (saniye)
    const socket = useSocket(); // SocketContext'ten socket'i aldık

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            // Kullanıcı görüntülemeye başladığında
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1); // Her saniye artır
            }, 1000);
        } else {
            // Kullanıcı kartı terk ettiğinde
            if (interval) clearInterval(interval);

            // Görüntüleme süresini sunucuya gönder
            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration, // Süreyi gönder
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} kartını izledi.`
                );

                // Süreyi sıfırla
                setViewDuration(0);
            }
        }

        // Temizlik
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, _id, designId, socket]);

    const handleClick = () => {
        if (socket && socket.connected) {
            // Eğer socket bağlıysa etkinlik gönder
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId"),
                componentId: _id,
                launchId: designId,
                activityType: "click",
            });

            console.log(
                "Socket.IO 'click' log gönderildi:",
                _id,
                designId,
                componentData.componentName
            );
        } else {
            console.error("Socket bağlantısı yok, click log gönderilemedi.");
        }
    };

    const defaultMedia =
        "https://via.placeholder.com/1920x1080?text=No+Media+Available";

    const renderMedia = (mediaUrl: string) => {
        const mediaType = mediaUrl.split(".").pop()?.toLowerCase();

        if (!mediaType) {
            return <p>Desteklenmeyen medya türü</p>;
        }

        if (["mp4", "webm", "ogg"].includes(mediaType)) {
            return (
                <motion.video
                    className="w-full h-[50vh] md:w-[95vw] md:h-[85vh] object-cover rounded-lg mx-auto"
                    autoPlay
                    muted
                    loop
                    playsInline
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4, ease: "easeInOut" }}
                >
                    <source src={mediaUrl} type={`video/${mediaType}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </motion.video>
            );
        }

        if (["jpg", "jpeg", "png", "gif", "webp"].includes(mediaType)) {
            return (
                <motion.img
                    src={mediaUrl}
                    alt="Media"
                    className="w-full h-[50vh] md:w-[95vw] md:h-[85vh] object-cover rounded-lg mx-auto"
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4, ease: "easeInOut" }}
                />
            );
        }

        if (mediaType === "pdf") {
            return (
                <iframe
                    className="w-full h-[50vh] md:w-[95vw] md:h-[85vh] mx-auto"
                    src={mediaUrl}
                    title="PDF Preview"
                    style={{ border: "none" }}
                />
            );
        }

        if (["mp3", "wav", "ogg"].includes(mediaType)) {
            return (
                <audio controls className="w-full">
                    <source src={mediaUrl} type={`audio/${mediaType}`} />
                    Tarayıcınız bu sesi desteklemiyor.
                </audio>
            );
        }

        if (["doc", "docx", "ppt", "pptx"].includes(mediaType)) {
            return (
                <a
                    href={mediaUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                >
                    {`Dosyayı görüntüle: ${mediaUrl}`}
                </a>
            );
        }

        return <p>Desteklenmeyen medya türü</p>;
    };

    return (
        <motion.div
            ref={ref}
            className="p-5 bg-white rounded-3xl mb-8 flex justify-center w-full md:w-[85vw] h-[50vh] md:h-[70vh] mx-auto"
            initial={{ opacity: 0, y: 100 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
        >
            <div className="mb-4 w-full h-full">
                <motion.a
                    href={url || "#"}
                    target={url ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    onClick={handleClick}
                    whileHover={{ scale: 1.05, rotate: 1 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    {renderMedia(media || defaultMedia)}
                </motion.a>
            </div>
        </motion.div>
    );
};

export default LargeCardSection;