import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";

interface HeaderSectionProps {
    componentData: {
        title: string;
        logoMedia: string;
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const { title, logoMedia, componentName } = componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";
    const { ref, inView } = useInView({
        threshold: 0.3,
    });

    const socket = useSocket();
    const [viewDuration, setViewDuration] = useState<number>(0);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            // Kart görünümdeyse süreyi artır
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000);
        } else {
            // Kart görünüm dışına çıktığında süreyi logla
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} başlık kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, socket, _id, designId]);

    return (
        <div
            ref={ref}
            className="flex justify-between items-center w-full h-[120px] mx-auto bg-white relative p-4 md:p-6"
        >
            {/* Sol tarafta başlık */}
            <motion.h1
                className="text-gray-800 font-bold text-[40px] md:text-[70px] pl-8"
                style={{
                    fontFamily: "Radhanji, sans-serif",
                    paddingLeft: "20px",
                    textShadow: "3px 3px 6px rgba(0, 0, 0, 0.3)",
                }}
                initial={{ x: -100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: "easeOut" }}
            >
                {title}
            </motion.h1>

            {/* Sağ tarafta logo */}
            <motion.div
                className="flex items-center h-auto pr-8"
                initial={{ x: 100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: "easeOut" }}
            >
                <img
                    src={logoMedia}
                    alt="Logo"
                    className="object-contain w-[70px] h-[70px] md:w-[120px] md:h-[120px]"
                />
            </motion.div>
        </div>
    );
};

export default HeaderSection;
