import create from "zustand";

import axios from "../../libs/axios";

import type {
    IActivityAreaSearchResponse,
    ISearchCompanyReference,
    ISearchActivityArea,
    ISearchProductCode,
    ICountry,
    IProductCertificate,
    ISearchProductSubCategory,
    ISearchProductInnerCategory,
    ISearchServiceSubType,
    ISearchGtipCode,
} from "../../services/damiseTypes";

interface SearchState {
    loading: boolean;
    activeTab: string;
    searchTerm: string;
    initialSearch: string;
    searchSortOption: string;
    gtipStages: ISearchGtipCode[];
    fscStages: ISearchGtipCode[];
    companySuggestions: ICountry[];
    activityAreaSuggestions: IActivityAreaSearchResponse[];
    companyDocTypeSuggestions: IActivityAreaSearchResponse[];
    companyReferenceSuggestions: ISearchCompanyReference[];
    countrySuggestions: ICountry[];
    citySuggestions: ICountry[];
    productCategorySuggestions: IActivityAreaSearchResponse[];
    productSubCategorySuggestions: IActivityAreaSearchResponse[];
    productInnerCategorySuggestions: IActivityAreaSearchResponse[];
    productDocumentSuggestions: IProductCertificate[];
    serviceCategorySuggestions: IActivityAreaSearchResponse[];
    serviceSubCategorySuggestions: IActivityAreaSearchResponse[];
    serviceDocumentSuggestions: IProductCertificate[];
    serviceReferenceSuggestions: ISearchCompanyReference[];
    dunsSuggestions: ISearchProductCode[];
    naceSuggestions: ISearchProductCode[];
    nCageSuggestions: ISearchProductCode[];
    us97Suggestions: ISearchProductCode[];
    fscSuggestions: ISearchProductCode[];
    fscSuggestionsWithName: ISearchGtipCode[];
    ataSuggestions: ISearchProductCode[];
    gtipSuggestions: ISearchProductCode[];
    gtipSuggestionsWithName: ISearchGtipCode[];
    napcsSuggestions: ISearchProductCode[];
    nsnSuggestions: ISearchProductCode[];
    niinSuggestions: ISearchProductCode[];
    setActiveTab: (tab: string) => void;
    setSearchTerm: (searchTerm: string) => void;
    setSearchSortOption: (searchSortOption: string) => void;
    setInitialSearch: (initial: string) => void;
    searchCompanies: (q: string) => Promise<void>;
    searchActivityAreas: (q: ISearchActivityArea) => Promise<void>;
    searchCompanyDocTypes: (q: ISearchActivityArea) => Promise<void>;
    searchCompanyReferences: (q: string) => Promise<void>;
    searchCountries: (q: string) => Promise<void>;
    searchCities: (q: string) => Promise<void>;
    searchProductCategories: (q: ISearchActivityArea) => Promise<void>;
    searchProductSubCategories: (q: ISearchProductSubCategory) => Promise<void>;
    searchProductInnerCategories: (
        q: ISearchProductInnerCategory
    ) => Promise<void>;
    searchProductDocuments: (q: ISearchActivityArea) => Promise<void>;
    searchServiceCategories: (q: ISearchActivityArea) => Promise<void>;
    searchServiceSubCategories: (q: ISearchServiceSubType) => Promise<void>;
    searchServiceDocuments: (q: ISearchActivityArea) => Promise<void>;
    searchServiceReferences: (q: string) => Promise<void>;
    searchDunsCodes: (q: ISearchProductCode) => Promise<void>;
    searchNaceCodes: (q: ISearchProductCode) => Promise<void>;
    searchNCageCodes: (q: ISearchProductCode) => Promise<void>;
    searchUs97Codes: (q: ISearchProductCode) => Promise<void>;
    searchFscCodes: (q: ISearchProductCode) => Promise<void>;
    searchFscCodesByName: (q: ISearchActivityArea) => Promise<void>;
    searchAtaChapters: (q: ISearchProductCode) => Promise<void>;
    searchGtipCodes: (q: ISearchProductCode) => Promise<void>;
    searchGtipCodesByName: (q: ISearchActivityArea) => Promise<void>;
    getSubGtipNumbers: (
        parentGtip: ISearchGtipCode
    ) => Promise<{ gtipCount: number } | undefined>;
    getSubFscCodes: (
        parentFsc: ISearchGtipCode
    ) => Promise<{ fscCount: number } | undefined>;
    searchNapcsCodes: (q: ISearchProductCode) => Promise<void>;
    searchNsnCodes: (q: ISearchProductCode) => Promise<void>;
    searchNiinCodes: (q: ISearchProductCode) => Promise<void>;
    clearFscStages: () => void;
    clearGtipStages: () => void;
    clearSuggestions: () => void;
}

const useSearchStore = create<SearchState>()((set, get) => ({
    activeTab: "company",
    loading: false,
    searchTerm: "",
    initialSearch: "",
    searchSortOption: "",
    gtipStages: [],
    fscStages: [],
    companySuggestions: [],
    activityAreaSuggestions: [],
    companyDocTypeSuggestions: [],
    companyReferenceSuggestions: [],
    countrySuggestions: [],
    citySuggestions: [],
    productCategorySuggestions: [],
    productSubCategorySuggestions: [],
    productInnerCategorySuggestions: [],
    productDocumentSuggestions: [],
    serviceCategorySuggestions: [],
    serviceSubCategorySuggestions: [],
    serviceDocumentSuggestions: [],
    serviceReferenceSuggestions: [],
    dunsSuggestions: [],
    naceSuggestions: [],
    nCageSuggestions: [],
    us97Suggestions: [],
    fscSuggestions: [],
    fscSuggestionsWithName: [],
    ataSuggestions: [],
    gtipSuggestions: [],
    gtipSuggestionsWithName: [],
    napcsSuggestions: [],
    nsnSuggestions: [],
    niinSuggestions: [],
    setActiveTab: (activeTab: string) => {
        set({ activeTab });
    },
    setSearchTerm: (searchTerm: string) => {
        set({ searchTerm });
    },
    setSearchSortOption: (searchSortOption: string) => {
        set({ searchSortOption });
    },
    setInitialSearch: (initialSearch: string) => {
        set({ initialSearch });
    },
    searchCompanies: async (name: string) => {
        try {
            const { data } = await axios.post(`/search/company`, { name });
            set({ companySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchActivityAreas: async (name: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(`/search/activityarea`, name);
            set({ activityAreaSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchCompanyDocTypes: async (name: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(`/search/document-type`, name);
            set({ companyDocTypeSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchCompanyReferences: async (name: string) => {
        try {
            const { data } = await axios.post(`/search/company-reference`, {
                name,
            });
            set({ companyReferenceSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchCountries: async (name: string) => {
        try {
            const { data } = await axios.post(`/search/country`, { name });
            set({ countrySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchCities: async (name: string) => {
        try {
            const { data } = await axios.post(`/search/state`, { name });
            set({ citySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchProductCategories: async (name: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(`/search/product-category`, name);
            set({ productCategorySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchProductSubCategories: async (name: ISearchProductSubCategory) => {
        try {
            const { data } = await axios.post(
                `/search/product-subcategory`,
                name
            );
            set({ productSubCategorySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchProductInnerCategories: async (name: ISearchProductInnerCategory) => {
        try {
            const { data } = await axios.post(
                `/search/product-subsubcategory`,
                name
            );
            set({ productInnerCategorySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchProductDocuments: async (name: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(
                `/search/product-certificate-documentname`,
                name
            );
            set({ productDocumentSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchServiceCategories: async (name: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(`/search/service-type`, name);
            set({ serviceCategorySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchServiceSubCategories: async (name: ISearchServiceSubType) => {
        try {
            const { data } = await axios.post(`/search/service-subtype`, name);
            set({ serviceSubCategorySuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchServiceDocuments: async (name: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(
                `/search/service-certificate-documentname`,
                name
            );
            set({ serviceDocumentSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchServiceReferences: async (name: string) => {
        try {
            const { data } = await axios.post(`/search/service-reference`, {
                name,
            });
            set({ serviceReferenceSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchDunsCodes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/search/company-dunsnumber`, q);
            set({ dunsSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchNaceCodes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/search/company-nacecode`, q);
            set({ naceSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchNCageCodes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(
                `/search/company-natocagecode`,
                q
            );
            set({ nCageSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchUs97Codes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/search/company-us97`, q);
            set({ us97Suggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    /*  searchFscCodes: async (q: ISearchProductCode) => {
    try {
      const { data } = await axios.post(`/search/product-fsc`, q)
      set({ fscSuggestions: data })
    } catch (e) {
      console.error(e)
    }
  }, */
    searchAtaChapters: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/search/product-atachapter`, q);
            set({ ataSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchFscCodes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/utils/product-fsc`, q);
            set({ fscSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchFscCodesByName: async (q: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(`/utils/product-fsc-name`, q);
            set({ fscSuggestionsWithName: data });
        } catch (e) {
            console.error(e);
        }
    },
    getSubFscCodes: async (parentFsc: ISearchGtipCode) => {
        try {
            const { data } = await axios(
                `/utils/fsc-one-sub/${parentFsc?.code}`
            );
            const fscStages = get().fscStages;
            fscStages.push(parentFsc);
            set({
                fscSuggestionsWithName: data,
                fscStages,
            });
            return { fscCount: data?.length as number };
        } catch (e) {
            console.error(e);
        }
    },
    searchGtipCodes: async (q: ISearchProductCode) => {
        set({ loading: true });
        try {
            const { data } = await axios.post(`/search/product-gtip`, q);
            set({ gtipSuggestions: data });
        } catch (e) {
            console.error(e);
        } finally {
            set({ loading: false });
        }
    },
    searchGtipCodesByName: async (q: ISearchActivityArea) => {
        try {
            const { data } = await axios.post(`/search/product-gtip-name`, q);
            set({ gtipSuggestionsWithName: data });
        } catch (e) {
            console.error(e);
        }
    },
    getSubGtipNumbers: async (parentGtip: ISearchGtipCode) => {
        try {
            const { data } = await axios(
                `/utils/gtip-one-sub/${parentGtip?.code}`
            );
            const gtipStages = get().gtipStages;
            gtipStages.push(parentGtip);
            set({
                gtipSuggestionsWithName: data,
                gtipStages,
            });
            return { gtipCount: data?.length as number };
        } catch (e) {
            console.error(e);
        }
    },
    searchNapcsCodes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/search/product-napcs`, q);
            set({ napcsSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchNsnCodes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/search/product-nsn`, q);
            set({ nsnSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    searchNiinCodes: async (q: ISearchProductCode) => {
        try {
            const { data } = await axios.post(`/search/product-nin`, q);
            set({ niinSuggestions: data });
        } catch (e) {
            console.error(e);
        }
    },
    clearGtipStages: () => set({ gtipStages: [] }),
    clearFscStages: () => set({ fscStages: [] }),
    clearSuggestions: () => {
        set({
            activityAreaSuggestions: [],
            companyDocTypeSuggestions: [],
            companyReferenceSuggestions: [],
            countrySuggestions: [],
            citySuggestions: [],
            dunsSuggestions: [],
            naceSuggestions: [],
            nCageSuggestions: [],
            us97Suggestions: [],
            fscSuggestions: [],
            fscSuggestionsWithName: [],
            ataSuggestions: [],
            gtipSuggestions: [],
            gtipSuggestionsWithName: [],
            napcsSuggestions: [],
            nsnSuggestions: [],
            niinSuggestions: [],
            productCategorySuggestions: [],
            productSubCategorySuggestions: [],
            productInnerCategorySuggestions: [],
            companySuggestions: [],
            productDocumentSuggestions: [],
            serviceCategorySuggestions: [],
            serviceSubCategorySuggestions: [],
            serviceDocumentSuggestions: [],
            serviceReferenceSuggestions: [],
            gtipStages: [],
            fscStages: [],
        });
    },
}));

export default useSearchStore;
