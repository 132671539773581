import { useQuery } from "@tanstack/react-query";
import axios from "../../libs/axios";
import type {
    ProductCode,
    ServiceType,
    CompanyType,
    ICountry,
    Language,
    ServiceReference,
    ISme,
    IProductCertificate,
    IActivityAreaSearchResponse,
    ISearchContentResponse,
    ISearchActivityArea,
    IParentActivityArea,
    IState,
} from "../damiseTypes";

const getCompanyTypes = async (): Promise<CompanyType[]> => {
    const { data } = await axios(`/utils/company-type`);
    return data;
};

export function useCompanyTypes() {
    return useQuery(["companyTypes"], getCompanyTypes);
}

const getCompanyStructuralStates = async (): Promise<CompanyType[]> => {
    const { data } = await axios(`/utils/company-structural-state`);
    return data;
};

export function useCompanyStructuralStates() {
    return useQuery(["companyStructuralStates"], getCompanyStructuralStates);
}

const getSectors = async (): Promise<CompanyType[]> => {
    const { data } = await axios(`/sector/sector`);
    return data?.data;
};

export function useSectors() {
    return useQuery(["sectors"], getSectors);
}

const getSector = async (
    sectorId: string | undefined
): Promise<CompanyType> => {
    return typeof sectorId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/sector/sector-id/${sectorId}`).then(
              (response) => response.data
          );
    // const { data } = await axios(`/sector/sector`)
    // return data
};

export function useSector(sectorId: string | undefined) {
    return useQuery(["sectors", sectorId], () => getSector(sectorId), {
        enabled: Boolean(sectorId),
    });
}
const getLanguages = async (): Promise<Language[]> => {
    const { data } = await axios(`/utils/language`);
    return data;
};

export function useLanguages() {
    return useQuery(["languages"], getLanguages);
}
const getCountries = async (): Promise<ICountry[]> => {
    const { data } = await axios(`/countries`);
    return data;
};

export function useCountries() {
    return useQuery(["countries"], getCountries);
}
const getStatesByCounty = async (countryId?: string): Promise<IState[]> => {
    return typeof countryId === "undefined"
        ? Promise.reject(new Error("Invalid country id"))
        : axios(`/states/${countryId}`).then((res) => res.data);
};

export function useStatesByCountry(countryId?: string) {
    return useQuery(["states", countryId], () => getStatesByCounty(countryId), {
        enabled: Boolean(countryId),
    });
}
const getDistrictsByStates = async (cityId?: string): Promise<IState[]> => {
    return typeof cityId === "undefined"
        ? Promise.reject(new Error("Invalid city id"))
        : axios(`/cities/${cityId}`).then((res) => res.data);
};

export function useDistrictsByStates(cityId?: string) {
    return useQuery(["districts", cityId], () => getDistrictsByStates(cityId), {
        enabled: Boolean(cityId),
    });
}

const getDunsNumbers = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/company-dunsnumber`);
    return response.data;
};

export function useDunsNumbers() {
    return useQuery(["duns-numbers"], getDunsNumbers);
}

const getNaceCodes = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/company-nacecode`);
    return response.data;
};

export function useNaceCodes() {
    return useQuery(["nace-codes"], getNaceCodes);
}
const getNatoCageCodes = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/company-natocagecode`);
    return response.data;
};

export function useNatoCageCodes() {
    return useQuery(["nato-cage-codes"], getNatoCageCodes);
}
const getUsCodes = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/company-us97`);
    return response.data;
};

export function useUsCodes() {
    return useQuery(["us97-codes"], getUsCodes);
}
const getAtaChapters = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/product-atachapter`);
    return response.data;
};

export function useAtaChapters() {
    return useQuery(["ata-chapters"], getAtaChapters);
}

const getFscCodes = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/product-fsc`);
    return response.data;
};
export function useFscCodes() {
    return useQuery(["fsc-codes"], getFscCodes);
}

const getGtipCodes = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/product-gtip`);
    return response.data;
};
export function useGtipCodes() {
    return useQuery(["gtip-codes"], getGtipCodes);
}

const getNapcsCodes = async (): Promise<ProductCode[]> => {
    const response = await axios(`/utils/product-napcs`);
    return response.data;
};
export function useNapcsCodes() {
    return useQuery(["napcs-codes"], getNapcsCodes);
}

const getServiceTypes = async (): Promise<ServiceType[]> => {
    const { data } = await axios(`/services/service-type`);
    return data;
};

export function useServiceTypes() {
    return useQuery(["service-types"], getServiceTypes);
}

const getServiceReferences = async (): Promise<ServiceReference[]> => {
    const { data } = await axios(`/services/service-reference`);
    return data;
};

export function useAllServiceReferences() {
    return useQuery(["service-references"], getServiceReferences);
}

const getSmeOptions = async (): Promise<ISme[]> => {
    const { data } = await axios(`/utils/kobi`);
    return data;
};

export function useSmeOptions() {
    return useQuery(["sme"], getSmeOptions);
}

const getAllProductCertificates = async (): Promise<IProductCertificate[]> => {
    const { data } = await axios(`/utils/product-certificate-list`);
    return data;
};

export function useAllProductCertificates() {
    return useQuery(["product-certificates"], getAllProductCertificates);
}
const getAllServiceCertificates = async (): Promise<IProductCertificate[]> => {
    const { data } = await axios(`/utils/service-certificate-list`);
    return data;
};

export function useAllServiceCertificates() {
    return useQuery(["service-certificates"], getAllServiceCertificates);
}
const getActivityArea = async (
    areaId: string | undefined
): Promise<IActivityAreaSearchResponse> => {
    return typeof areaId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/activityarea/getactivityarea/${areaId}`).then(
              (response) => response.data
          );
};

export function useActivityArea(areaId: string | undefined) {
    return useQuery(["activity-area", areaId], () => getActivityArea(areaId), {
        enabled: Boolean(areaId),
        _optimisticResults: "isRestoring",
    });
}
const getMainActivityAreas = async (): Promise<
    IActivityAreaSearchResponse[]
> => {
    const { data } = await axios(`/activityarea/activityarea-no-parent`);
    return data?.data;
};

export function useMainActivityAreas() {
    return useQuery(["main-activity-areas"], getMainActivityAreas);
}

const getParentActivityAreas = async (
    areaId: string | undefined
): Promise<IParentActivityArea> => {
    return typeof areaId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/activityarea/connectactivityarea/${areaId}`).then(
              (response) => response.data?.[0]
          );
};

export function useParentActivityAreas(areaId: string | undefined) {
    return useQuery(
        ["parent-activity-areas", areaId],
        () => getParentActivityAreas(areaId),
        {
            enabled: Boolean(areaId),
        }
    );
}

const getContentTypes = async (): Promise<IActivityAreaSearchResponse[]> => {
    return await axios(`/announcement/announcementcategory-all`)?.then(
        (res) => res?.data
    );
};

export function useContentTypes() {
    return useQuery(["content-types"], getContentTypes);
}

// __ Search
export const searchContents = async (
    reqBody: ISearchActivityArea,
    searchSortOption: string
): Promise<ISearchContentResponse> => {
    const searchOption = searchSortOption.substring(1);
    return typeof reqBody === "undefined"
        ? Promise.reject(new Error("Invalid request body"))
        : await axios
              .post(`/search/all-content?${searchOption}`, reqBody)
              .then((response) => response.data);
};
