import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSocket } from "../../context/SocketContext";
import { useParams } from "react-router-dom";

interface TwinCardSectionProps {
    componentData: {
        leftMedia: string;
        leftPoster?: string; // Sol medya için kapak fotoğrafı
        rightMedia: string;
        rightPoster?: string; // Sağ medya için kapak fotoğrafı
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const TwinCardSection: React.FC<TwinCardSectionProps> = ({ componentData, _id, designId }) => {
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";

    const { ref: leftRef, inView: leftInView } = useInView({ threshold: 0.3 });
    const { ref: rightRef, inView: rightInView } = useInView({ threshold: 0.3 });

    const socket = useSocket();

    const [leftViewDuration, setLeftViewDuration] = useState(0);
    const [rightViewDuration, setRightViewDuration] = useState(0);

    useEffect(() => {
        let leftInterval: NodeJS.Timeout | null = null;
       

        // Sol kart görünüm süresi
        if (leftInView) {
            leftInterval = setInterval(() => {
                setLeftViewDuration((prev) => prev + 1);
            }, 1000);
        } else if (leftViewDuration > 0 && socket?.connected) {
            // Sol kart loglama
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "view-duration",
                duration: leftViewDuration,
                
            });
            console.log(`Left card view-duration logged: ${leftViewDuration}s`);
            setLeftViewDuration(0);
        }

    
        return () => {
            if (leftInterval) clearInterval(leftInterval);
           
        };
    }, [leftInView, rightInView, leftViewDuration, rightViewDuration, _id, designId, socket]);

    const renderMedia = (mediaUrl: string, mediaPoster?: string) => {
        const mediaType = mediaUrl.split(".").pop()?.toLowerCase();

        if (!mediaType) return <p>Unsupported media type</p>;

        if (["mp4", "webm", "ogg"].includes(mediaType)) {
            return (
                <video
                    className="w-full h-full object-cover"
                    controls
                    poster={mediaPoster}
                >
                    <source src={mediaUrl} type={`video/${mediaType}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        if (["jpg", "jpeg", "png", "gif", "webp"].includes(mediaType)) {
            return <img src={mediaUrl} alt="Media" className="w-full h-full object-cover" />;
        }

        return <p>Unsupported media type</p>;
    };

    return (
        <div
            className="flex flex-col md:flex-row justify-center items-center gap-8 py-8 bg-white-100 mx-auto"
            style={{ width: "85vw", height: "70vh" }}
        >
            {/* Sol Kart */}
            <div
                ref={leftRef}
                className="w-full md:w-[65%] h-full bg-gray-200 rounded-3xl shadow-lg flex justify-center items-center transform transition-transform duration-300 hover:scale-105 overflow-hidden"
            >
                {renderMedia(componentData.leftMedia, componentData.leftPoster)}
            </div>

            {/* Sağ Kart */}
            <div
                ref={rightRef}
                className="w-full md:w-[65%] h-full bg-gray-200 rounded-3xl shadow-lg flex justify-center items-center transform transition-transform duration-300 hover:scale-105 overflow-hidden"
            >
                {renderMedia(componentData.rightMedia, componentData.rightPoster)}
            </div>
        </div>
    );
};

export default TwinCardSection;
