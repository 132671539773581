import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, A11y, Autoplay } from "swiper/modules";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import useFairStore from "../../store/fairStore";
import { useParams } from "react-router-dom";
import ItemModalCard from "../../components/componentmodals/ItemModalCard";
import {
    useCompany,
    usePublicCompanyServices,
} from "../../services/damiseServices";

interface CompanyProductCardData {
    buttonText: string;
    //buttonUrl: string;
    text: string;
    logoMedia: string;
    backGroundMedia?: string;
    about: string;
    url?: string;
    id: string;
}

const CompanyServiceCardSection = () => {
    const { companyUrl, launchUrl } = useParams<{
        companyUrl: string;
        launchUrl: string;
    }>();
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<CompanyProductCardData>();
    const [componentData, setComponentData] = useState<
        CompanyProductCardData[]
    >([]);

    // Intersection Observer ile bileşen görünür olduğunda animasyonu tetiklemek için ref ekliyoruz
    const { ref, inView } = useInView({
        triggerOnce: true, // Sadece bir kez tetiklensin
        threshold: 0.3, // Kartın %30'u görünür olduğunda tetikleme yapılır
    });
    const { launchData, fairData } = useFairStore();
    const {
        data: selectedCompany,
        isLoading: loadingCompany,
        isError: errorCompany,
    } = useCompany(launchData.language, companyUrl);
    const {
        data: services,
        isLoading: loadingServices,
        isError: errorServices,
    } = usePublicCompanyServices(selectedCompany?._id);

    useEffect(() => {
        const createCard = async () => {
            let cards;
            if (launchData.language === "tr") {
                cards = services?.map((p) => ({
                    buttonText: "İncele",
                    text: p?.name?.tr,
                    logoMedia: p?.mainImage || "",
                    backGroundMedia: p?.mainImage || "",
                    about: p?.description?.tr || "",
                    url: p?.slug?.tr,
                    id: p?._id,
                }));
            } else {
                cards = services?.map((p) => ({
                    buttonText: "View",
                    text: p?.name?.en,
                    logoMedia: p?.mainImage || "",
                    backGroundMedia: p?.mainImage || "",
                    about: p?.description?.en || "",
                    url: p?.slug?.en,
                    id: p?._id,
                }));
            }
            setComponentData(cards ?? []);
        };

        createCard();
    }, [selectedCompany, services, launchData]);

    useEffect(() => {
        // Check if the screen is larger than 720px and componentData length is <= 4
        const handleResize = () => {
            if (window.innerWidth > 768 && componentData?.length < 4) {
                setIsLargeScreen(true);
            } else {
                setIsLargeScreen(false);
            }
        };

        handleResize(); // Run on mount
        window.addEventListener("resize", handleResize); // Listen for screen resize

        return () => {
            window.removeEventListener("resize", handleResize); // Cleanup listener
        };
    }, [componentData, window.innerWidth]);

    const handleClick = (item: CompanyProductCardData) => {
        setSelectedItem(item);
        setShowPopup(true);
    };



    const renderMedia = (mediaUrl: string) => {
        const fileExtension = mediaUrl.split(".").pop()?.toLowerCase();
        const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
        const videoTypes = ["mp4", "webm", "ogg"];
        const audioTypes = ["mp3", "wav", "ogg"];

        if (imageTypes.includes(fileExtension || "")) {
            return (
                <img
                    src={mediaUrl}
                    alt="Görsel medya"
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                />
            );
        } else if (videoTypes.includes(fileExtension || "")) {
            return (
                <video
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                    autoPlay
                    loop
                    muted
                >
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else if (audioTypes.includes(fileExtension || "")) {
            return (
                <audio
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                    controls
                >
                    <source src={mediaUrl} type={`audio/${fileExtension}`} />
                    Tarayıcınız bu sesi desteklemiyor.
                </audio>
            );
        } else {
            return (
                <p className="text-white">
                    Desteklenmeyen medya formatı: {fileExtension}
                </p>
            );
        }
    };

    if (componentData.length === 0 || !componentData) return <></>;

    return (
        <>
            <div className="w-full flex justify-start items-center pt-6 pb-0 ml-20">
                {" "}
                {/* Sol tarafta daha fazla boşluk bıraktık */}
                {/* Başlık ve Çizgi */}
                <motion.div
                    ref={ref} // Intersection observer için ref ekliyoruz
                    className="flex items-center w-full"
                    initial={{ scale: 0.8, opacity: 0 }} // Başlangıç durumu (küçük boyut ve saydam)
                    animate={inView ? { scale: 1, opacity: 1 } : {}} // Sayfa kaydırıldığında büyütme ve görünürlük sağlanır
                    transition={{ duration: 0.6, ease: "easeInOut" }} // Geçiş süresi ve yumuşaklık
                >
                    {/* Başlık */}
                    <motion.h2
                        className="text-[38px] font-bold text-gray-700 w-auto"
                        style={{ fontFamily: "Radhanji, sans-serif" }} // Font ailesini Radhanji olarak ayarladık
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : {}} // Sayfa kaydırıldığında başlık görünür hale gelir
                        transition={{ duration: 0.6, delay: 0.3 }} // Biraz gecikme ekleyerek başlık animasyonu sağlanır
                    >
                        {launchData?.language === "tr"
                            ? "Hizmetler"
                            : "Services"}
                    </motion.h2>

                    {/* Yanındaki Gradient Çizgi */}
                    <div className="flex-grow ml-4 h-[1px] bg-gradient-to-r from-gray-500 to-transparent"></div>
                </motion.div>
            </div>
            <div
                className="w-full"
                style={{
                    marginTop: `${10}px`,
                    marginBottom: `${10}px`,
                }}
            >
                <motion.div
                    className="w-full h-[50px] bg-transparent"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                />
            </div>
            <div className="relative w-[100vw] flex items-center justify-center bg-white mt-0 mb-0">
                {componentData?.length < 4 && isLargeScreen ? (
                    <div className="flex justify-start space-x-6 w-full ml-16">
                        {componentData.map((card, index) => (
                            <div
                                key={index}
                                className="w-[25vw] lg:w-[20vw] h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl  transform scale-100 hover:scale-105 transition-transform duration-300"
                            >
                                {card?.backGroundMedia ? (
                                    renderMedia(card.backGroundMedia)
                                ) : card?.logoMedia ? (
                                    renderMedia(card.logoMedia)
                                ) : (
                                    <p className="text-white">
                                        Medya bulunamadı
                                    </p>
                                )}

                                <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent transition-opacity duration-300 group-hover:bg-black/50">
                                    <p
                                        className="text-base font-semibold mb-1 text-left w-full"
                                        style={{
                                            fontFamily: "Radhanji, sans-serif",
                                            marginBottom: "20px",
                                            maxWidth: "calc(100% - 120px)",
                                            whiteSpace: "normal",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {card?.text}
                                    </p>
                                    <a
                                        onClick={() => handleClick(card)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 cursor-pointer"
                                        onMouseEnter={() => {
                                            const swiperInstance = (
                                                document.querySelector(
                                                    ".swiper"
                                                ) as unknown as {
                                                    swiper: {
                                                        autoplay: {
                                                            stop: () => void;
                                                        };
                                                    };
                                                }
                                            )?.swiper;
                                            swiperInstance?.autoplay.stop();
                                        }}
                                        onMouseLeave={() => {
                                            const swiperInstance = (
                                                document.querySelector(
                                                    ".swiper"
                                                ) as unknown as {
                                                    swiper: {
                                                        autoplay: {
                                                            start: () => void;
                                                        };
                                                    };
                                                }
                                            )?.swiper;
                                            swiperInstance?.autoplay.start();
                                        }}
                                    >
                                        <span
                                            className="px-8 py-3 text-white bg-transparent border-2 border-white rounded-full transition-colors duration-300 group-hover:bg-white/30 group-hover:border-white/30 hover:bg-white/60 hover:border-white/60 hover:text-black"
                                            style={{
                                                fontFamily:
                                                    "Radhanji, sans-serif",
                                            }}
                                        >
                                            {card?.buttonText}
                                        </span>
                                    </a>
                                </div>

                                {card.logoMedia && (
                                    <div className="absolute bottom-4 right-4 w-16 h-16 z-20">
                                        <img
                                            src={card?.logoMedia}
                                            alt={`Card Logo ${index + 1}`}
                                            className="w-full h-full object-contain"
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <Swiper
                        modules={[Pagination, A11y, Autoplay]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView:
                                    componentData.length === 1
                                        ? 1
                                        : componentData.length === 2
                                        ? 1.5
                                        : 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView:
                                    componentData.length === 4 ? 4 : 3,
                                spaceBetween: 30,
                            },
                            1280: {
                                slidesPerView:
                                    componentData.length === 4 ? 4 : 3.3,
                                spaceBetween: 40,
                            },
                        }}
                        centeredSlides={componentData.length <= 4}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 500,
                            disableOnInteraction: false,
                        }}
                        speed={1500}
                        loop={componentData.length > 4}
                    >
                        {componentData.map((card, index) => (
                            <SwiperSlide>
                                <div className="w-[85%] md:w-[85%] lg:w-[85%] h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl mx-auto">
                                    {card.backGroundMedia ? (
                                        renderMedia(card.backGroundMedia)
                                    ) : card.logoMedia ? (
                                        renderMedia(card.logoMedia)
                                    ) : (
                                        <p className="text-white">
                                            Medya bulunamadı
                                        </p>
                                    )}

                                    <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent transition-opacity duration-300 group-hover:bg-black/50">
                                        <p
                                            className="text-base font-semibold mb-1 text-left w-full"
                                            style={{
                                                fontFamily:
                                                    "Radhanji, sans-serif",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            {card.text}
                                        </p>
                                        <a
                                            onClick={() => handleClick(card)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
                                            onMouseEnter={() => {
                                                const swiperInstance = (
                                                    document.querySelector(
                                                        ".swiper"
                                                    ) as unknown as {
                                                        swiper: {
                                                            autoplay: {
                                                                stop: () => void;
                                                            };
                                                        };
                                                    }
                                                )?.swiper;
                                                swiperInstance?.autoplay.stop();
                                            }}
                                            onMouseLeave={() => {
                                                const swiperInstance = (
                                                    document.querySelector(
                                                        ".swiper"
                                                    ) as unknown as {
                                                        swiper: {
                                                            autoplay: {
                                                                start: () => void;
                                                            };
                                                        };
                                                    }
                                                )?.swiper;
                                                swiperInstance?.autoplay.start();
                                            }}
                                        >
                                            <span
                                                className="px-8 py-3 text-white bg-transparent border-2 border-white rounded-full group-hover:bg-white/30 group-hover:border-white/30 hover:text-white transition-colors duration-300 font-bold"
                                                style={{
                                                    fontFamily:
                                                        "Radhanji, sans-serif",
                                                }}
                                            >
                                                {card.buttonText}
                                            </span>
                                        </a>
                                    </div>

                                    {card.logoMedia && (
                                        <div className="absolute bottom-4 right-4 w-16 h-16 z-20">
                                            <img
                                                src={card.logoMedia}
                                                alt={`Card Logo ${index + 1}`}
                                                className="w-full h-full object-contain"
                                            />
                                        </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
                <div className="custom-pagination swiper-pagination absolute bottom-4 flex justify-center space-x-2"></div>
            </div>
            {selectedItem && (
                <ItemModalCard
                    show={showPopup}
                    setShow={setShowPopup}
                    card={selectedItem}
                    lang={launchData?.language}
                    launchUrl={launchUrl || ""}
                    type="service"
                    products={fairData?.products || []}
                    services={fairData?.services || []}
                   
                />
            )}
        </>
    );
};

export default CompanyServiceCardSection;
