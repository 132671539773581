import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import useFairStore from "../../store/fairStore";
import { useParams } from "react-router-dom";
import { useCompany } from "../../services/damiseServices";
import { useSocket } from "../../context/SocketContext"; // Socket kullanımı

// Modal'ın kök elementini belirlemek gerekir
Modal.setAppElement("#root");

interface CompanyLargePopupCardData {
    media: string; // Media URL (image or video)
}

const CompanyLargePopupCardSection = () => {
    const { companyUrl, launchUrl } = useParams<{ companyUrl: string; launchUrl: string }>();
    const [isZoomed, setIsZoomed] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.2 });
    const [viewDuration, setViewDuration] = useState(0); // Görünüm süresi
    const [componentData, setComponentData] =
        useState<CompanyLargePopupCardData>({
            media: "",
        });
    const { launchData } = useFairStore();
    const {
        data: selectedCompany,
        isLoading: loadingCompany,
        isError: errorCompany,
    } = useCompany(launchData?.language, companyUrl);

    const socket = useSocket(); // Socket kullanımı

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000); // Her saniye artır
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: `${launchUrl}-${companyUrl}`,
                    launchId: launchUrl || "default_launch",
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${launchUrl}-${companyUrl} medya kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, companyUrl, launchUrl, socket]);

    useEffect(() => {
        const createCard = async () => {
            setComponentData((prevData) => ({
                ...prevData,
                media: selectedCompany?.trailerVideo || "",
            }));
        };

        createCard();
    }, [launchData, selectedCompany]);

    const handleZoom = () => {
        setIsZoomed(true);

        if (socket && socket.connected) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: `${launchUrl}-${companyUrl}`,
                launchId: launchUrl || "default_launch",
                activityType: "click",
                textlinks: [componentData.media],
            });

            console.log(`Medya tıklama kaydedildi: ${componentData.media}`);
        }
    };

    const handleClose = () => {
        setIsZoomed(false);
        setIsVideoPlaying(false); // Video durdurulacak
    };

    const handlePlayVideo = () => {
        setIsZoomed(true);
        setIsVideoPlaying(true); // Video oynatılacak

        if (socket && socket.connected) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: `${launchUrl}-${companyUrl}`,
                launchId: launchUrl || "default_launch",
                activityType: "click",
                textlinks: [componentData.media],
            });

            console.log(`Video oynatma kaydedildi: ${componentData.media}`);
        }
    };

    if (!componentData.media) return <></>;

    return (
        <div className="flex justify-center items-center w-full h-screen">
            <motion.div
                ref={ref}
                className="relative flex justify-center items-center rounded-3xl mx-auto"
                style={{ width: "85vw", height: "70vh" }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.8 }}
                transition={{ duration: 1, ease: "easeOut" }}
            >
                <div className="relative w-full h-full">
                    <iframe
                        className="rounded-3xl object-cover w-full h-full"
                        style={{ width: "85vw", height: "70vh" }}
                        src={componentData.media}
                        title="YouTube video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>

                {/* Icon Button for both image and video */}
                <button
                    className="absolute bottom-4 right-4 bg-gray-200 bg-opacity-30 hover:bg-opacity-70 transition-all rounded-full shadow-lg w-16 h-16 flex items-center justify-center"
                    onClick={handlePlayVideo}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-10 h-10 text-white"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.5 3.75a.75.75 0 011.11-.66l14.25 7.5a.75.75 0 010 1.32l-14.25 7.5a.75.75 0 01-1.11-.66v-15z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </motion.div>

            {isZoomed && (
                <Modal
                    isOpen={isZoomed}
                    onRequestClose={handleClose}
                    contentLabel="Media Modal"
                    className="fixed inset-0 flex items-center justify-center z-50"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-70 z-[1000]"
                    shouldCloseOnOverlayClick={true}
                >
                    <motion.div
                        className="relative flex items-center justify-center w-full h-full"
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.7, ease: "easeInOut" }}
                    >
                        <iframe
                            className="rounded-3xl object-cover w-full h-full"
                            style={{ width: "85vw", height: "70vh" }}
                            src={componentData?.media}
                            title="YouTube video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />

                        <button
                            className="absolute top-4 right-4 bg-gray-200 bg-opacity-30 hover:bg-opacity-70 rounded-full shadow-lg hover:shadow-2xl transition-all w-14 h-14 flex items-center justify-center"
                            onClick={handleClose}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-10 h-10 text-white"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </motion.div>
                </Modal>
            )}
        </div>
    );
};

export default CompanyLargePopupCardSection;
