import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { FaUserPlus } from "react-icons/fa";
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import CountryOptions from '../CountryOptions';
import Popup from '../PopupComponent';
import PopupConsent from '../PopupConsent';
import PopupMembers from '../PopupMembership';
import PopupMembersEN from '../PopupMembersEN';
// Dil tipini tanımlayın
type Language = 'en' | 'tr';

const texts: Record<Language, { [key: string]: string }> = {
    en: {
        title: "Digital Showcase Visitor Form",
        emailPlaceholder: "Email",
        nextButton: "Send Code",
        verificationTitle: "Verification",
        companyName: "Company *",
        firstName: "First Name *",
        lastName: "Last Name *",
        phone: "Phone Number *",
        duty: "Duty *",
        password: "Password *",
        newsletterLabel: "I agree to the processing of my personal data in accordance with the ",
        consentLabel: " I accept the terms and conditions. ",
        membersConsentLabel: " I agree to receive Commercial Electronic Messages and e-bulletins. ",
        continueButton: "Continue",
        verifyCodePlaceholder: "Enter Verification Code",
        submitButton: "Submit",
        errorMessage: "Please fill in all required fields!",
    },
    tr: {
        title: "Dijital Vitrin Ziyaret Formu",
        emailPlaceholder: "E-Posta",
        nextButton: "Kodu Gönder",
        verificationTitle: "Doğrulama",
        companyName: "Firma *",
        firstName: "Ad *",
        lastName: "Soyad *",
        phone: "Telefon Numarası *",
        duty: "Görev *",
        password: "Şifre *",
        newsletterLabel: "Kişisel verilerimin, ",
        newsletterLabel2: " kapsamında işlenmesini kabul ediyorum.",
        consentLabel: " kapsamında tarafıma Ticari Elektronik İleti ve e-bülten gönderilmesini kabul ediyorum.",
        membersConsentLabel: " şartlarını kabul ediyorum.",
        continueButton: "İlerle",
        verifyCodePlaceholder: "Doğrulama Kodu Girin",
        submitButton: "Gönder",
        errorMessage: "Lütfen tüm gerekli alanları doldurun!",
    },
};


interface PopupFormProps {
    onClose?: () => void;
    onVerifySuccess?: () => void;
}

interface SaveVisitorResponse {
    visitorId: string;
    id: string;
    _id: string;
    userId: string;
}

const VisitForm: React.FC<PopupFormProps> = ({ onVerifySuccess }) => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const formRef = useRef<HTMLDivElement>(null);
    const [isFormVisible, setIsFormVisible] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [email, setEmail] = useState<string>('');
    const [verificationCode, setVerificationCode] = useState<string[]>(["", "", "", ""]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
    const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyname: '',
        name: '',
        surname: '',
        phone: '',
        country: '',
        duty: '',
        password: '',
        newsletter: true,
        consent: false,
        membershipConsent: false,
    });
    const [language, setLanguage] = useState<Language>(document.documentElement.lang as Language || 'tr'); // Default dil
    const [textsToDisplay, setTextsToDisplay] = useState(texts[language]); // Metinler için durum

    useEffect(() => {
        // MutationObserver ile dil değişimini kontrol et
        const observer = new MutationObserver(() => {
            const lang = document.documentElement.lang as Language || 'tr'; // HTML'den dil değerini al
            if (lang !== language) {
                setLanguage(lang); // Dili güncelle
            }
        });

        observer.observe(document.documentElement, { attributes: true }); // attributes değişimini gözlemle

        return () => observer.disconnect(); // Temizlik işlemi
    }, [language]);

    useEffect(() => {
        setTextsToDisplay(texts[language]); // Dil değiştiğinde metinleri güncelle
    }, [language]);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const onRecaptchaChange = (value: string | null) => {
        setRecaptchaValue(value);
    };

    const sendVerificationEmail = async (email: string, type: "showcase" | "lansman") => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_DAMISE_API_URL}/utils/custom-send-mail-code`, {
                type,
                email,
                recaptcha: recaptchaValue,
                lang: language,
            });
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                throw new Error('Bu e-posta adresi zaten mevcut.');
            }
            console.error('E-posta gönderilirken hata oluştu:', error.response?.data || error.message);
            throw new Error('E-posta gönderme hatası: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleNext = async () => {
        if (!recaptchaValue) {
            setErrorMessage('Lütfen reCAPTCHA doğrulamasını tamamlayın.');
            return;
        }

        if (emailRegex.test(email)) {
            setIsSendingEmail(true);
            try {
                await sendVerificationEmail(email, "showcase");
                sessionStorage.setItem('email', email);
                setErrorMessage('');
                setIsSendingEmail(false);
                setCurrentPage(2); // Sadece hata yoksa bir sonraki aşamaya geç
            } catch (error: any) {
                setIsSendingEmail(false);
                console.error('Hata:', error);

                // E-posta mevcutsa hata mesajı göster
                if (error.message === 'Bu e-posta adresi zaten mevcut.') {
                    setErrorMessage('Bu e-posta adresi zaten kayıtlı. Lütfen başka bir e-posta deneyin.');
                } else {
                    setErrorMessage(error.message || 'Bir hata oluştu. Lütfen tekrar deneyin.');
                }
            }
        } else {
            setErrorMessage('Geçerli bir e-posta adresi giriniz.');
        }
    };

    const handleStepClick = (step: number) => {
        setCurrentPage(step);
    };

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isConsentPopupOpen, setIsConsentPopupOpen] = useState(false);

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const [error, setError] = useState('');

    const handleSubmitClick = () => {
        if (!formData.companyname || !formData.name || !formData.surname || !formData.phone || !formData.duty || !formData.password || !formData.newsletter || !formData.membershipConsent) {
            setError(texts[language].errorMessage);
            return;
        }
        setError('');
        setCurrentPage(3);
    };

    const handleVerifyCodeSubmit = async () => {
        if (verificationCode.join('').length === 4) {
            const email = sessionStorage.getItem('email');
            const type = 'showcase';
            const mailCode = verificationCode.join('').trim();

            try {
                const ipResponse = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
                const ip = ipResponse.data.ip;

                const response = await axios.post<SaveVisitorResponse>(
                    `${process.env.REACT_APP_DAMISE_API_URL}/utils/custom-send-form/${type}/${mailCode}`,
                    {
                        password: formData.password,
                        lang: language,
                        newsletter: formData.newsletter,
                        country: formData.country,
                        ip: ip,
                        duty: formData.duty,
                        phone: formData.phone,
                        email: email || '',
                        surname: formData.surname,
                        name: formData.name,
                        companyname: formData.companyname,
                    }
                );

                const visitorId = response.data.visitorId || response.data.id || response.data.userId || response.data._id;

                if (visitorId) {
                    // `localStorage`'da `visitorId` değerini sakla
                    localStorage.setItem('visitorId', visitorId);
                }

                if (onVerifySuccess) {
                    onVerifySuccess();
                }

                // Başarılı doğrulamadan sonra sayfayı yenile
                window.location.reload();
            } catch (error) {
                setErrorMessage('Doğrulama kodu hatalı.');
            }
        } else {
            setErrorMessage('4 haneli bir doğrulama kodu giriniz.');
        }
    };


    const handleVerificationInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            const newVerificationCode = [...verificationCode];
            newVerificationCode[index] = value;
            setVerificationCode(newVerificationCode);
            if (value.length === 1 && e.target.nextElementSibling) {
                (e.target.nextElementSibling as HTMLInputElement).focus();
            }
        }
        else if (value === "") {
            const newVerificationCode = [...verificationCode];
            newVerificationCode[index] = "";
            setVerificationCode(newVerificationCode);
        }
    };

    const handleVerificationInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = e.clipboardData.getData("text").slice(0, 4);
        const newVerificationCode = [...verificationCode];
        for (let i = 0; i < pastedData.length; i++) {
            newVerificationCode[i] = pastedData[i];
        }
        setVerificationCode(newVerificationCode);
        const nextInput = e.currentTarget.form?.querySelectorAll("input")[pastedData.length];
        if (nextInput) {
            (nextInput as HTMLInputElement).focus();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace") {
            if (verificationCode[index] === "" && e.currentTarget.previousElementSibling) {
                (e.currentTarget.previousElementSibling as HTMLInputElement).focus();
            }
        }
    };

    const handleNewsletterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            newsletter: e.target.checked,
        });
    };

    const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            consent: e.target.checked,
        });
    };

    const handleAcceptConsentPopup = () => {
        setFormData({
            ...formData,
            consent: true,
        });
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (formRef.current && !formRef.current.contains(event.target as Node)) {
                setIsFormOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [formRef]);

    const toggleConsentPopup = () => {
        setIsConsentPopupOpen(!isConsentPopupOpen);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleAcceptPopup = () => {
        setFormData({
            ...formData,
            newsletter: true,
        });
    };

    const renderButton = () => (
        <div className="fixed bottom-5 right-5 z-50 bg-[#353642] text-white p-2 sm:p-4 rounded-lg shadow-lg flex flex-col items-center justify-between space-y-2 w-11/12 max-w-sm sm:max-w-md opacity-50 hover:opacity-100 transition-opacity duration-300">
            <div className="text-center text-sm sm:text-base leading-relaxed">
                {language === 'en'
                    ? "This is the trailer show of the launch, to see the full version"
                    : "Bu lansman fragman gösterimidir, tamamını görmek için"}
            </div>
            <span
                onClick={() => setIsFormOpen(true)}
                className="bg-red-900 text-white px-4 py-2 rounded-full cursor-pointer transition-all border-2 border-red-900 hover:bg-transparent hover:text-white mt-2"
            >
                {language === 'en' ? "Register" : "Kayıt ol"}
            </span>
        </div>
    );

    const renderSteps = () => (
        <div className="flex items-center justify-center space-x-4 text-sm text-gray-600 mb-4">
            <div className="flex items-center space-x-2">
                <button
                    className={`text-gray-600 flex items-center space-x-1 ${currentPage === 1 ? 'font-bold text-red-800' : ''}`}
                    onClick={() => handleStepClick(1)}
                    disabled={currentPage < 1}
                >
                    <span className={`rounded-full w-8 h-8 flex justify-center items-center ${currentPage >= 1 ? 'bg-red-800 text-white' : 'bg-gray-300 text-gray-500'}`}>1</span>
                    <span className="ml-2">{language === 'en' ? "Email" : "E-Posta"}</span>
                </button>
            </div>

            <div className="flex items-center space-x-2">
                <span className="text-gray-400">➜</span>
            </div>

            <div className="flex items-center space-x-2">
                <button
                    className={`text-gray-600 flex items-center space-x-1 ${currentPage === 2 ? 'font-bold text-red-800' : ''}`}
                    onClick={() => handleStepClick(2)}
                    disabled={currentPage < 2}
                >
                    <span className={`rounded-full w-8 h-8 flex justify-center items-center ${currentPage >= 2 ? 'bg-red-800 text-white' : 'bg-gray-300 text-gray-500'}`}>2</span>
                    <span className="ml-2">{language === 'en' ? "Visitor Form" : "Ziyaretçi Formu"}</span>
                </button>
            </div>

            <div className="flex items-center space-x-2">
                <span className="text-gray-400">➜</span>
            </div>

            <div className="flex items-center space-x-2">
                <button
                    className={`text-gray-600 flex items-center space-x-1 ${currentPage === 3 ? 'font-bold text-red-800' : ''}`}
                    onClick={() => handleStepClick(3)}
                    disabled={currentPage < 3}
                >
                    <span className={`rounded-full w-8 h-8 flex justify-center items-center ${currentPage >= 3 ? 'bg-red-800 text-white' : 'bg-gray-300 text-gray-500'}`}>3</span>
                    <span className="ml-2">{language === 'en' ? "Verification" : "Doğrulama"}</span>
                </button>
            </div>
        </div>
    );

    const [isMembersPopupOpen, setIsMembersPopupOpen] = useState(false);

    // Toggle Members Popup
    const toggleMembersPopup = () => {
        setIsMembersPopupOpen(!isMembersPopupOpen);
    };


    // Popup Accept Handler
    const handleAcceptMembersPopup = () => {
        setFormData({
            ...formData,
            membershipConsent: true,
        });
        setIsMembersPopupOpen(false);
    };

    const handlemembershipConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setIsMembersPopupOpen(true);
        } else {
            setFormData({
                ...formData,
                membershipConsent: false,
            });
        }
    };
    // Popup Rendering
    const renderMembersPopup = () => {
        if (language === 'en') {
            return (
                <PopupMembersEN
                    isOpen={isMembersPopupOpen}
                    onClose={toggleMembersPopup}
                    onAccept={handleAcceptMembersPopup}
                />
            );
        } else {
            return (
                <PopupMembers
                    isOpen={isMembersPopupOpen}
                    onClose={toggleMembersPopup}
                    onAccept={handleAcceptMembersPopup}
                />
            );
        }
    };

    return isFormVisible ? (
        <>
            {isFormOpen ? (
                <div className="fixed bottom-5 right-5 z-50">
                    <div ref={formRef} className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-md z-10">
                        {renderSteps()}

                        {currentPage === 1 && (
                            <div className="animate-slideIn space-y-6">
                                <h2 className="text-xl font-semibold text-gray-800">{texts[language].title}</h2>
                                <p className="text-sm text-gray-500">
                                    {language === 'en'
                                        ? "You need to verify your email address with a one-time code we will send to you."
                                        : "E-posta adresinizi, tarafınıza göndereceğimiz tek seferlik kod ile doğrulamanız gerekmektedir."}
                                </p>
                                <input
                                    type="email"
                                    placeholder={texts[language].emailPlaceholder}
                                    value={email || ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                />
                                {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}

                                <ReCAPTCHA
                                    sitekey="6LeuE4YmAAAAALfP9QB266CMknsey9ka241XMItg"
                                    onChange={onRecaptchaChange}
                                />

                                {isSendingEmail ? (
                                    <div className="flex justify-center items-center space-x-2">
                                        <div className="w-6 h-6 border-4 border-red-400 border-solid rounded-full animate-spin border-t-transparent"></div>
                                        <span>{language === 'en' ? "Sending..." : "Gönderiliyor..."}</span>
                                    </div>
                                ) : (
                                    <button
                                        onClick={handleNext}
                                        className="w-full bg-red-900 text-white p-3 rounded-lg hover:bg-red-700 transition duration-300"
                                    >
                                        {texts[language].nextButton}
                                    </button>
                                )}

                                <div className="text-center mt-6">
                                    <p className="text-gray-700">
                                        {language === 'en' ? "Already have a Damise membership?" : "Damise üyeliğiniz var mı?"}{' '}
                                        <span
                                            className="text-gray-800 cursor-pointer"
                                            onClick={() => navigate(language === "en" ? "/en/login" : "/tr/giris-yap")}
                                        >
                                            {language === 'en' ? "Log in" : "Giriş yap"}
                                        </span>
                                    </p>
                                    <p className="text-gray-700 mt-2">
                                        {language === 'en' ? "Want to join DAMISE?" : "DAMISE'e katılmak ister misiniz?"}{' '}
                                        <a href="https://damise.com/tr/uyelik" className="text-gray-800">
                                            {language === 'en' ? "Register" : "Kayıt ol"}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}

                        {currentPage === 2 && (
                            <div className="animate-slideIn space-y-6">
                                <h2 className="text-xl font-semibold text-gray-800">{texts[language].title}</h2>
                                <div className="space-y-4">
                                    <input
                                        type="text"
                                        name="companyname"
                                        placeholder={texts[language].companyName}
                                        value={formData.companyname}
                                        onChange={handleFormChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        required
                                    />
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder={texts[language].firstName}
                                            value={formData.name}
                                            onChange={handleFormChange}
                                            className="w-full p-3 border border-gray-300 rounded-lg"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="surname"
                                            placeholder={texts[language].lastName}
                                            value={formData.surname}
                                            onChange={handleFormChange}
                                            className="w-full p-3 border border-gray-300 rounded-lg"
                                            required
                                        />
                                    </div>
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder={texts[language].phone}
                                            value={formData.phone}
                                            onChange={handleFormChange}
                                            className="w-full p-3 border border-gray-300 rounded-lg"
                                            required
                                        />
                                        <select
                                            name="country"
                                            value={formData.country}
                                            onChange={handleFormChange}
                                            className="w-full p-3 border border-gray-300 rounded-lg"
                                            required
                                        >
                                            <CountryOptions />
                                        </select>
                                    </div>
                                    <input
                                        type="text"
                                        name="duty"
                                        placeholder={texts[language].duty}
                                        value={formData.duty}
                                        onChange={handleFormChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        required
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder={texts[language].password}
                                        value={formData.password}
                                        onChange={handleFormChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        required
                                    />

                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="newsletter"
                                            checked={formData.newsletter}
                                            onChange={handleNewsletterChange}
                                            className="mr-2"
                                        />
                                        <span className="text-sm">
                                            {texts[language].newsletterLabel}
                                            <button type="button" onClick={togglePopup} className="text-blue-500 underline">
                                                {language === 'en' ? "Privacy Notice.*" : "Aydınlatma Metni *"}
                                            </button>
                                            {texts[language].newsletterLabel2}
                                        </span>
                                    </label>

                                    <label className="flex items-center mt-4">
                                        <input
                                            type="checkbox"
                                            name="membershipConsent"
                                            checked={formData.membershipConsent}
                                            onChange={handlemembershipConsent}
                                            className="mr-2"
                                        />
                                        <span className="text-sm">

                                            <button type="button" onClick={toggleMembersPopup} className="text-blue-500 underline">
                                                {language === 'en' ? "Membership Agreement*" : "Üyelik Sözleşmesi *"}
                                            </button>
                                            {texts[language].membersConsentLabel}
                                        </span>
                                    </label>

                                    <Popup isOpen={isPopupOpen} onClose={togglePopup} onAccept={handleAcceptPopup} language={language} />
                                    {renderMembersPopup()}

                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="consent"
                                            checked={formData.consent}
                                            onChange={handleConsentChange}
                                            className="mr-2"
                                        />
                                        <span className="text-sm">

                                            <button type="button" onClick={toggleConsentPopup} className="text-blue-500 underline">
                                                {language === 'en' ? "Open Consent Text" : "Açık Rıza Metni "}
                                            </button>
                                            {texts[language].consentLabel}
                                        </span>
                                    </label>


                                    <PopupConsent language={language} isOpen={isConsentPopupOpen} onClose={toggleConsentPopup} onAccept={handleAcceptConsentPopup} />

                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                    <button
                                        onClick={handleSubmitClick}
                                        className="w-full bg-red-900 text-white p-3 rounded-lg hover:bg-red-700 transition duration-300"
                                    >
                                        {texts[language].continueButton}
                                    </button>
                                </div>
                            </div>
                        )}

                        {currentPage === 3 && (
                            <div className="animate-slideIn space-y-6">
                                <h2 className="text-xl font-semibold text-gray-800">{texts[language].verificationTitle}</h2>
                                <div className="flex justify-center space-x-2">
                                    {Array(4).fill('').map((_, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={verificationCode[index] || ''}
                                            onChange={(e) => handleVerificationInputChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            onPaste={handleVerificationInputPaste}
                                            className="w-12 h-12 text-xl text-center border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
                                        />

                                    ))}
                                </div>
                                <button
                                    onClick={handleVerifyCodeSubmit}
                                    className={`w-full p-3 rounded-lg mt-4 ${verificationCode.join('').length === 4 ? 'bg-red-900 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                                    disabled={verificationCode.join('').length !== 4}
                                >
                                    {texts[language].submitButton}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                renderButton()
            )}
        </>
    ) : null;
};

export default VisitForm;
