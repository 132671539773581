import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";
interface TopTextCardSectionProps {
    componentData: {
        text: string;
        media: string;
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const TopTextCardSection: React.FC<TopTextCardSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || "homepage"; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const { ref, inView } = useInView({
        triggerOnce: false, // Sürekli tetiklenmesi için false yapıldı
        threshold: 0.3,
    });
    const socket = useSocket();
    const [viewDuration, setViewDuration] = useState(0); // İzleme süresi (saniye)

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            // Kart görünümdeyse süreyi başlat
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000);
        } else {
            // Kart görünümden çıktığında
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                // Görüntüleme süresini gönder
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} kartını izledi.`
                );

                // Süreyi sıfırla
                setViewDuration(0);
            }
        }

        // Temizlik işlemleri
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, _id, designId, socket]);

    const renderMediaPreview = () => {
        const fileExtension = componentData.media
            .split(".")
            .pop()
            ?.toLowerCase();

        if (
            ["jpg", "jpeg", "png", "gif", "webp"].includes(fileExtension || "")
        ) {
            return (
                <img
                    src={componentData.media}
                    alt="Selected Media"
                    className="w-full h-full object-cover rounded-lg" // Görselin tam çerçeveyi doldurması için object-cover
                />
            );
        }

        if (["mp4", "webm"].includes(fileExtension || "")) {
            return (
                <video
                    className="w-full h-full object-cover rounded-b-lg" // Videonun tam çerçeveyi doldurması için object-cover
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source
                        src={componentData.media}
                        type={`video/${fileExtension}`}
                    />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    const mediaStyle: React.CSSProperties = {
        opacity: 0,
        transform: "translateY(80px)",
        transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
    };

    const visibleStyle: React.CSSProperties = {
        opacity: 1,
        transform: "translateY(0)",
    };

    return (
        <div
            ref={ref}
            className="bg-white p-0 mx-auto overflow-hidden rounded-b-3xl w-full md:w-[85vw] h-auto md:h-[70vh]"
        >
            <div className="flex flex-col justify-between items-center w-full h-full">
                {/* Başlık */}
                <div className="text-center py-2 w-full">
                    <p
                        className="text-lg md:text-3xl font-bold leading-tight"
                        style={{
                            fontFamily: "Radhanji, sans-serif",
                            color: "gray-900",
                        }}
                    >
                        {componentData.text}
                    </p>
                </div>

                {/* Medya */}
                <div
                    className="w-full h-auto md:h-full flex justify-center items-center"
                    style={{
                        ...mediaStyle,
                        ...(inView ? visibleStyle : {}),
                    }}
                >
                    {renderMediaPreview()}
                </div>
            </div>
        </div>
    );
};

export default TopTextCardSection;
