import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useSocket } from "../../context/SocketContext";
import { IProduct, IService } from "../../services/fairTypes";

interface Card {
    id: string;
    buttonText: string;
    text: string;
    logoMedia: string;
    backGroundMedia?: string;
    about: string | "";
    url?: string;
}

interface ItemModalCardProps {
    card: Card;
    show: boolean;
    setShow: (show: boolean) => void;
    lang: string;
    launchUrl: string;
    type: "product" | "service";
    products: { _id: string; product: IProduct }[];
    services: { _id: string; service: IService }[];
    id?:string
}

const ItemModalCard: React.FC<ItemModalCardProps> = ({
    card,
    show,
    setShow,
    lang,
    launchUrl,
    type,
    id,
}) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });


    const socket = useSocket(); // Socket.IO kullanımı

    const handleDetailsClick = () => {
        const visitorId = localStorage.getItem("visitorId") || "none_user";

        if (socket && socket.connected) {
            // 'click' aktivitesi gönderiliyor
            socket.emit("activity", {
                userId: visitorId,
                launchId: type,
                activityType: "click",
                productId: type === "product" ? id : null,
                serviceId: type === "service" ? id : null,
            });

            console.log("Tüm detayları görüntüle aktivitesi gönderildi:", {
                userId: visitorId,

                launchId: launchUrl,
                type,
                productId: type === "product" ? id : null,
                serviceId: type === "service" ? id : null,
            });
        } else {
            console.error("Socket bağlantısı yok, aktivite gönderilemedi.");
        }
    };

    useEffect(() => {
        const visitorId = localStorage.getItem("visitorId") || "none_user";

        if (socket && socket.connected) {
            // 'view' aktivitesi gönderiliyor
            socket.emit("activity", {
                userId: visitorId,
                launchId: launchUrl,
                activityType: "view",
                type, // 'product' veya 'service'
                productId: type === "product" ? id : null,
                serviceId: type === "service" ? id : null,
            });

            console.log("Modal görüntüleme aktivitesi gönderildi:", {
                userId: visitorId,
                launchId: launchUrl,
                type,
            });
        }
    }, [socket, card?.id, launchUrl, type]);

    return (
        <Modal
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            open={show}
            onClose={() => setShow(!show)}
        >
            <div
                ref={ref}
                style={{
                    height: "70vh",
                    width: "85vw",
                }}
                className="w-full max-w-[95vw] mx-auto flex lg:flex-row-reverse flex-col bg-gray-70 rounded-3xl shadow-xl overflow-hidden"
            >
                <div className="relative lg:w-[50%] w-full h-full p-6 bg-gray-100 flex-col flex items-center justify-start">
                    <motion.h1
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        className="text-black text-right"
                        style={{
                            marginBottom: "2rem",
                            maxWidth: "600px",
                            fontSize: `clamp(24px, 3vw, calc(${-card?.text.length / 5
                                }px + 3rem))`,
                            lineHeight: "1.2",
                            fontFamily: "Radhanji, sans-serif",
                            textAlign: "left",
                            wordWrap: "break-word",
                        }}
                    >
                        {card?.text}
                    </motion.h1>
                    <motion.p
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        className="text-black text-right mb-10"
                        style={{
                            maxWidth: "600px",
                            fontSize: "clamp(18px, 1.6vw, 1.6rem)",
                            lineHeight: "1.2",
                            fontFamily: "Radhanji, sans-serif",
                            textAlign: "left",
                            wordWrap: "break-word",
                        }}
                    >
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    card?.about?.length > 400
                                        ? `${card?.about?.slice(0, 400)}...`
                                        : card?.about,
                            }}
                        />
                    </motion.p>
                    <p>
                       {id}
                    </p>
                    <a
                        className="px-4 py-2 sm:px-6 sm:py-3 lg:px-8 lg:py-4 text-white bg-red-700 border-2 border-transparent rounded-full transition-colors duration-300 group-hover:bg-white/30 group-hover:border-red-700 hover:bg-white/60 hover:border-red-700 hover:text-red-700 absolute bottom-2 right-2 sm:bottom-1 sm:right-1 lg:bottom-3 lg:right-3"
                        href={
                            lang === "tr"
                                ? type === "product"
                                    ? `https://damise.com/tr/urunler/${card?.url}`
                                    : `https://damise.com/tr/hizmetler/${card?.url}`
                                : type === "product"
                                    ? `https://damise.com/en/products/${card?.url}`
                                    : `https://damise.com/en/services/${card?.url}`
                        }
                        target="_blank"
                        onClick={handleDetailsClick}
                    >
                        {lang === "tr" ? "Tüm detayları görüntüle" : "View all details"}
                    </a>

                </div>

                <div className="lg:w-[50%] w-full h-[50vh] lg:h-full">
                    <img
                        src={card?.backGroundMedia}
                        alt="Selected Media"
                        className="w-full h-full object-contain bg-white"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ItemModalCard;
