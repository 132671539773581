import React from "react";

import { useEffect, useState } from "react";

import useSearchStore from "../../store/damiseStores/searchStore";

import { SearchLayout } from "../../components/damiseComponents/SearchLayout";
import { Spinner } from "../../components/damiseComponents/Spinner";

import Company from "./Company";
import Product from "./Product";
import Service from "./Service";

//import { useLanguage } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
    useFair,
    useFairProducts,
    useFairServices,
} from "../../services/damiseServices";
import useFairStore from "../../store/fairStore";
import useDeployDesignStore from "../../store/useLaunchForComponents";

export const ParticipantDetails = () => {
    const { launchUrl, companyUrl } = useParams<{
        launchUrl: string;
        companyUrl: string;
    }>();

    //const { lang } = useLanguage();
    const [selectedSectors, setSelectedSectors] = useState<string[]>([]);
    const [visitorId, setVisitorId] = useState<string | null>(null); // visitorId'yi state'de tutuyoruz
    const { setLaunchData, launchData, setFairData } = useFairStore();
    const navigate = useNavigate();

    const [activeTab] = useSearchStore((state) => [state.activeTab]);
    const { data: selectedFair, isLoading: loadingSelectedFair } = useFair(
        launchData.language,
        launchData.damiseId
    );

    const {
        data: fairProducts,
        isLoading: loadingFairProducts,
        isError: errorFairProducts,
    } = useFairProducts(selectedFair?._id, selectedSectors);
    const {
        data: fairServices,
        isLoading: loadingFairServices,
        isError: errorFairServices,
    } = useFairServices(selectedFair?._id, selectedSectors);

    const loading = loadingFairProducts || loadingFairServices;
    const error = errorFairProducts || errorFairServices;

    const { fetchLaunch, launch, components } = useDeployDesignStore(
        (state: any) => state
    );
    useEffect(() => {
        if (selectedFair?._id) {
            console.log("Selected Fair ID:", selectedFair._id);
        }
    }, [selectedFair]);
    const fetchDatas = async () => {
        try {
            // Lansman verilerini çekiyoruz
            const launchData = await fetchLaunch(launchUrl);
            setLaunchData(launchData);
        } catch (error) {
            console.error("Firma bilgileri alınırken hata oluştu:", error);
            navigate("/access-denied");
        }
    };

    useEffect(() => {
        if (launchUrl) {
            fetchDatas();
        }
        const storedVisitorId = localStorage.getItem("visitorId"); // Session Storage'teki visitorId kontrolü
        setVisitorId(storedVisitorId);
    }, [launchUrl, navigate]);

    useEffect(() => {
        if (launch && components) {
            // Lansmanın aktif olup olmadığını kontrol ediyoruz
            if (!launch.isActive) {
                console.log("Firma sayfası aktif değil, erişim engellendi.");
                navigate("/access-denied"); // Eğer lansman aktif değilse erişim engelleniyor
                return;
            }
        }
    }, [launch, components]);

    if (loadingSelectedFair || loading) return <Spinner />;

    if (error) return <div> Something went wrong </div>;

    return (
        <SearchLayout
        // title={
        //     selectedFair?.name?.[lang as keyof typeof selectedFair.name] ??
        //     ""
        // }
        >
            {(activeTab === "company" || activeTab === "firma") && (
                <Company
                    fairId={String(selectedFair?._id)}
                    selectedSectors={selectedSectors}
                    setSelectedSectors={setSelectedSectors}
                />
            )}
            {(activeTab === "product" || activeTab === "ürün") && (
                <Product
                    fairId={String(selectedFair?._id)}
                    data={fairProducts}
                />
            )}
            {(activeTab === "service" || activeTab === "hizmet") && (
                <Service
                    fairId={String(selectedFair?._id)}
                    data={fairServices}
                />
            )}
        </SearchLayout>
    );
};
