import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useSocket } from "../../context/SocketContext"; // SocketContext'ten socket'i aldık

interface FullTextCardSectionProps {
    componentData: {
        text: string;
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const FullTextCardSection: React.FC<FullTextCardSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const { ref, inView } = useInView({
        threshold: 0.1, // Daha düşük eşik değerine ayarladık
        triggerOnce: false, // Tekrar tetiklenmeyi aktif bıraktık
    });

    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";

    const socket = useSocket(); // Socket bağlamını alıyoruz
    const [viewDuration, setViewDuration] = useState<number>(0);
    const [isViewing, setIsViewing] = useState<boolean>(false); // Aktif izleme durumu

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView && !isViewing) {
            setIsViewing(true); // İzlemeye başla
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1); // Her saniye artır
            }, 1000);
        } else if (!inView && isViewing) {
            setIsViewing(false); // İzlemeyi durdur
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, socket, _id, designId, isViewing]);

    return (
        <motion.div
            ref={ref}
            className="flex justify-center items-center mx-auto bg-white rounded-lg shadow-none p-4"
            style={{
                height: "50vh",
                width: "85vw",
                transformOrigin: "center",
                overflow: "hidden",
            }}
            initial={{ opacity: 0.3 }}
            animate={
                inView
                    ? { opacity: 1, scale: 1 }
                    : { opacity: 0.3, scale: 0.9 }
            }
            transition={{ duration: 1, ease: "easeInOut" }}
        >
            <div
                className="w-full h-full text-center flex items-center justify-center"
                style={{
                    padding: "0 5%",
                    fontSize: "clamp(16px, 2vw, 24px)",
                }}
            >
                <p
                    className="font-medium"
                    style={{
                        fontFamily: "Radhanji, sans-serif",
                        lineHeight: 1.6,
                    }}
                >
                    {componentData.text}
                </p>
            </div>
        </motion.div>
    );
};

export default FullTextCardSection;
