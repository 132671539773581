import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";

interface CTACardSectionProps {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonUrl: string;
    componentName: string;
    _id: string; // Component ID
    designId: string; // Launch ID
}

const CTACardSection: React.FC<CTACardSectionProps> = ({
    title,
    subtitle,
    buttonText,
    buttonUrl,
    componentName,
    _id,
    designId,
}) => {
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });

    const socket = useSocket();
    const [viewDuration, setViewDuration] = useState<number>(0);
    const viewStartTime = useRef<number | null>(null);

    // Görünüm süresi takibi
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            viewStartTime.current = Date.now();
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000);
        } else {
            if (interval) clearInterval(interval);
            if (viewStartTime.current) {
                const duration = (Date.now() - viewStartTime.current) / 1000;
                if (duration > 0 && socket) {
                    socket.emit("activity", {
                        userId: localStorage.getItem("visitorId") || "none_user",
                        componentId: _id,
                        launchId: designId,
                        activityType: "view-duration",
                        duration: Math.round(duration),
                    });
                    console.log(`View duration logged: ${Math.round(duration)} seconds`);
                }
                viewStartTime.current = null; // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, socket, _id, designId]);

    // Backend loglama
    useEffect(() => {
        if (inView) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                    launchUrl,
                    visitorId: localStorage.getItem("visitorId"),
                    componentName,
                    componentCount: 1,
                })
                .then((response) => {
                    console.log("Component verisi başarıyla kaydedildi:", response.data);
                })
                .catch((error) => {
                    console.error("Component verisi kaydedilirken hata oluştu:", error);
                });
        }
    }, [inView, launchUrl, componentName]);

    // Tıklama olayı
    const handleClick = () => {
        if (socket) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [`${buttonUrl}`],
            });
            console.log("Click event logged:", buttonText, buttonUrl);
        }
    };

    return (
        <motion.div
            ref={ref}
            className="flex flex-col md:flex-row justify-between items-center p-6 bg-white border border-gray-300 rounded-3xl shadow-lg mx-auto min-h-[190px] w-full md:w-[85vw]"
            style={{ marginTop: "0px", fontFamily: "Radhanji, sans-serif" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
        >
            <div className="flex flex-col ml-0 md:ml-8 text-center md:text-left">
                <h3 className="text-2xl md:text-4xl font-semibold text-black">{title}</h3>
                {subtitle && (
                    <p className="text-xl md:text-3xl text-gray-600 mt-2">{subtitle}</p>
                )}
            </div>

            <motion.a
                href={buttonUrl}
                className="mt-4 md:mt-0 md:mr-12 px-8 md:px-16 py-4 md:py-6 text-white rounded-full font-extrabold text-base md:text-lg transition-transform duration-300"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: "#970928" }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                onClick={handleClick}
            >
                {buttonText}
            </motion.a>
        </motion.div>
    );
};

export default CTACardSection;
