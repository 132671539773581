import React, { useEffect, useState } from "react";
import { motion, useInView } from "framer-motion";
import useFairStore from "../../store/fairStore";
import { useParams } from "react-router-dom";
import { useCompany } from "../../services/damiseServices";
import { useSocket } from "../../context/SocketContext"; // Socket kullanımı

interface CompanyFullTextCardSectionData {
    text: string;
}

const CompanyFullTextCardSection = () => {
    const { companyUrl, launchUrl } = useParams<{
        companyUrl: string;
        launchUrl: string;
    }>();
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: false }); // Her kaydırmada tetiklenecek
    const [viewDuration, setViewDuration] = useState(0); // Görünüm süresi
    const [componentData, setComponentData] =
        useState<CompanyFullTextCardSectionData>({
            text: "",
        });
    const { launchData } = useFairStore();
    const {
        data: selectedCompany,
        isLoading: loadingCompany,
        isError: errorCompany,
    } = useCompany(launchData.language, companyUrl);

    const socket = useSocket(); // Socket kullanımı

    useEffect(() => {
        const createCard = async () => {
            if (launchData.language === "tr") {
                setComponentData((prevData) => ({
                    ...prevData,
                    text: selectedCompany?.about?.tr || "",
                }));
            } else {
                setComponentData((prevData) => ({
                    ...prevData,
                    text: selectedCompany?.about?.en || "",
                }));
            }
        };

        createCard();
    }, [launchData, useParams, companyUrl, selectedCompany]);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (isInView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000); // Her saniye görünüm süresini artır
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: `${launchUrl}-${companyUrl}`,
                    launchId: launchUrl || "default_launch",
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${launchUrl}-${companyUrl} kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isInView, viewDuration, companyUrl, launchUrl, socket]);

    if (!componentData.text) return <></>;

    return (
        <motion.div
            ref={ref}
            className="flex justify-center items-center mx-auto bg-white rounded-lg shadow-none"
            style={{
                height: "45vh", // Yükseklik 45vh
                width: "full", // Genişlik full
                transformOrigin: "center", // Kart merkezde kalacak
            }}
            initial={{ opacity: 0.3 }} // Başlangıçta çok az opak
            animate={isInView ? { opacity: 1 } : { opacity: 0.3 }} // Görünüyorsa tamamen opak hale gelir, değilse biraz saydam kalır
            transition={{ duration: 2, ease: "easeInOut" }} // Yavaş ve yumuşak geçiş
        >
            <div className="max-w-[1000px] text-center p-4">
                <p
                    className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-medium"
                    style={{ fontFamily: "Radhanji, sans-serif" }} // Font ailesi Radhanji olarak ayarlandı
                >
                    {componentData.text.length > 600
                        ? componentData?.text?.slice(0, 600) + "..."
                        : componentData?.text}
                </p>
            </div>
        </motion.div>
    );
};

export default CompanyFullTextCardSection;
