import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

// SocketContext oluşturuyoruz
const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        // LocalStorage'dan visitorId'yi al, yoksa "none_user" olarak oluştur ve kaydet
        let visitorId = localStorage.getItem("visitorId");


        // Socket.IO bağlantısı oluştur
        const newSocket = io(process.env.REACT_APP_SOCKET_URL as string, {
            query: { userId: visitorId }, // visitorId'yi sorgu parametresi olarak gönder
        });

        newSocket.on("connect", () => {
            console.log("Socket.IO bağlantısı kuruldu:", newSocket.id);
        });

        newSocket.on("disconnect", () => {
            console.log("Socket.IO bağlantısı kesildi.");
        });

        setSocket(newSocket);

        // Temizlik: Bileşen unmount olduğunda bağlantıyı kapat
        return () => {
            newSocket.disconnect();
        };
    }, []);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
