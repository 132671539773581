import React, { useState } from "react";
import useFairStore from "../../store/fairStore";
import ItemModalCard from "../componentmodals/ItemModalCard";
import { useParams } from "react-router-dom";

interface ItemCard {
    text: string;
    logoMedia: string;
    backGroundMedia?: string;
    about: string;
    url?: string;
    companyName: string;
    type: "product" | "service";
    id: string;
}

interface ItemCardProps {
    item: ItemCard;
}

const ItemCard: React.FC<ItemCardProps> = ({ item }) => {
    const { launchUrl } = useParams<{
        launchUrl: string;
    }>();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ItemCard>();
    const { launchData, fairData } = useFairStore();
    const renderMedia = (mediaUrl: string) => {
        const fileExtension = mediaUrl.split(".").pop()?.toLowerCase();
        const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
        const videoTypes = ["mp4", "webm", "ogg"];
        const audioTypes = ["mp3", "wav", "ogg"];

        if (imageTypes.includes(fileExtension || "")) {
            return (
                <img
                    src={mediaUrl}
                    alt="Görsel medya"
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                />
            );
        } else if (videoTypes.includes(fileExtension || "")) {
            return (
                <video
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                    autoPlay
                    loop
                    muted
                >
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else if (audioTypes.includes(fileExtension || "")) {
            return (
                <audio
                    className="w-full h-full object-cover absolute top-0 left-0 z-0"
                    controls
                >
                    <source src={mediaUrl} type={`audio/${fileExtension}`} />
                    Tarayıcınız bu sesi desteklemiyor.
                </audio>
            );
        } else {
            return (
                <p className="text-white">
                    Desteklenmeyen medya formatı: {fileExtension}
                </p>
            );
        }
    };

    const handleClick = (item: ItemCard) => {
        setSelectedItem(item);
        setShowPopup(true);
    };

    return (
        <>
            <div className="flex justify-start space-x-6 w-full ">
                <div className="w-full h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl  transform scale-100 hover:scale-105 transition-transform duration-300">
                    {item.backGroundMedia ? (
                        renderMedia(item.backGroundMedia)
                    ) : item.logoMedia ? (
                        renderMedia(item.logoMedia)
                    ) : (
                        <p className="text-white">Medya bulunamadı</p>
                    )}

                    <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent transition-opacity duration-300 group-hover:bg-black/50">
                        <p
                            className="text-base font-semibold mb-1 text-center w-full line-clamp-2"
                            style={{
                                fontFamily: "Radhanji, sans-serif",
                                //marginBottom: "20px",
                                //maxWidth: "calc(100% - 120px)",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                textAlign: "center",
                            }}
                        >
                            {item.text}
                        </p>
                        <p
                            className="text-base font-semibold mb-1 text-center w-full line-clamp-2"
                            style={{
                                fontFamily: "Radhanji, sans-serif",
                                //marginBottom: "20px",
                                //maxWidth: "calc(100% - 120px)",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                textAlign: "center",
                            }}
                        >
                            {item?.companyName
                                .replace(/i/g, "İ")
                                .replace(/ı/g, "I")
                                .replace(/ğ/g, "Ğ")
                                .replace(/ü/g, "Ü")
                                .replace(/ş/g, "Ş")
                                .replace(/ö/g, "Ö")
                                .replace(/ç/g, "Ç")
                                .toUpperCase()}
                        </p>
                        <a
                            onClick={() => handleClick(item)}
                            rel="noopener noreferrer"
                            className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 cursor-pointer"
                            onMouseEnter={() => {
                                const swiperInstance = (
                                    document.querySelector(
                                        ".swiper"
                                    ) as unknown as {
                                        swiper: {
                                            autoplay: {
                                                stop: () => void;
                                            };
                                        };
                                    }
                                )?.swiper;
                                swiperInstance?.autoplay.stop();
                            }}
                            onMouseLeave={() => {
                                const swiperInstance = (
                                    document.querySelector(
                                        ".swiper"
                                    ) as unknown as {
                                        swiper: {
                                            autoplay: {
                                                start: () => void;
                                            };
                                        };
                                    }
                                )?.swiper;
                                swiperInstance?.autoplay.start();
                            }}
                        >
                            <span
                                className="px-8 py-3 text-white bg-transparent border-2 border-white rounded-full transition-colors duration-300 group-hover:bg-white/30 group-hover:border-white/30 hover:bg-white/60 hover:border-white/60 hover:text-black"
                                style={{
                                    fontFamily: "Radhanji, sans-serif",
                                }}
                            >
                                {launchData.language === "tr"
                                    ? "İncele"
                                    : "View"}
                            </span>
                        </a>
                    </div>

                    {/* {item.logoMedia && (
                        <div className="absolute bottom-4 right-4 w-16 h-16 z-20">
                            <img
                                src={item.logoMedia}
                                className="w-full h-full object-contain"
                            />
                        </div>
                    )} */}
                </div>
            </div>
            {selectedItem && (
                <ItemModalCard
                    show={showPopup}
                    setShow={setShowPopup}
                    card={{
                        ...selectedItem,
                        buttonText:
                            launchData.language === "tr" ? "İncele" : "View",
                    }}
                    lang={launchData?.language}
                    launchUrl={launchUrl || ""}
                    type={selectedItem.type}
                    id={item?.id}
                    products={fairData?.products || []}
                    services={fairData?.services || []}
                />
            )}
        </>
    );
};

export default ItemCard;
