import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";
import axios from "axios";

interface LargeScalableCardSectionProps {
    componentData: {
        media: string;
        text: string;
        componentName: string;
    };
    marginTop?: number;
    marginBottom?: number;
    _id: string; // Component ID
    designId: string; // Launch ID
}

const LargeScalableCardSection: React.FC<LargeScalableCardSectionProps> = ({
    componentData,
    marginTop = 15,
    marginBottom = 15,
    _id,
    designId,
}) => {
    const isVideo = (mediaUrl: string) => {
        return (
            mediaUrl.endsWith(".mp4") ||
            mediaUrl.endsWith(".webm") ||
            mediaUrl.endsWith(".ogg")
        );
    };
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const [scrollY, setScrollY] = useState(0);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [viewDuration, setViewDuration] = useState(0);
    const socket = useSocket(); // SocketContext'ten socket'i aldık

    useEffect(() => {
        if (inView) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                    launchUrl,
                    visitorId: localStorage.getItem("visitorId"),
                    componentName: componentData.componentName,
                    componentCount: 1,
                })
                .then((response) => {
                    console.log(
                        "Component verisi başarıyla kaydedildi:",
                        response.data
                    );
                })
                .catch((error) => {
                    console.error(
                        "Component verisi kaydedilirken hata oluştu:",
                        error
                    );
                });
        }
    }, [inView, launchUrl]);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const scaleFactor = Math.max(0, Math.min(1, 1 - scrollY / 500));

    const minWidth = 1042;
    const minHeight = 337;

    const maxWidth = windowSize.width;
    const maxHeight = windowSize.height;

    const width = minWidth + (maxWidth - minWidth) * scaleFactor;
    const height = minHeight + (maxHeight - minHeight) * scaleFactor;

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000);
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${_id} kartını izledi.`
                );

                setViewDuration(0);
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, _id, designId, socket]);

    useEffect(() => {
        const style = document.createElement("style");
        style.innerHTML = `
            ::-webkit-scrollbar {
                display: none;
            }
            body {
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-x: hidden;
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return (
        <motion.div
            ref={ref}
            className="flex justify-center items-center bg-white py-10 w-full max-w-full overflow-x-hidden"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                marginTop: `${marginTop}px`,
                marginBottom: `${marginBottom}px`,
                transformOrigin: "center",
            }}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
                scale: 1,
                opacity: 1,
                height: `${height}px`,
                width: `${width}px`,
            }}
            transition={{ duration: 1.7, ease: "easeOut" }}
        >
            {componentData.media && (
                <div className="overflow-hidden w-full h-full flex justify-center items-center">
                    {isVideo(componentData.media) ? (
                        <motion.video
                            src={componentData.media}
                            className="object-cover w-full h-full"
                            autoPlay
                            muted
                            loop
                        />
                    ) : (
                        <motion.img
                            src={componentData.media}
                            alt="Large Scalable Card"
                            className="object-cover w-full h-full"
                        />
                    )}
                </div>
            )}
        </motion.div>
    );
};

export default LargeScalableCardSection;
