import React from "react";

import { memo } from "react";
//import { useLanguage } from 'hooks'
import { FcDeleteDatabase } from "react-icons/fc";
import classNames from "classnames";

type Props = {
    title: string;
    position?: string;
};

export const AnyData = memo((props: Props) => {
    const { title, position } = props;
    // const { t } = useLanguage();
    return (
        <div
            className={classNames(
                "flex w-full flex-col items-center justify-center gap-5",
                {
                    "w-52": position === "left",
                }
            )}
        >
            <FcDeleteDatabase size={44} />
            <p className="text-center text-lg font-light text-danger">
                {title}
            </p>
        </div>
    );
});

AnyData.displayName = "AnyData";
