import React from "react";

import { useState, Dispatch, SetStateAction } from "react";

import { Spinner } from "../../components/damiseComponents/Spinner";

//import { useLanguage } from "hooks";

import { Collapse, Tooltip, theme } from "antd";
import { BsTrash } from "react-icons/bs";
import { RxCaretDown, RxCaretUp, RxGrid, RxListBullet } from "react-icons/rx";

import { useSectors } from "../../services/damiseServices";

import { UseFormRegister, UseFormReset } from "react-hook-form";
import type { ICompanySearchBody } from "../../services/damiseTypes";
import useFairStore from "../../store/fairStore";

type Props = {
    mode: "grid" | "table";
    setMode: Dispatch<SetStateAction<"grid" | "table">>;
    reset: UseFormReset<ICompanySearchBody>;
    register: UseFormRegister<ICompanySearchBody>;
};

const SearchFilters = (props: Props) => {
    const { mode, setMode, reset, register } = props;
    // const { t, lang } = useLanguage();

    const { token } = theme.useToken();
    const { Panel } = Collapse;

    const panelStyle = {
        marginBottom: 12,
        background: token.colorWhite,
        borderRadius: token.borderRadiusLG,
        color: "red",
        border: "none",
    };

    const [activeKey, setActiveKey] = useState<string | string[]>(["2"]);
    const { launchData } = useFairStore();

    const {
        data: sectors,
        isLoading: loadingSectors,
        isError: errorSectors,
    } = useSectors();

    function toggleMode() {
        setMode(mode === "grid" ? "table" : "grid");
    }

    function handleResetFilters(): void {
        setActiveKey([]);
        reset();
    }

    const isLoading = loadingSectors;

    const isError = errorSectors;

    if (isLoading) return <Spinner />;
    // if (isError) return <div>{t("somethingWentWrong")}</div>;

    return (
        <div className="flex flex-col justify-start">
            <div className="flex justify-end gap-x-3 pr-8">
                <Tooltip
                    className="cursor-pointer"
                    placement="right"
                    // title={t("clearFilters")}
                >
                    <BsTrash
                        onClick={handleResetFilters}
                        size={20}
                        className="text-red-700"
                    />
                </Tooltip>
            </div>
            <Collapse
                onChange={(e) => setActiveKey(e)}
                activeKey={activeKey}
                bordered={false}
                accordion
                expandIconPosition="end"
                style={{ backgroundColor: token.colorWhite }}
                expandIcon={({ isActive }) => {
                    return isActive ? (
                        <RxCaretUp size={24} color="#cc374b" />
                    ) : (
                        <RxCaretDown size={24} color="#cc374b" />
                    );
                }}
            >
                <Panel
                    header={
                        <h3 className="text-[16px]  font-[600] text-red-700">
                            {" "}
                            {/* {t("sectors")}{" "} */}
                            {launchData.language === "tr"
                                ? "Sektörler"
                                : "Sectors"}
                        </h3>
                    }
                    key="2"
                    style={panelStyle}
                >
                    <div className="h-auto md:h-auto xl:h-auto">
                        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6">
                            {/* Filtreleri burada döngüyle render ediyoruz */}
                            {sectors?.map((s) => (
                                <div
                                    className="flex items-center justify-start gap-3 p-2"
                                    key={s?._id}
                                >
                                    <input
                                        type="checkbox"
                                        value={s?._id}
                                        id={s?._id}
                                        className="w-5 h-5"
                                        {...register("sectors")}
                                    />
                                    <label htmlFor={s?._id} className="text-base text-gray-700">
                                        {s?.name?.[launchData.language as keyof typeof s.name]}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
};

export default SearchFilters;
