import React, { useRef, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSocket } from "../../context/SocketContext";
import { useParams } from "react-router-dom";

interface ReelsCardSliderSectionProps {
    componentData: {
        reelsCards: Array<{
            media: string;
            title: string;
            subtitle: string;
        }>;
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const ReelsCardSliderSection: React.FC<ReelsCardSliderSectionProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";
    const socket = useSocket();

    const { ref: inViewRef, inView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });

    const duplicatedCards = [
        ...componentData.reelsCards,
        ...componentData.reelsCards,
    ];

    const cardWidth = 320; // Her kartın genişliği
    const [isPaused, setIsPaused] = useState(false);
    const [lastReportedIndex, setLastReportedIndex] = useState<number | null>(
        null
    );

    // Loglama
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const visibleEntry = entries.find((entry) => entry.isIntersecting);
                if (visibleEntry && sliderRef.current) {
                    const visibleIndex = Array.from(
                        sliderRef.current.children
                    ).indexOf(visibleEntry.target);

                    if (
                        visibleIndex !== -1 &&
                        visibleIndex !== lastReportedIndex
                    ) {
                        setLastReportedIndex(visibleIndex);

                        const card = componentData.reelsCards[
                            visibleIndex % componentData.reelsCards.length
                        ];

                        if (socket) {
                            socket.emit("activity", {
                                userId: localStorage.getItem("visitorId") || "none_user",
                                componentId: _id,
                                launchId: designId,
                                activityType: "view",
                                texts: [{ text: card.title, count: 1 }],
                            });

                            console.log(`View logged for card: ${card.title}`);
                        }
                    }
                }
            },
            { threshold: 0.5 }
        );

        if (sliderRef.current) {
            Array.from(sliderRef.current.children).forEach((child) =>
                observer.observe(child)
            );
        }

        return () => {
            observer.disconnect();
        };
    }, [componentData.reelsCards, socket, _id, designId, lastReportedIndex]);

    const isVideo = (media: string) => {
        const videoExtensions = ["mp4", "webm", "ogg"];
        const extension = media.split(".").pop();
        return extension && videoExtensions.includes(extension.toLowerCase());
    };

    // Otomatik kaydırma işlevi
    useEffect(() => {
        const autoSlide = () => {
            if (sliderRef.current) {
                const maxScrollLeft =
                    sliderRef.current.scrollWidth -
                    sliderRef.current.clientWidth;

                if (sliderRef.current.scrollLeft >= maxScrollLeft) {
                    sliderRef.current.scrollTo({
                        left: 0,
                        behavior: "smooth",
                    });
                } else {
                    sliderRef.current.scrollBy({
                        left: cardWidth,
                        behavior: "smooth",
                    });
                }
            }
        };

        if (!isPaused) {
            const interval = setInterval(autoSlide, 3000); // Her 3 saniyede bir kaydır
            return () => clearInterval(interval);
        }
    }, [isPaused, cardWidth]);

    return (
        <div className="relative w-full flex justify-center items-center">
            {/* Slider */}
            <div
                ref={(node) => {
                    inViewRef(node);
                    sliderRef.current = node as HTMLDivElement; // TypeScript için tür dönüştürme
                }}
                className="max-w-[95%] overflow-x-scroll flex space-x-5 py-4 px-2 scrollbar-hide"
                style={{
                    scrollSnapType: "x mandatory",
                    WebkitOverflowScrolling: "touch",
                }}
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => setIsPaused(false)}
            >
                {duplicatedCards.map((card, index) => (
                    <div
                        key={index}
                        className="relative flex-shrink-0 w-[320px] h-[600px] rounded-3xl shadow-lg overflow-hidden transition-transform transform-gpu hover:scale-105 duration-500"
                        style={{ scrollSnapAlign: "start" }}
                    >
                        {isVideo(card.media) ? (
                            <video
                                src={card.media}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <img
                                src={card.media}
                                alt={`Reels Card ${index + 1}`}
                                className="w-full h-full object-cover"
                            />
                        )}

                        <div className="absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-black to-transparent text-white">
                            <div className="p-4">
                                <h3
                                    className="text-2xl text-center font-semibold mb-1"
                                    style={{
                                        fontFamily: "Radhanji, sans-serif",
                                    }}
                                >
                                    {card.title}
                                </h3>
                                <p
                                    className="text-gray-300 text-center"
                                    style={{
                                        fontFamily: "Radhanji, sans-serif",
                                    }}
                                >
                                    {card.subtitle}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ReelsCardSliderSection;
