import { useQuery } from "@tanstack/react-query";
import axios from "../../libs/axios";
import {
    IProduct,
    IBranchInfo,
    ICompany,
    ICompanyDocument,
    ICompanyWithLimit,
    IDocumentName,
    ICompanyActivityAreas,
    IPublicCompany,
    ICompanySearchBody,
    ISector,
    ICompanyReference,
    IService,
    IPublicCompanyProducts,
    ICompanyTestCapability,
    IFeaturedCompany,
    ICompanyBadge,
    ICompanyOtherSector,
} from "../damiseTypes";

export const getSectors = (): Promise<ISector[]> =>
    axios(`/sector/sector`).then((response) => response.data);

export const getCompanyTypes = (): Promise<IDocumentName[]> =>
    axios(`/utils/company-type`).then((response) => response.data);

export const getCompanies = (): Promise<ICompany[]> =>
    axios(`/company/getcompanies`).then((response) => response.data);

const getFeaturedCompanies = (): Promise<IFeaturedCompany[]> =>
    axios(`/company/company-highlight`).then((response) => response.data);

export function useFeaturedCompanies() {
    return useQuery(["featured-companies"], getFeaturedCompanies);
}

const getCompanyById = (companyId?: string): Promise<ICompany> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/getcompanyid/${companyId}`).then(
              (response) => response.data
          );
};
export function useCompanyById(companyId?: string, notLogged?: boolean) {
    return useQuery(["companies", companyId], () => getCompanyById(companyId), {
        enabled: Boolean(companyId) && Boolean(!notLogged),
    });
}
const getCompanyBySlug = (
    lang: string,
    companySlug?: string
): Promise<ICompany> => {
    return typeof companySlug === "undefined" || typeof lang === "undefined"
        ? Promise.reject(new Error("Invalid request"))
        : axios(`/company/slug/${lang}/${companySlug}`).then(
              (response) => response.data
          );
};

export function useCompany(
    lang: string,
    companySlug?: string,
    notLogged?: boolean
) {
    return useQuery(
        ["companies", companySlug],
        () => getCompanyBySlug(lang, companySlug),
        {
            enabled:
                Boolean(lang) && Boolean(companySlug) && Boolean(!notLogged),
        }
    );
}

const getCompanyBranches = (companyId?: string): Promise<IBranchInfo[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/getcompany-branch-address/${companyId}`).then(
              (response) => response.data
          );
};

export function useCompanyBranches(companyId?: string) {
    return useQuery(
        ["company-branches", companyId],
        () => getCompanyBranches(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}
export const getCompanyDocumentNames = (): Promise<IDocumentName[]> =>
    axios(`/company/getdocumentname`).then((response) => response.data);

const getCompanyDocumentTypes = (): Promise<IDocumentName[]> =>
    axios(`/company/documenttype-all`).then((response) => response.data);

export const useCompanyDocumentTypes = () => {
    return useQuery(["document-types"], getCompanyDocumentTypes);
};

const getCompanyDocuments = (
    companyId?: string
): Promise<ICompanyDocument[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/company-document-companyid/${companyId}`).then(
              (response) => response.data
          );
};
export function useCompanyDocuments(companyId?: string) {
    return useQuery(
        ["company-documents", companyId],
        () => getCompanyDocuments(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}
const getCompanyDocumentTypesAll = (
    companyId?: string
): Promise<ICompanyDocument[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/company-documenttype-new/${companyId}`).then(
              (response) => response.data
          );
};

export function useCompanyDocumentTypesAll(companyId?: string) {
    return useQuery(
        ["company-documents", companyId],
        () => getCompanyDocumentTypesAll(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}

const getCompanyProducts = (companyId?: string): Promise<IProduct[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/products/products/getcompanyproduct/${companyId}`).then(
              (response) => response.data
          );
};
export function useCompanyProducts(companyId?: string) {
    return useQuery(
        ["company-products", companyId],
        () => getCompanyProducts(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}

const getFeaturedProductsOfCompany = (
    companyId?: string
): Promise<IProduct[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(
              `/products/products/getcompanyproduct/${companyId}?highlight=yes`
          ).then((response) => response.data);
};
export function useFeaturedProductsOfCompany(companyId?: string) {
    return useQuery(
        ["company-featured-products", companyId],
        () => getFeaturedProductsOfCompany(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}
const getCompanyReferences = (
    companyId?: string
): Promise<ICompanyReference[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/company-reference/${companyId}`).then(
              (response) => response.data.info
          );
};
export function useCompanyReferences(companyId?: string) {
    return useQuery(
        ["company-references", companyId],
        () => getCompanyReferences(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}

const getCompanyServices = (companyId?: string): Promise<IService[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/services/company/getservice/${companyId}`).then(
              (response) => response.data
          );
};
export function useCompanyServices(companyId?: string) {
    return useQuery(
        ["company-services", companyId],
        () => getCompanyServices(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}

const getCompanyActivityAreas = (
    companyId?: string
): Promise<ICompanyActivityAreas[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/company-activityarea-new/${companyId}`).then(
              (response) => response.data
          );
};

export function useCompanyActivityAreas(companyId?: string) {
    return useQuery(
        ["company-activity-areas", companyId],
        () => getCompanyActivityAreas(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}
const getCompaniesWithLimit = (
    limit: number | undefined
): Promise<ICompanyWithLimit[]> => {
    return typeof limit === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/utils/company-limit/${limit}`).then(
              (response) => response.data
          );
};

export function useCompaniesWithLimit(limit: number | undefined) {
    return useQuery(
        ["companies-limit", limit],
        () => getCompaniesWithLimit(limit),
        {
            enabled: Boolean(limit),
        }
    );
}

const getPublicCompany = (companyId?: string): Promise<IPublicCompany> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/utils/company/${companyId}`).then(
              (response) => response.data
          );
};
/*   const getPublicCompany = (lang?: string, companySlug?: string): Promise<IPublicCompany> => {
  return typeof companySlug === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axios(`/utils/company/${companySlug}`).then((response) => response.data)
} */

export function usePublicCompany(companyId?: string) {
    return useQuery(
        ["company-public", companyId],
        () => getPublicCompany(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}

const getPublicCompanies = async (): Promise<IPublicCompany[]> => {
    const { data } = await axios(`/utils/company-list`);
    return data;
};

export function usePublicCompanies() {
    return useQuery(["public-companies"], getPublicCompanies);
}

const getPublicCompanyProducts = async (
    companyId?: string
): Promise<IPublicCompanyProducts[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/products/products/getcompanyuserproduct/${companyId}`).then(
              (response) => response.data
          );
};
export function usePublicCompanyProducts(companyId?: string) {
    return useQuery(
        ["public-company-products", companyId],
        () => getPublicCompanyProducts(companyId),
        { enabled: Boolean(companyId) }
    );
}

const getCompanyTestCapabilities = (
    companyId?: string
): Promise<ICompanyTestCapability[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/company-testcapability-companyid/${companyId}`).then(
              (response) => response.data
          );
};

export function useCompanyTestCapabilities(companyId?: string) {
    return useQuery(
        ["company-test-capabilities", companyId],
        () => getCompanyTestCapabilities(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}
const getCompanyOtherSector = (
    companyId?: string
): Promise<ICompanyOtherSector> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/company/other-sector/${companyId}`).then(
              (response) => response.data
          );
};

export function useCompanyOtherSector(companyId?: string) {
    return useQuery(
        ["company-other-sector", companyId],
        () => getCompanyOtherSector(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}

const getCompanyBadges = (companyId?: string): Promise<ICompanyBadge[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid company id"))
        : axios(`/badge/badge-assignment-company/${companyId}`).then(
              (response) => response.data
          );
};

export const useCompanyBadges = (companyId?: string) => {
    return useQuery(["company-badges"], () => getCompanyBadges(companyId), {
        enabled: Boolean(companyId),
    });
};

// __ Public Company
const getPublicCompanyServices = (
    companyId?: string
): Promise<IPublicCompanyProducts[]> => {
    return typeof companyId === "undefined"
        ? Promise.reject(new Error("Invalid id"))
        : axios(`/services/company/getservice-user/${companyId}`).then(
              (response) => response.data
          );
};

export function usePublicCompanyServices(companyId?: string) {
    return useQuery(
        ["public-company-services", companyId],
        () => getPublicCompanyServices(companyId),
        {
            enabled: Boolean(companyId),
        }
    );
}

// __ Search
export const searchCompanies = async (
    reqBody: ICompanySearchBody,
    searchSortOption: string
): Promise<ICompany[]> => {
    return typeof reqBody === "undefined"
        ? Promise.reject(new Error("Invalid request body"))
        : await axios
              .post(
                  `/utils/company-search?log=true${searchSortOption}`,
                  reqBody
              )
              .then((response) => response.data);
};
