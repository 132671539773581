import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/apiService'; // Axios instance'ı import edin
import LoadingSpinner from '../components/LoadingSpinner'; // Yüklenme göstergesi için bir bileşen
import PreviewPage from './PreviewPage'; // PreviewPage bileşeni

interface LaunchData {
    _id: string;
    launchName: string; // Lansman adı
    showOnHomepage: boolean;
    startDate: string;
    endDate: string;
}

const HomepagePreviewPage: React.FC = () => {
    const [launch, setLaunch] = useState<LaunchData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHomepageLaunch = async () => {
            try {
                const response = await api.get<LaunchData[]>(`${process.env.REACT_APP_API_URL}/launch`);
                const homepageLaunches = response.data.filter((launch) => launch.showOnHomepage);

                if (homepageLaunches.length === 1) {
                    // Eğer yalnızca bir lansman showOnHomepage: true ise
                    setLaunch(homepageLaunches[0]);
                } else if (homepageLaunches.length > 1) {
                    // Eğer birden fazla showOnHomepage lansman varsa
                    setError('Birden fazla lansman ana sayfada gösteriliyor. Sadece bir lansman olmalıdır.');
                } else {
                    // Eğer hiç showOnHomepage: true olan lansman yoksa
                    setError('Ana sayfada gösterilecek bir lansman bulunamadı.');
                }
            } catch (error) {
                console.error('Lansman bilgileri alınırken bir hata oluştu:', error);
                setError('Lansman bilgileri alınırken bir hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchHomepageLaunch();
    }, []);

    const handleAdminLogin = () => {
        navigate('/admin-login'); // Admin giriş sayfasına yönlendirme
    };

    if (loading) {
        return <LoadingSpinner />; // Yüklenme durumu
    }

    if (error) {
        return <div className="text-red-500">{error}</div>; // Hata durumu
    }

    if (launch) {
        // Launch adını ve ID'sini PreviewPage'e geçiyoruz
        return (
            <div>
                {/* Buton sağa hizalandı ama biraz daha aşağıya alındı */}
                <div className="flex justify-end">
                   
                </div>
                <PreviewPage launchId={launch._id}  />
            </div>
        );
    }

    return null;
};

export default HomepagePreviewPage;
