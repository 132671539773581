import axios from "axios";
import { CompanyData, IFair } from "./fairTypes";

const api = axios.create({
    baseURL: process.env.REACT_APP_DAMISE_API_URL,
});

export const getFair = async (fairId?: string): Promise<IFair> => {
    return typeof fairId === "undefined"
        ? Promise.reject(new Error("Invalid fair id"))
        : api
              .get<IFair>(`/fair/fair-highlight/${fairId}`)
              .then((response) => response.data);
};

export const getCompany = async (companySlug?: string, lang?: string) => {
    try {
        const response = await api.get<CompanyData>(
            `/company/slug/${lang?.toLowerCase()}/${companySlug}`
        );
        console.log("Company Response:", response.data);
        return response.data;
    } catch (error) {
        console.error("API request error: ", error);
        throw error;
    }
};

export const getProduct = async (companyId?: string) => {
    try {
        const response = await api.get<CompanyData>(
            `/products/products/getcompanyproduct/${companyId}`
        );
        console.log("Company Response:", response.data);
        return response.data;
    } catch (error) {
        console.error("API request error: ", error);
        throw error;
    }
};

export const getService = async (companyId?: string) => {
    try {
        const response = await api.get<CompanyData>(
            `/services/company/getservice/${companyId}`
        );
        console.log("Company Response:", response.data);
        return response.data;
    } catch (error) {
        console.error("API request error: ", error);
        throw error;
    }
};
