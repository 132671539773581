import create from "zustand";
import { S3File } from "../services/types";
import { IFair } from "../services/fairTypes";
import { ICompany } from "../services/damiseTypes";

export interface LaunchData {
    active: boolean;
    _id: string;
    launchName: string;
    language: string;
    groupNumber: string;
    companyName: string;
    companyLogo: string;
    startDate: string;
    endDate: string;
    orderNumber: string;
    isActive: boolean;
    showOnHomepage: boolean;
    logo?: S3File;
    damiseId?: string;
}

interface FairStore {
    launchData: LaunchData;
    fairData?: IFair;
    setLaunchData: (data: Partial<any>) => void;
    setFairData: (data: Partial<IFair>) => void;
    resetLaunchData: () => void;
}

const useFairStore = create<FairStore>((set) => ({
    fairData: {
        products: [],
        services: [],
        participation: [],
    },
    launchData: {
        active: false,
        _id: "",
        launchName: "",
        language: "",
        groupNumber: "",
        companyName: "",
        companyLogo: "",
        startDate: "",
        endDate: "",
        orderNumber: "",
        isActive: false,
        showOnHomepage: false,
        logo: undefined,
        damiseId: undefined,
    },
    setLaunchData: (data) =>
        set((state) => ({
            launchData: {
                ...state.launchData,
                ...data,
                language: data.language
                    ? data.language.toLowerCase()
                    : state.launchData.language,
            },
        })),
    setFairData: (data: Partial<IFair>) =>
        set((state) => ({
            fairData: {
                ...state.fairData,
                ...data,
            },
        })),
    resetLaunchData: () =>
        set({
            launchData: {
                active: false,
                _id: "",
                launchName: "",
                language: "",
                groupNumber: "",
                companyName: "",
                companyLogo: "",
                startDate: "",
                endDate: "",
                orderNumber: "",
                isActive: false,
                showOnHomepage: false,
                logo: undefined,
                damiseId: undefined,
            },
        }),
}));

export default useFairStore;
