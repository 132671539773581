import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import useFairStore from "../../store/fairStore";
import { useParams } from "react-router-dom";
import { useCompany } from "../../services/damiseServices";
import { useSocket } from "../../context/SocketContext"; // Socket kullanımı

interface CompanyLargeScalableCardData {
    media: string;
    text: string;
}

const CompanyLargeScalableCardSection = () => {
    const { companyUrl, launchUrl } = useParams<{
        companyUrl: string;
        launchUrl: string;
    }>();
    const isVideo = (mediaUrl: string) => {
        return (
            mediaUrl.endsWith(".mp4") ||
            mediaUrl.endsWith(".webm") ||
            mediaUrl.endsWith(".ogg")
        );
    };
    const [componentData, setComponentData] =
        useState<CompanyLargeScalableCardData>({
            media: "",
            text: "",
        });
    const { fairData, launchData } = useFairStore();
    const {
        data: selectedCompany,
        isLoading: loadingCompany,
        isError: errorCompany,
    } = useCompany(launchData?.language, companyUrl);

    const { ref, inView } = useInView({
        triggerOnce: false, // Her görünüm için kontrol
        threshold: 0.3, // Görünüm yüzdesi
    });

    const [viewDuration, setViewDuration] = useState(0); // Görünüm süresi
    const socket = useSocket(); // Socket kullanımı

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (inView) {
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000); // Her saniye artır
        } else {
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: `${launchUrl}-${companyUrl}`,
                    launchId: launchUrl || "default_launch",
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Kullanıcı ${viewDuration} saniye boyunca ${launchUrl}-${companyUrl} scalable kartını izledi.`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [inView, viewDuration, companyUrl, launchUrl, socket]);

    useEffect(() => {
        const createCard = async () => {
            if (launchData.language === "tr") {
                setComponentData((prevData) => ({
                    ...prevData,
                    text: selectedCompany?.about?.tr || "",
                    media: selectedCompany?.promotionImages?.[0] || "",
                }));
            } else {
                setComponentData((prevData) => ({
                    ...prevData,
                    text: selectedCompany?.about?.en || "",
                    media: selectedCompany?.promotionImagesEn?.[0] || "",
                }));
            }
        };

        createCard();
    }, [selectedCompany, launchData]);

    const [scrollY, setScrollY] = useState(0);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const scaleFactor = Math.max(0, Math.min(1, 1 - scrollY / 500));

    const minWidth = 1042;
    const minHeight = 337;

    const maxWidth = windowSize.width;
    const maxHeight = windowSize.height;

    const width = minWidth + (maxWidth - minWidth) * scaleFactor;
    const height = minHeight + (maxHeight - minHeight) * scaleFactor;

    useEffect(() => {
        const style = document.createElement("style");
        style.innerHTML = `
            ::-webkit-scrollbar {
                display: none;
            }
            body {
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-x: hidden; /* Yatay kaymayı tamamen devre dışı bırak */
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    if (!componentData.media) return <></>;

    return (
        <>
            <motion.div
                ref={ref}
                className="flex justify-center items-center bg-white py-10 w-full max-w-full overflow-x-hidden"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: `${20}px`,
                    marginBottom: `${20}px`,
                    transformOrigin: "center",
                }}
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    height: `${height}px`,
                    width: `${width}px`,
                }}
                transition={{ duration: 1.7, ease: "easeOut" }}
            >
                {componentData.media && (
                    <div className="overflow-hidden w-full h-full flex justify-center items-center">
                        {isVideo(componentData.media) ? (
                            <motion.video
                                src={componentData.media}
                                className="object-cover w-full h-full"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <motion.img
                                src={componentData.media}
                                alt="Large Scalable Card"
                                className="object-cover w-full h-full"
                            />
                        )}
                    </div>
                )}
            </motion.div>
        </>
    );
};

export default CompanyLargeScalableCardSection;
