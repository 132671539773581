import React from "react";
import { useParams } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";
import useFairStore from "../../store/fairStore";

interface Company {
    title: string;
    media?: string;
    subtitle: string;
    exploreUrl: string;
    logo?: string;
    id?: string;
}

interface CompanyCardProps {
    company: Company;
}

const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => {
 
    const { launchData } = useFairStore();
    const socket = useSocket(); // Socket.IO bağlantısı

    const handleProtectedLinkClick = (url: string,title:string) => {
        const visitorId = localStorage.getItem("visitorId") || "none_user";

        if (socket && socket.connected) {
            // Aktiviteyi Socket.IO ile gönder
            const textlinks = `${url} | ${title}`;
            socket.emit("activity", {
                userId: visitorId,
                launchId: "CompanyList",
                activityType: "click",
                companyId: company?.id || null,
                textlinks: [textlinks],
            });

            console.log("Şirket aktivitesi gönderildi:", {
                userId: visitorId,
                launchId: "CompanyList",
                companyId: company?.id,
            });
        } else {
            console.error("Socket bağlantısı yok, aktivite gönderilemedi.");
        }

        // Yeni sekmede URL aç
        window.open(url, "_blank");
    };

    return (
        <div
            className={`relative flex-shrink-0 w-[320px] h-[600px] rounded-xl shadow-lg overflow-hidden transition-transform transform-gpu hover:scale-105 duration-500`}
        >
            {company?.media ? (
                <img
                    src={company.media}
                    className="h-full w-full object-cover"
                    alt={company?.title}
                />
            ) : (
                <div className="flex justify-center items-center h-full w-full">
                    <img src={company.logo} className="w-2/3" alt="Logo" />
                </div>
            )}
    
            <div className="absolute bottom-0 left-0 w-full p-6 bg-gradient-to-t from-black to-transparent text-white">
                <h3
                    className="text-xl text-center font-semibold mb-1 line-clamp-2"
                    style={{ fontFamily: "Radhanji, sans-serif" }}
                >
                    {company?.title
                        ? company.title
                            .replace(/i/g, "İ")
                            .replace(/ı/g, "I")
                            .replace(/ğ/g, "Ğ")
                            .replace(/ü/g, "Ü")
                            .replace(/ş/g, "Ş")
                            .replace(/ö/g, "Ö")
                            .replace(/ç/g, "Ç")
                            .toUpperCase()
                        : ""}
                </h3>
                <p
                    className="text-gray-300 text-center mb-5"
                    style={{ fontFamily: "Radhanji, sans-serif" }}
                >
                    {company?.subtitle}
                </p>
                <p>
                    {`Ürün ID: ${company?.id}`}
                </p>
                {company?.exploreUrl && (
                    <div className="text-center">
                        <button
                            onClick={() =>
                                handleProtectedLinkClick(company?.exploreUrl,company?.title)
                            }
                            className="inline-block px-8 py-3 text-lg font-bold text-white mb-3 border-2 border-white rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg"
                            style={{
                                fontFamily: "Radhanji, sans-serif",
                            }}
                        >
                            
                            {launchData.language === "tr"
                                ? "Görüntüle"
                                : "View"}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyCard;
