import create from "zustand";
import axios from "axios";
import useFairStore from "./fairStore";

interface DeployDesignStore {
    launch: any | null; // Launch verisi
    components: any[]; // Component verileri
    loading: boolean; // Yükleme durumu
    error: string | null; // Hata mesajı
    fetchLaunch: (launchUrl: string) => Promise<void>; // Launch verisini çeken fonksiyon
}

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";

// Zustand store oluşturma
const useDeployDesignStore = create<DeployDesignStore>((set) => ({
    launch: null,
    components: [],
    loading: false,
    error: null,

    // Launch ve componentları fetch eden fonksiyon
    fetchLaunch: async (launchUrl: string) => {
        set({ loading: true, error: null }); // Yükleme durumunu aktif et
        try {
            const response = await axios.get(
                `${apiUrl}/launch/by-launch-url/${launchUrl}`
            );
            console.log("Launch API Response:", response.data);

            // Verileri store'a kaydet
            set({
                launch: (response.data as any).launch, // Launch bilgisi
                components: (response.data as any).components, // Component listesi
                loading: false,
            });
            return (response.data as any).launch;
        } catch (error: any) {
            // Hata durumunda error mesajını kaydet
            set({
                error: error.response?.data || "An error occurred",
                loading: false,
            });
            return null;
        }
    },
}));

export default useDeployDesignStore;
