import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParticipantDetails } from "./ParticipantDetails";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const SearchLayout: React.FC = () => {
    const navigate = useNavigate();
    const [visitorId, setVisitorId] = useState<string | null>(null);

    // URL'den dil bilgisi çıkarma
    const determineLanguage = (): string => {
        const path = window.location.pathname.toLowerCase();
        if (path.includes("katilimcilar")) {
            return "tr";
        }
        if (path.includes("participants")) {
            return "en";
        }
        // Varsayılan dil
        return "en";
    };

    const language = determineLanguage();

    useEffect(() => {
        const storedVisitorId = localStorage?.getItem("visitorId");
        if (storedVisitorId) {
            setVisitorId(storedVisitorId);
        } else {
            // Dil bilgisine göre yönlendirme
            if (language === "tr") {
                navigate("/tr/giris-yap");
            } else if (language === "en") {
                navigate("/en/login");
            } else {
                navigate("/en/login"); // Varsayılan dil
            }
        }
    }, [language]);

    return (
        <>
            <Navbar />
            <div className="pt-48">
                <ParticipantDetails />
            </div>
            <Footer />
        </>
    );
};

export default SearchLayout;
