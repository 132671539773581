import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import { useSocket } from "../../context/SocketContext";
import { useParams } from "react-router-dom";

interface LargeTopTitleHeroCardSectionProps {
    componentData: {
        title: string;
        subtitle: string;
        buttonText: string;
        buttonUrl: string;
        media?: string; // Arka plan resmi için opsiyonel medya alanı
        componentName: string; // Backend'e göndermek için componentName ekleniyor
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const LargeTopTitleHeroCardSection: React.FC<
    LargeTopTitleHeroCardSectionProps
> = ({ componentData, _id, designId }) => {
    const { title, subtitle, buttonText, buttonUrl, media, componentName } =
        componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";

    const { ref, inView } = useInView({
        triggerOnce: false, // Tekrar görünürlüğü kontrol etmek için
        threshold: 0.3,
    });

    const socket = useSocket(); // Socket bağlanıyor
    const [viewDuration, setViewDuration] = useState<number>(0);
    let interval: NodeJS.Timeout | null = null;

    // Görüntüleme süresini izleme
    useEffect(() => {
        if (inView) {
            // Bileşen görünür olduğunda süreyi başlat
            interval = setInterval(() => {
                setViewDuration((prev) => prev + 1);
            }, 1000);
        } else {
            // Bileşen görünür olmadığında süreyi logla ve temizle
            if (interval) clearInterval(interval);

            if (viewDuration > 0 && socket) {
                socket.emit("activity", {
                    userId: localStorage.getItem("visitorId") || "none_user",
                    componentId: _id,
                    launchId: designId,
                    activityType: "view-duration",
                    duration: viewDuration,
                });

                console.log(
                    `Bileşen ${viewDuration} saniye boyunca görüntülendi. ID: ${_id}`
                );

                setViewDuration(0); // Süreyi sıfırla
            }
        }

        return () => {
            if (interval) clearInterval(interval); // Bileşen temizliği sırasında interval temizle
        };
    }, [inView, viewDuration, socket, _id, designId]);

    const handleClick = () => {
        if (socket) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "click",
                textlinks: [`${buttonUrl} || ${buttonText}`],
            });
            console.log(
                `Click aktivitesi loglandı: ${buttonText} - URL: ${buttonUrl}`
            );
        }
    };

    return (
        <section
            ref={ref}
            className="relative w-[85vw] h-[70vh] flex flex-col justify-start text-center mx-auto rounded-3xl overflow-hidden pt-12"
        >
            {/* Arka plandaki resim */}
            {media && (
                <div className="absolute inset-0">
                    <img
                        src={media}
                        alt="Background"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-transparent"></div>
                </div>
            )}

            {/* İçerik */}
            <div className="relative z-10">
                <h1
                    className="text-4xl font-bold text-white mb-2"
                    style={{ fontFamily: "Radhanji, sans-serif" }}
                >
                    {title}
                </h1>
                <h2
                    className="text-xl text-white mb-4"
                    style={{ fontFamily: "Radhanji, sans-serif" }}
                >
                    {subtitle}
                </h2>
                {buttonText && buttonUrl && (
                    <motion.a
                        href={buttonUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-transparent border-2 border-white text-white px-6 py-3 rounded-full text-lg font-bold transition-colors duration-300 mt-4"
                        whileHover={{
                            scale: 1.05,
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                        }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        style={{ fontFamily: "Radhanji, sans-serif" }}
                        onClick={handleClick} // Click event loglanıyor
                    >
                        {buttonText}
                    </motion.a>
                )}
            </div>
        </section>
    );
};

export default LargeTopTitleHeroCardSection;
