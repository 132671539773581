import React from "react";

import { useMemo, useState } from "react";

//import { useLanguage } from 'hooks'

//import { ItemCardWithModal } from "../../../components/damiseComponents/Card";
import { AnyData } from "../../../components/damiseComponents/AnyData";

import { useForm } from "react-hook-form";
import classNames from "classnames";

import TopSearchArea from "../TopSearchArea";
//import TableView from "../TableView";
import SearchFilters from "../SearchFilters";

import type {
    IService,
    ICompanySearchBody,
    IFeaturedParticipation,
} from "../../../services/damiseTypes";
import useFairStore from "../../../store/fairStore";
import ItemCard from "../../../components/damiseComponents/ItemCard";
import { Pagination } from "antd";

const defaultValues: ICompanySearchBody = {
    sectors: [],
};

const FairServiceList = (props: {
    data?: IFeaturedParticipation["services"];
    fairId: string;
}) => {
    const { data, fairId } = props;

    //const { lang } = useLanguage();
    const { launchData } = useFairStore();

    const { register, reset } = useForm({
        defaultValues,
    });

    const [mode, setMode] = useState<"grid" | "table">("grid");
    const [searchValue, setSearchValue] = useState<string>("");
    const [sorting, setSorting] = useState<"asc" | "desc" | "">("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;

    const allServices = useMemo(() => {
        return data?.map((p) => p.service);
    }, [data]);
    console.log("okkkkk")

    const filteredData = useMemo(() => {
        if (!searchValue || !searchValue?.length) {
            if (!sorting?.length) return allServices;
            return allServices?.sort(function (a, b) {
                return (
                    a?.name?.[
                        launchData.language as keyof typeof a.name
                    ]?.localeCompare(
                        b?.name?.[launchData.language as keyof typeof b.name],
                        "tr",
                        { sensitivity: "base" }
                    ) * (sorting === "asc" ? 1 : -1)
                );
            });
        }

        const filteredByName = allServices?.filter(
            (c) =>
                c.name?.[launchData.language as keyof typeof c.name]
                    ?.toLocaleLowerCase("tr")
                    ?.indexOf(searchValue?.toLocaleLowerCase("tr")) !== -1
        );

        if (!sorting.length) {
            return filteredByName;
        }

        return filteredByName?.sort(function (a, b) {
            return (
                a?.name?.[
                    launchData.language as keyof typeof a.name
                ]?.localeCompare(
                    b?.name?.[launchData.language as keyof typeof b.name],
                    "tr",
                    { sensitivity: "base" }
                ) * (sorting === "asc" ? 1 : -1)
            );
        });
    }, [allServices, searchValue, sorting]);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredData?.slice(startIndex, endIndex);
    }, [filteredData, currentPage, itemsPerPage]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <section className="mb-12 grid grid-cols-9">
            <div className="col-span-9 flex flex-col">
                <TopSearchArea
                    sorting={sorting}
                    setSorting={setSorting}
                    setSearchValue={setSearchValue}
                    filters={
                        <SearchFilters
                            mode={mode}
                            setMode={setMode}
                            reset={reset}
                            register={register}
                        />
                    }
                />

                <>
                    <div
                        className={classNames(
                            "grid h-full w-full grid-cols-1 flex-wrap items-center justify-center gap-6 py-1 sm:grid-cols-2  md:flex-1 md:grid-cols-3 md:pr-8 md:pl-6 lg:grid-cols-4 xl:pr-20",
                            {
                                "!flex !flex-col": mode === "table",
                                "!flex !w-full": !filteredData?.length,
                            }
                        )}
                    >
                        {typeof filteredData !== "undefined" &&
                        filteredData?.length > 0 ? (
                            <>
                                {paginatedData?.map((s) => (
                                    <ItemCard
                                        item={{
                                            text:
                                                launchData?.language === "tr"
                                                    ? s?.name?.tr
                                                    : s?.name?.en,
                                            about:
                                                launchData?.language === "tr"
                                                    ? s?.explanation?.tr
                                                    : s?.explanation?.en,
                                            logoMedia: s?.mainImage,
                                            backGroundMedia: s?.mainImage,
                                            url:
                                                launchData?.language === "tr"
                                                    ? s?.slug?.tr
                                                    : s?.slug?.en,
                                            companyName: s?.company?.name,
                                            id: s?._id,
                                            type: "service",
                                        }}
                                    />
                                ))}
                            </>
                        ) : (
                            <div className="flex !h-96 !w-full !flex-1 items-center justify-center">
                                <AnyData title="notMatchingCompanies" />
                            </div>
                        )}
                    </div>

                    {/* Pagination Bileşeni */}
                    <Pagination
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={filteredData?.length}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                    />
                </>
            </div>
        </section>
    );
};

export default FairServiceList;
