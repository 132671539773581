import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import { useSocket } from "../../context/SocketContext";
import { useParams } from "react-router-dom";

interface TwinFlipCardProps {
    componentData: {
        leftCard: {
            frontMedia: string;
            backMedia: string;
        };
        rightCard: {
            frontMedia: string;
            backMedia: string;
        };
        componentName: string;
    };
    _id: string; // Component ID
    designId: string; // Launch ID
}

const TwinFlipCard: React.FC<TwinFlipCardProps> = ({
    componentData,
    _id,
    designId,
}) => {
    const { leftCard, rightCard } = componentData;
    const [isLeftFlipped, setIsLeftFlipped] = useState(false);
    const [isRightFlipped, setIsRightFlipped] = useState(false);

    const { ref: leftCardRef, inView: leftCardInView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });
    const { ref: rightCardRef, inView: rightCardInView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });

    const [leftViewDuration, setLeftViewDuration] = useState(0);
    const [rightViewDuration, setRightViewDuration] = useState(0);

    const socket = useSocket();
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || "homepage";

    // View-duration loglama
    useEffect(() => {
        let leftInterval: NodeJS.Timeout | null = null;
      

        if (leftCardInView) {
            leftInterval = setInterval(() => {
                setLeftViewDuration((prev) => prev + 1);
            }, 1000);
        } else if (leftViewDuration > 0 && socket) {
            socket.emit("activity", {
                userId: localStorage.getItem("visitorId") || "none_user",
                componentId: _id,
                launchId: designId,
                activityType: "view-duration",
                duration: leftViewDuration,
            });
            setLeftViewDuration(0);
        }

        return () => {
            if (leftInterval) clearInterval(leftInterval);
           
        };
    }, [
        leftCardInView,
        rightCardInView,
        leftViewDuration,
        rightViewDuration,
        _id,
        designId,
        socket,
    ]);

    const renderMedia = (media: string) => {
        const fileExtension = media.split(".").pop()?.toLowerCase();

        if (
            ["jpg", "jpeg", "png", "gif", "webp"].includes(fileExtension || "")
        ) {
            return (
                <img
                    src={media}
                    alt="Media"
                    className="w-full h-full object-cover"
                />
            );
        }

        if (["mp4", "webm"].includes(fileExtension || "")) {
            return (
                <video
                    className="w-full h-full object-cover"
                    autoPlay
                    muted
                    loop
                >
                    <source src={media} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        }

        return <p>Geçersiz medya formatı.</p>;
    };

    return (
        <div
            className="flex flex-col md:flex-row justify-center gap-8 mt-12 mx-auto"
            style={{ width: "85vw", height: "70vh" }}
        >
            {/* Sol Kart */}
            <ReactCardFlip isFlipped={isLeftFlipped} flipDirection="horizontal">
                <div
                    ref={leftCardRef}
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsLeftFlipped(true)}
                >
                    {renderMedia(leftCard.frontMedia)}
                </div>

                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsLeftFlipped(false)}
                >
                    {renderMedia(leftCard.backMedia)}
                </div>
            </ReactCardFlip>

            {/* Sağ Kart */}
            <ReactCardFlip
                isFlipped={isRightFlipped}
                flipDirection="horizontal"
            >
                <div
                    ref={rightCardRef}
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsRightFlipped(true)}
                >
                    {renderMedia(rightCard.frontMedia)}
                </div>

                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsRightFlipped(false)}
                >
                    {renderMedia(rightCard.backMedia)}
                </div>
            </ReactCardFlip>
        </div>
    );
};

export default TwinFlipCard;
